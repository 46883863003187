import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';

const ResponsiveTableCard = ({
  columns,
  data,
  isDesktopTableStyled = false,
  tableHeaderCellStyle = {},
  tableCellStyle = {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatEmail = (email) => {
    if (!email.includes('@')) return email; // Return as is if not a valid email

    const [localPart, domain] = email.split('@'); // Split into local part and domain
    if (localPart.length > 6) {
      return (
        <>
          <span style={{ wordBreak: 'break-word' }}>{localPart}</span>
          <br />
          <span style={{ whiteSpace: 'nowrap' }}>@{domain}</span>
        </>
      );
    }
    return (
      <span style={{ whiteSpace: 'nowrap' }}>{email}</span> // No wrapping for short emails
    );
  };

  if (isMobile) {
    // Mobile view rendering
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centers items horizontally
        }}
      >
        {data.map((row, index) => {
          // Prepare columns for the two-column layout
          const otherColumns = columns.filter(
            (column) =>
              column.type !== 'image' && column.field !== 'provider_name'
          );
          const half = Math.ceil(otherColumns.length / 2);
          const leftColumns = otherColumns.slice(0, half);
          const rightColumns = otherColumns.slice(half);

          return (
            <Card
              key={index}
              style={{
                marginBottom: '16px',
                width: '95%', // Adjust the width as needed
                maxWidth: '600px', // Optional: limit the maximum width
              }}
            >
              <CardContent>
                {/* Center the image */}
                {columns.some((column) => column.type === 'image') &&
                  columns
                    .filter((column) => column.type === 'image')
                    .map((column) => (
                      <div
                        key={column.field}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '16px',
                        }}
                      >
                        <img
                          src={
                            row[column.field] ||
                            'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                          }
                          alt="Provider"
                          style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                    ))}
                {/* Display the name, centered */}
                {columns.some((column) => column.field === 'provider_name') && (
                  <Typography
                    variant="h6"
                    style={{ textAlign: 'center', marginBottom: '16px' }}
                  >
                    {row['provider_name']}
                  </Typography>
                )}
                {/* Display other fields in a two-column grid with separator */}
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {/* Left Column */}
                  <div style={{ flex: 1, padding: '4px' }}>
                    {leftColumns.map((column) => {
                      const cellData = row[column.field];
                      let cellContent;
                      if (column.field === 'provider_email') {
                        // Use formatEmail for email addresses
                        cellContent = formatEmail(cellData);
                      } else if (column.field === 'static_doxy_link') {
                        // Render as a link or text
                        cellContent =
                          cellData === 'Uses Google Meet' ? (
                            'Uses Google Meet'
                          ) : (
                            <a
                              href={cellData.replace('&#8203;', '')} // Remove zero-width space
                              target="_blank"
                              rel="noopener noreferrer"
                              dangerouslySetInnerHTML={{ __html: cellData }}
                            />
                          );
                      } else if (typeof cellData === 'string') {
                        // Handle strings with possible newlines
                        cellContent = cellData.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      } else if (column.field === 'diplomas') {
                        // Handle diplomas field
                        cellContent = cellData
                          ? cellData.split('\n\n').map((item, idx) => (
                              <div key={idx}>
                                {item.split('\n').map((line, i) => (
                                  <React.Fragment key={i}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </div>
                            ))
                          : 'N/A';
                      } else if (Array.isArray(cellData)) {
                        // If cellData is an array, render each item
                        cellContent = cellData.map((item, idx) => (
                          <div key={idx} style={item.style}>
                            {item.text}
                          </div>
                        ));
                      } else if (cellData && typeof cellData === 'object') {
                        // If cellData is an object with text and style
                        cellContent = (
                          <span style={cellData.style}>{cellData.text}</span>
                        );
                      } else if (typeof cellData === 'string') {
                        // If cellData is a string, handle newlines
                        cellContent = cellData.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      } else {
                        cellContent = '';
                      }

                      return (
                        <div
                          key={column.field}
                          style={{ marginBottom: '12px' }}
                        >
                          <Typography variant="subtitle2" color="textSecondary">
                            {column.headerName}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            style={{
                              wordBreak: 'break-word', // Handle long words
                              whiteSpace: 'normal', // Allow wrapping
                            }}
                          >
                            {cellContent}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                  {/* Vertical Divider */}
                  <div
                    style={{
                      width: '1px',
                      backgroundColor: '#e0e0e0',
                      margin: '0 8px',
                    }}
                  />
                  {/* Right Column */}
                  <div style={{ flex: 1, padding: '4px' }}>
                    {rightColumns.map((column) => {
                      const cellData = row[column.field];
                      let cellContent;

                      if (column.field === 'static_doxy_link') {
                        // Render as a link or text
                        cellContent =
                          cellData === 'Uses Google Meet' ? (
                            'Uses Google Meet'
                          ) : (
                            <a
                              href={cellData.replace('&#8203;', '')} // Remove zero-width space
                              target="_blank"
                              rel="noopener noreferrer"
                              dangerouslySetInnerHTML={{ __html: cellData }}
                            />
                          );
                      } else if (typeof cellData === 'string') {
                        // Handle strings with possible newlines
                        cellContent = cellData.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      } else if (column.field === 'diplomas') {
                        // Handle diplomas field
                        cellContent = cellData
                          ? cellData.split('\n\n').map((item, idx) => (
                              <div key={idx}>
                                {item.split('\n').map((line, i) => (
                                  <React.Fragment key={i}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </div>
                            ))
                          : 'N/A';
                      } else if (Array.isArray(cellData)) {
                        cellContent = cellData.map((item, idx) => (
                          <div key={idx} style={item.style}>
                            {item.text}
                          </div>
                        ));
                      } else if (cellData && typeof cellData === 'object') {
                        cellContent = (
                          <span style={cellData.style}>{cellData.text}</span>
                        );
                      } else if (typeof cellData === 'string') {
                        cellContent = cellData.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      } else {
                        cellContent = '';
                      }

                      return (
                        <div
                          key={column.field}
                          style={{ marginBottom: '12px' }}
                        >
                          <Typography variant="subtitle2" color="textSecondary">
                            {column.headerName}
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {cellContent}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    );
  } else {
    // Desktop view rendering with adjustments
    return (
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        <TableContainer
          component={Paper}
          style={{ width: '100%', maxHeight: '80vh' }}
        >
          <Table
            sx={{ width: '100%', tableLayout: 'fixed' }}
            size="medium"
            aria-label="Providers_table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{
                      width: `${100 / columns.length}%`,
                      ...tableHeaderCellStyle,
                      ...(isDesktopTableStyled ? column.headerStyle : {}),
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => {
                    const cellData = row[column.field];
                    let cellContent;

                    if (column.type === 'image') {
                      cellContent = (
                        <img
                          src={cellData}
                          alt="Provider"
                          style={{
                            width: '100%',
                            height: 'auto',
                          }}
                        />
                      );
                    } else if (Array.isArray(cellData)) {
                      cellContent = cellData.map((item, idx) => (
                        <div key={idx} style={item.style}>
                          {item.text}
                        </div>
                      ));
                    } else if (cellData && typeof cellData === 'object') {
                      cellContent = (
                        <span style={cellData.style}>{cellData.text}</span>
                      );
                    } else if (typeof cellData === 'string') {
                      cellContent = cellData.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ));
                    } else {
                      cellContent = '';
                    }

                    return (
                      <TableCell
                        key={column.field}
                        style={{
                          width: `${100 / columns.length}%`,
                          ...tableCellStyle,
                          ...(isDesktopTableStyled ? column.cellStyle : {}),
                        }}
                      >
                        {cellContent}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
};

export default ResponsiveTableCard;

import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Grid2,
  Typography,
  Modal,
  Tabs,
  Tab,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
} from '@mui/material';
import moment from 'moment';

function MultiSlotModal({
  open,
  handleClose,
  selectedSlots,
  setSelectedSlots,
  selectedReminder,
  handleUpdateReminder,
  handleCancel,
  reason,
  setReason,
  cancelOpenSlot,
  setCancelOpenSlot,
  adminOrCareCoordinators,
  onTagsChange,
  disabledButton,
  setDisabledButton,
  isMultiSlotAppointment,
}) {
  const [tabValue, setTabValue] = useState(0); // 0 = Reschedule, 1 = Cancel
  const [isMultiSlot, setIsMultiSlot] = useState(false);

  useEffect(() => {
    // Update whether it's a multi-slot appointment whenever `selectedReminder` changes
    if (selectedReminder) {
      setIsMultiSlot(isMultiSlotAppointment(selectedReminder));
    }
  }, [selectedReminder, isMultiSlotAppointment]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCheckboxChange = (index) => {
    const updatedSlots = [...selectedSlots];
    updatedSlots[index].editable = !updatedSlots[index].editable;
    setSelectedSlots(updatedSlots);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          padding: 20,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          height: 'auto',
          // Apply maxHeight and overflowY conditionally based on tabValue
          maxHeight: tabValue === 1 ? '90vh' : 'auto',
          overflowY: tabValue === 1 ? 'auto' : 'visible',
        }}
      >
        {/* Tabs for Reschedule and Cancel */}
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label="Reschedule"
            sx={{
              backgroundColor: tabValue === 0 ? 'lightblue' : 'inherit', // Change color when active
              '&.Mui-selected': { backgroundColor: 'lightblue' }, // Apply color when selected
            }}
          />
          <Tab
            label="Cancel"
            sx={{
              backgroundColor: tabValue === 1 ? 'lightblue' : 'inherit', // Change color when active
              '&.Mui-selected': { backgroundColor: 'lightblue' }, // Apply color when selected
            }}
          />
        </Tabs>

        {isMultiSlot && tabValue === 1 && (
          <Typography
            variant="body2"
            sx={{
              color: 'red',
              marginTop: 5,
              marginBottom: 2,
              fontWeight: 'bold',
              textAlign: 'center',
              padding: '20px', // Added padding to the message
              fontSize: '16px',
            }}
          >
            When cancelling an appointment with multiple slots, all slots need
            to be cancelled. If you need to modify only one of the slots, please
            use the Reschedule option instead.
          </Typography>
        )}

        {tabValue === 0 && (
          // Reschedule Tab Content
          <Grid2
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: '60vh' }}
          >
            {selectedSlots.map((slot, index) => (
              <Grid2
                key={slot.slot_id}
                container
                spacing={3}
                sx={{ marginBottom: '20px', marginTop: '40px' }}
              >
                <Grid2 item xs={1}>
                  <Checkbox
                    checked={slot.editable}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </Grid2>

                <Grid2 item xs={5}>
                  <TextField
                    label="Start Date & Time"
                    type="datetime-local"
                    value={moment
                      .utc(slot.start_date_time)
                      .format('YYYY-MM-DDTHH:mm')}
                    onChange={(event) => {
                      const updatedSlots = [...selectedSlots];
                      updatedSlots[index].start_date_time = event.target.value;
                      setSelectedSlots(updatedSlots);
                    }}
                    disabled={!slot.editable}
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Ensure label doesn't overlap with the date-time value
                    }}
                  />
                </Grid2>

                <Grid2 item xs={5}>
                  <TextField
                    label="End Date & Time"
                    type="datetime-local"
                    value={moment
                      .utc(slot.end_date_time)
                      .format('YYYY-MM-DDTHH:mm')}
                    onChange={(event) => {
                      const updatedSlots = [...selectedSlots];
                      updatedSlots[index].end_date_time = event.target.value;
                      setSelectedSlots(updatedSlots);
                    }}
                    disabled={!slot.editable}
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Ensure label doesn't overlap with the date-time value
                    }}
                  />
                </Grid2>
              </Grid2>
            ))}
            <ButtonGroup>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'yellow', // <-- Yellow color for Reschedule button
                  color: 'black',
                }}
                onClick={handleUpdateReminder} // <-- Trigger Reschedule action
              >
                Reschedule Appt
              </Button>
              <Button variant="contained" onClick={handleClose}>
                Go Back
              </Button>
            </ButtonGroup>
            <Typography variant="caption" sx={{ color: 'grey', marginTop: 1 }}>
              Note: Once Rescheduled is clicked, it may take 20-30 seconds to
              process. A spinner will be displayed during this time.
            </Typography>
          </Grid2>
        )}

        {tabValue === 1 && (
          <Grid2
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: '60vh' }}
          >
            {selectedSlots.map((slot, index) => (
              <Grid2
                key={slot.slot_id}
                container
                spacing={3}
                sx={{ marginBottom: '20px', marginTop: '60px' }}
              >
                <Grid2 item xs={5}>
                  <TextField
                    label="Start Date & Time"
                    type="datetime-local"
                    value={moment(slot.start_date_time).format(
                      'YYYY-MM-DDTHH:mm'
                    )}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid2>
                <Grid2 item xs={5}>
                  <TextField
                    label="End Date & Time"
                    type="datetime-local"
                    value={moment(slot.end_date_time).format(
                      'YYYY-MM-DDTHH:mm'
                    )}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid2>
              </Grid2>
            ))}

            {/* User Dropdown Selection */}
            <Autocomplete
              disablePortal
              id="users_selection"
              options={adminOrCareCoordinators}
              onChange={onTagsChange}
              sx={{ width: 300, marginBottom: '20px', marginTop: '20px' }}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  name="userSelection"
                  label="Select User"
                />
              )}
              getOptionLabel={(option) => option.username || ''}
            />
            {/* Reason Input */}
            <TextField
              fullWidth
              required
              sx={{ marginBottom: '20px' }} // Added marginBottom for spacing
              autoComplete="off"
              name="reason"
              label="Reason"
              defaultValue=""
              variant="outlined"
              onChange={(e) => {
                setReason(e.target.value);
              }}
              helperText="Please enter the Reason"
            />

            {/* Open Slot Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={cancelOpenSlot}
                  onChange={(e) => setCancelOpenSlot(!cancelOpenSlot)}
                />
              }
              label={isMultiSlot ? 'Open These Slots' : 'Open This Slot'}
              sx={{ paddingX: 1, paddingY: 1 }}
            />

            <ButtonGroup>
              <Button
                variant="contained"
                color="error"
                sx={{
                  backgroundColor: '#d32f2f',
                  marginRight: 2, // You can customize this color further if needed
                }}
                onClick={() => {
                  console.log('Cancel Button Clicked');
                  if (reason) {
                    setDisabledButton(true); // Disable button after clicking
                    handleCancel(reason, cancelOpenSlot); // Pass reason and cancelOpenSlot to cancel handler
                  } else {
                    alert('Please provide a reason before canceling.');
                  }
                }}
                disabled={disabledButton} // Button will be disabled initially until form is complete
              >
                CANCEL APPT
              </Button>
              <Button variant="contained" onClick={handleClose}>
                Go Back
              </Button>
            </ButtonGroup>

            <Typography variant="caption" sx={{ color: 'grey', marginTop: 2 }}>
              Note: Once clicked, it may take 20-30 seconds to process. A
              spinner will be displayed during this time.
            </Typography>
          </Grid2>
        )}
      </Box>
    </Modal>
  );
}

export default MultiSlotModal;

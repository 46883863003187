import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { formatTime24To12 } from '../../common/utility';

const GroupLocationsTable = ({ locations, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '10px' }}>
          Loading Locations...
        </Typography>
      </Box>
    );
  }

  if (!locations || locations.length === 0) {
    return (
      <Typography variant="h6" sx={{ padding: '16px', marginTop: '20px' }}>
        No Locations Available
      </Typography>
    );
  }

  return (
    <>
      {/* General Location Table */}
      <TableContainer
        component={Paper}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Typography variant="h6" sx={{ padding: '16px' }}>
          Locations
        </Typography>
        <Table>
          {/* Table Headers */}
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Location Name</strong>
              </TableCell>
              <TableCell>
                <strong>Full Address</strong>
              </TableCell>
              <TableCell>
                <strong>Phone Number</strong>
              </TableCell>
              <TableCell>
                <strong>Point of Contact</strong>
              </TableCell>
              <TableCell>
                <strong>Building E-Portal</strong>
              </TableCell>
              <TableCell>
                <strong>Building Hours</strong>
              </TableCell>
              <TableCell>
                <strong>After Hours Instructions</strong>
              </TableCell>
              <TableCell>
                <strong>Other Building Notes</strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Printing Locations */}
            {locations.map((loc, idx) => (
              <TableRow key={idx}>
                <TableCell>{loc.location_name || 'N/A'}</TableCell>
                <TableCell>
                  {[loc.address1, loc.address2, loc.city, loc.state, loc.zip]
                    .filter(Boolean) // Filter out empty or null values
                    .join(', ') || 'N/A'}
                </TableCell>
                <TableCell>{loc.phone_number || 'N/A'}</TableCell>
                <TableCell>
                  {loc.point_of_contact_name || 'N/A'}
                  <br />
                  {loc.point_of_contact_number || 'N/A'}
                  <br />
                  {loc.point_of_contact_email || 'N/A'}
                </TableCell>
                <TableCell>{loc.building_service_portal || 'N/A'}</TableCell>
                <TableCell>
                  {loc.building_hours && loc.building_hours.weekdays ? (
                    <>
                      <div>
                        <strong>Weekdays:</strong>{' '}
                        {formatTime24To12(loc.building_hours.weekdays.open)} -{' '}
                        {formatTime24To12(loc.building_hours.weekdays.close)}
                      </div>
                      <div>
                        <strong>Weekends:</strong> Locked - Use Keypad to Gain
                        Entry
                      </div>
                    </>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
                <TableCell>{loc.after_hours_instructions || 'N/A'}</TableCell>
                <TableCell>{loc.building_notes || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GroupLocationsTable;

import { useState, useEffect } from 'react';
import { adminService, userService, providerService } from '../services';
import { useSnackbar } from 'notistack';

// The below is the hook for fetching Admins from the BE.
const useAdmins = (showActiveAdmins = true) => {
  const { enqueueSnackbar } = useSnackbar();
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdmins = async () => {
      setIsLoading(true);
      setError(null); // Reset error before fetching

      try {
        const status = showActiveAdmins ? 'active' : 'inactive';

        // Fetch admins and users in parallel
        const [adminResponse, userResponse] = await Promise.all([
          adminService.getAdmins(status),
          userService.getAllUsers(),
        ]);

        const fetchedAdmins =
          adminResponse.data.filter((admin) => admin.admin_name != null) ?? [];
        const users = userResponse.data.users ?? [];

        // Map admins with user data
        const adminsWithImages = fetchedAdmins.map((admin) => {
          const user = users.find((user) => user.id === admin.id);
          return {
            ...admin,
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
          };
        });

        // Fetch additional details for each admin
        const fetchDetailsPromises = adminsWithImages.map((admin) =>
          getAdminDetail(admin.id, admin)
            .then((detail) => ({
              ...admin,
              ...detail,
              hipaa:
                admin.hipaa != null
                  ? admin.hipaa === true || admin.hipaa === 1
                    ? 'Yes'
                    : 'No'
                  : 'Unavailable', // Map HIPAA field
            }))
            .catch((error) => {
              console.error(
                'Failed to fetch details for admin:',
                admin.id,
                error
              );
              return {
                ...admin,
                hipaa: 'Unavailable',
              };
            })
        );

        const completedAdmins = await Promise.all(fetchDetailsPromises);

        // Update state with enriched admin data
        setAdmins(completedAdmins);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          'Error fetching admins';
        console.error('Error:', errorMessage);
        setError(errorMessage);
        enqueueSnackbar(errorMessage, { variant: 'error', timeout: 3000 });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAdmins();
  }, [showActiveAdmins, enqueueSnackbar]);

  // Helper function to fetch additional details for an admin
  const getAdminDetail = async (id, admin) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching admin details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  return { admins, isLoading, error };
};

export default useAdmins;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import React from 'react';
import BarCharts from '../../../components/UI/BarChart';
import { ResponsiveContainer } from 'recharts';

const childValueMap = {
  all_calls: 'allCalls',
  new_client: 'Extension_1+11',
  cancellations: 'Extension_3',
  outgoing: 'outGoingCalls',
};

function CallLogsTab(props) {
  const {
    data,
    isMobile,
    childValue,
    handleChangeChild,
    child2Tabs,
    onChangeDate,
  } = props;

  const currentData = data?.[childValueMap[childValue]];
  const dataLength = currentData ? currentData.length : 0;

  const CustomTooltipForCallLogs = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className=""
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            backgroundColor: 'black',
            cursor: 'pointer',
            border: '1px solid #e3e1e1',
            borderRadius: '3px',
            color: 'white',
            fontSize: '13px',
          }}
        >
          {payload[0]?.payload?.name?.toLowerCase() !== 'total' && (
            <p className="intro" style={{ marginTop: '5px' }}>
              {payload[0].payload.name}
            </p>
          )}
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.labelForValue} : {payload[0]?.payload?.value}
          </p>
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.labelForValue1} : {payload[0]?.payload?.value1}
          </p>
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.labelForValue2} : {payload[0]?.payload?.value2}
          </p>
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.labelForValue3} : {payload[0]?.payload?.value3}
          </p>
        </div>
      );
    }

    return null;
  };

  const MobileMenuCallLogs = () => (
    <div style={{ width: '100%' }}>
      <Select
        value={childValue} // Bind the current child tab value for accurate display
        onChange={(e) => handleChangeChild(null, e.target.value)}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {child2Tabs.map((tab, index) => (
          <MenuItem key={index} value={tab.value}>
            {tab.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  return (
    <>
      {isMobile && (
        <div style={{ marginTop: '55px', width: '100%' }}>
          <MobileMenuCallLogs />
        </div>
      )}
      <div>
        <div
          style={{
            marginTop: isMobile ? '10px' : '20px',
            display: 'flex',
          }}
        >
          {!isMobile && (
            <Box sx={{ display: 'flex', width: '15%' }}>
              <Tabs
                orientation="vertical"
                value={childValue}
                onChange={handleChangeChild}
                sx={{
                  '& button.Mui-selected': { backgroundColor: '#250480' },
                }}
              >
                {child2Tabs?.map((v, k) => (
                  <Tab
                    key={k}
                    label={v?.label}
                    value={v?.value}
                    sx={{
                      '&.Mui-selected': {
                        outline: 'none',
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          )}

          <div
            style={{
              padding: isMobile ? '0px' : '10px',
              width: isMobile ? '100%' : '85%',
              height: isMobile ? '350px' : '500px', // Adjusted height
            }}
          >
            {!isMobile && ( // Conditionally render the child tabs only for non-mobile screens
              <Box sx={{ display: 'flex', width: '15%' }}>
                <Tabs
                  orientation="vertical"
                  value={childValue}
                  onChange={handleChangeChild}
                  aria-label="Vertical tabs example"
                  //sx={{ borderBottom: 0, borderColor: 'divider' }}
                  sx={{
                    '& button.Mui-selected': { backgroundColor: '#1c405c' },
                  }}
                >
                  {child2Tabs?.map((v, k) => (
                    <Tab
                      sx={{
                        '&.Mui-selected': {
                          outline: 'none',
                        },
                      }}
                      label={v?.label}
                      value={v?.value}
                      key={k}
                    />
                  ))}
                </Tabs>
              </Box>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
                width: `100%`,
              }}
            >
              <FormControl
                style={{
                  width: `${isMobile ? '100%' : '150px'}`,
                }}
              >
                <InputLabel id="select-days-label">Select Days</InputLabel>
                <Select
                  onChange={(e) => {
                    onChangeDate(e);
                  }}
                  className="outline-none"
                  label="Select Days"
                  sx={{ fontSize: '14px' }}
                >
                  <MenuItem value={0}>Today</MenuItem>
                  <MenuItem value={1}>Yesterday</MenuItem>
                  <MenuItem value={7}>Last 7 Days</MenuItem>
                  <MenuItem value={30}>Past Month</MenuItem>
                  <MenuItem value={180}>Past 6 Months</MenuItem>
                  {/* <MenuItem value={365}>Past 1 Year</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: isMobile ? '' : '75px',
                marginTop: isMobile ? '50px' : '0px',
              }}
            >
              <div style={{ display: 'flex' }}>
                <div>Total</div>
                <div
                  style={{
                    backgroundColor: '#0088FE',
                    height: '10px',
                    marginTop: '7px',
                    width: '20px',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                ></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div>0-3 min</div>
                <div
                  style={{
                    backgroundColor: '#00C49F',
                    height: '10px',
                    marginTop: '7px',
                    width: '20px',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                ></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div>3-10 min</div>
                <div
                  style={{
                    backgroundColor: '#FFBB28',
                    height: '10px',
                    marginTop: '7px',
                    width: '20px',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                ></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div>10+ min</div>
                <div
                  style={{
                    backgroundColor: '#ff2a00',
                    height: '10px',
                    marginTop: '7px',
                    width: '20px',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                ></div>
              </div>
            </div>
            {/* Enable horizontal scrolling */}
            <div style={{ overflowX: 'auto' }}>
              {/* Set dynamic width based on data length */}
              <div
                style={{ width: isMobile ? `${dataLength * 100}px` : '100%' }}
              >
                {childValue === 'all_calls' && (
                  // <div style={{ height: '500px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarCharts
                      CustomTooltip={<CustomTooltipForCallLogs />}
                      firstBarColor="#0088FE"
                      secondBarColor="#00C49F"
                      thirdBarColor="#FFBB28"
                      forthBarColor="#ff2a00"
                      secondBar={true}
                      thirdBar={true}
                      forthBar={true}
                      data={data?.['allCalls']}
                      isResponsive={true}
                      showXaxis={!isMobile}
                    />{' '}
                  </ResponsiveContainer>
                )}
                {/* Enable horizontal scrolling */}
                <div style={{ overflowX: 'auto' }}>
                  {/* Set dynamic width based on data length */}
                  <div
                    style={{
                      width: isMobile ? `${dataLength * 70}px` : '100%',
                    }}
                  >
                    {childValue === 'new_client' && (
                      <ResponsiveContainer
                        width="100%"
                        height={isMobile ? 250 : 400}
                      >
                        <BarCharts
                          CustomTooltip={<CustomTooltipForCallLogs />}
                          firstBarColor="#0088FE"
                          secondBarColor="#00C49F"
                          thirdBarColor="#FFBB28"
                          forthBarColor="#ff2a00"
                          secondBar={true}
                          thirdBar={true}
                          forthBar={true}
                          isResponsive={true}
                          showXaxis={!isMobile}
                          data={data?.['Extension_1+11']}
                        />{' '}
                      </ResponsiveContainer>
                    )}
                    <div style={{ overflowX: 'auto' }}>
                      <div
                        style={{
                          width: isMobile ? `${dataLength * 70}px` : '100%',
                        }}
                      >
                        {childValue === 'cancellations' && (
                          <ResponsiveContainer
                            width="100%"
                            height={isMobile ? 250 : 400}
                          >
                            <BarCharts
                              CustomTooltip={<CustomTooltipForCallLogs />}
                              firstBarColor="#0088FE"
                              secondBarColor="#00C49F"
                              thirdBarColor="#FFBB28"
                              forthBarColor="#ff2a00"
                              secondBar={true}
                              thirdBar={true}
                              forthBar={true}
                              isResponsive={true}
                              showXaxis={!isMobile}
                              data={data?.['Extension_3']}
                            />{' '}
                            {/* </div> */}
                          </ResponsiveContainer>
                        )}
                        <div style={{ overflowX: 'auto' }}>
                          <div
                            style={{
                              width: isMobile ? `${dataLength * 70}px` : '100%',
                            }}
                          >
                            {childValue === 'outgoing' && (
                              <ResponsiveContainer
                                width="100%"
                                height={isMobile ? 250 : 400}
                              >
                                <BarCharts
                                  CustomTooltip={<CustomTooltipForCallLogs />}
                                  firstBarColor="#0088FE"
                                  secondBarColor="#00C49F"
                                  thirdBarColor="#FFBB28"
                                  forthBarColor="#ff2a00"
                                  secondBar={true}
                                  thirdBar={true}
                                  isResponsive={true}
                                  showXaxis={!isMobile}
                                  data={data?.['outGoingCalls']}
                                />{' '}
                              </ResponsiveContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CallLogsTab;

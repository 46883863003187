import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';

function TextArea({ label, name, value, onChange, rows, error, helperText }) {
  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        label={label}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        multiline
        rows={rows}
        variant="outlined"
        fullWidth
        error={error}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        // Customize styles using the `sx` prop
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'grey.300',
            },
            '&:hover fieldset': {
              borderColor: 'primary.light',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
          '& .MuiInputBase-input': {
            color: 'text.primary',
          },
          '& .MuiFormHelperText-root': {
            color: 'error.main',
          },
        }}
      />
    </Box>
  );
}

// Define PropTypes for better type checking and documentation
TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

// Define default props in case some props are not provided
TextArea.defaultProps = {
  rows: 1,
  error: false,
  helperText: '',
};

export default memo(TextArea);

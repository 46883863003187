import React, { useState } from 'react';
import { Radio, IconButton, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Helper function to format a raw phone number string (digits only)
 * into (XXX) XXX-XXXX if it's 10 digits, or 1 + 10 digits.
 * Otherwise, returns the original input.
 */
function formatPhoneNumber(rawPhone) {
  if (!rawPhone) return rawPhone;
  // Extract only digits
  const digits = rawPhone.replace(/\D/g, '');

  // CASE 1: 10-digit number
  if (digits.length === 10) {
    return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  // CASE 2: 11-digit number starting with '1'
  if (digits.length === 11 && digits[0] === '1') {
    // Strip leading '1'
    const area = digits.slice(1, 4);
    const prefix = digits.slice(4, 7);
    const line = digits.slice(7);
    return `(${area}) ${prefix}-${line}`;
  }

  // Otherwise, return original
  return rawPhone;
}

/**
 * Extracts just the code from a Google Meet URL, e.g.,
 * "https://meet.google.com/qgy-vqgy-jvk" -> "qgy-vqgy-jvk"
 */
function extractMeetCode(url) {
  const prefix = 'https://meet.google.com/';
  if (url.startsWith(prefix)) {
    return url.replace(prefix, '');
  }
  return url; // fallback if it's not a standard meet.google.com link
}

const MobileClientRow = ({
  contact,
  isSelected,
  onSelect, // function sets selectedClientId, opens dialog, etc.
}) => {
  const { name, provider, phoneNumber, email, meetLink } = contact;

  // Overlay state: which icon the user tapped (phone / email / meet) or null
  const [overlayMethod, setOverlayMethod] = useState(null);

  // Remove ", Ph.D." (case-insensitive) from provider if present
  const sanitizedProvider = provider
    ? provider.replace(/,\s*Ph\.?D\.?/i, '')
    : provider;

  // Open the overlay for phone/email/meet
  const openOverlay = (method) => setOverlayMethod(method);

  // Close the overlay
  const closeOverlay = (e) => {
    // Stop event so it doesn’t also trigger the link action
    e.stopPropagation();
    setOverlayMethod(null);
  };

  // The "click anywhere except X" action
  /**
   * The below function will open RingCentral if clicked on PhoneNumber.
   * Otherwise it will open gmail if clicked on the mail icon. And, at the
   * last if clicked on meet. It will open that google meet.
   */
  // const handleOverlayClick = () => {
  //   if (overlayMethod === 'phone') {
  //     // Deep link to RingCentral
  //     const ringCentralLink = `rcmobile://call?number=${phoneNumber}`;
  //     window.location.href = ringCentralLink;
  //   } else if (overlayMethod === 'email') {
  //     // Open Gmail compose
  //     window.open(
  //       `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`,
  //       '_blank'
  //     );
  //   } else if (overlayMethod === 'meet') {
  //     // Open Google Meet link in new tab
  //     window.open(meetLink, '_blank');
  //   }
  // };

  const handleOverlayClick = () => {
    if (overlayMethod === 'phone') {
      const cleanPhoneNumber = phoneNumber.replace(/\D/g, ''); // Ensure only digits
      const ringCentralLink = `rcapp://r/call?number=${cleanPhoneNumber}`;
      const fallbackLink = `tel:${cleanPhoneNumber}`;

      try {
        // Use RingCentral deep link
        const w = window.parent ? window.parent : window; // Support for iframes or parent windows
        w.location.assign(ringCentralLink);
      } catch (error) {
        console.error('Failed to open RingCentral app. Using fallback:', error);
        // Fallback to regular call link
        window.location.href = fallbackLink;
      }
    } else if (overlayMethod === 'email') {
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`,
        '_blank'
      );
    } else if (overlayMethod === 'meet') {
      window.open(meetLink, '_blank');
    }
  };

  // Decide the first-line text for the overlay
  const getFirstLineVariant = () => {
    return overlayMethod === 'meet' ? 'body1' : 'subtitle1';
  };

  const getFirstLineText = () => {
    if (overlayMethod === 'phone') {
      return formatPhoneNumber(phoneNumber);
    } else if (overlayMethod === 'email') {
      return email;
    } else if (overlayMethod === 'meet') {
      // "Google Meet - qgy-vqgy-jvk"
      const meetCode = extractMeetCode(meetLink);
      return `Google Meet - ${meetCode}`;
    }
    return '';
  };

  // Second line: "Call John Doe", "Email John Doe", or "Meet with John Doe"
  const getSecondLine = () => {
    if (overlayMethod === 'phone') {
      return `Call ${name}`;
    } else if (overlayMethod === 'email') {
      return `Email ${name}`;
    } else if (overlayMethod === 'meet') {
      return `Meet with ${name}`;
    }
    return '';
  };

  return (
    <div
      style={{
        // Ensures no horizontal scroll
        width: '100%',
        boxSizing: 'border-box',
        // overflowX: 'hidden',

        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0 10px 0', // or padding: '10px';
        borderBottom: '1px solid #ddd',
        // Move child content closer to the left edge.
      }}
    >
      {/* ROW: Radio on far left, Name & Provider, Icons on far right */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* LEFT SIDE: Radio + Name/Provider */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
          <Radio
            color="primary"
            name="clientSelection"
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              onSelect();
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Client Name in bold */}
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                // maxWidth: '250px', // Adjust as needed
              }}
            >
              {name}
            </Typography>
            {/* Provider: Single row, smaller font */}
            <Typography
              variant="body2"
              sx={{
                color: '#666',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                // maxWidth: '250px', // Adjust as needed
                width: '100%',
              }}
            >
              {sanitizedProvider}
            </Typography>
          </div>
        </div>

        {/* RIGHT SIDE: Icon Buttons aligned far right */}
        <div
          style={{
            display: 'flex',
            gap: '4px',
            marginLeft: 'auto',
          }}
        >
          {/* Phone */}
          <IconButton
            color="primary"
            onClick={() => openOverlay('phone')}
            aria-label={`Call ${name}`}
          >
            <PhoneIcon />
          </IconButton>

          {/* Email */}
          <IconButton
            color="secondary"
            onClick={() => openOverlay('email')}
            aria-label={`Email ${name}`}
          >
            <EmailIcon />
          </IconButton>

          {/* Meet (only if valid link) */}
          {meetLink && meetLink.startsWith('https://meet.google.com/') && (
            <IconButton
              color="success"
              onClick={() => openOverlay('meet')}
              aria-label={`Google Meet with ${name}`}
            >
              <VideoCallIcon />
            </IconButton>
          )}
        </div>
      </div>

      {/* OVERLAY: Appear directly over this row instead of bottom of screen */}
      {overlayMethod && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            // Adjust height or padding so it covers the row
            minHeight: '60px',
            backgroundColor:
              overlayMethod === 'phone'
                ? '#4CAF50' // Green for Call
                : overlayMethod === 'email'
                ? '#9C27B0' // Purple for Email
                : '#03A9F4', // Light Blue for Google Meet
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#fff',
            padding: '8px 16px',
            cursor: 'pointer',
            zIndex: 10,
            // Hide overflow if content is wide
            overflowX: 'hidden',
            boxSizing: 'border-box',
          }}
          onClick={handleOverlayClick}
        >
          <div>
            {/* FIRST LINE: phone/email or "Google Meet - qgy-vqgy-jvk" */}
            <Typography
              variant={getFirstLineVariant()}
              sx={{
                fontWeight: overlayMethod === 'meet' ? 400 : 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                // maxWidth: '250px', // widen as needed
              }}
            >
              {getFirstLineText()}
            </Typography>
            {/* SECOND LINE */}
            <Typography variant="body1">{getSecondLine()}</Typography>
          </div>
          <IconButton
            onClick={closeOverlay}
            aria-label="Close overlay"
            style={{
              color: '#fff',
              padding: '6px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default MobileClientRow;

import { useState, useEffect } from 'react';
import { groupService } from '../services';

// Below is the hook for Fetching group data
const useGroupData = () => {
  const [groupInfo, setGroupInfo] = useState(null);
  const [locations, setLocations] = useState([]);
  const [passwords, setPasswords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGroupInfo = async () => {
      console.log('Starting fetchGroupInfo...');
      setIsLoading(true);
      setError(null);
      try {
        const response = await groupService.getAllGroupsWithLocations();
        console.log('API Response:', response.data);

        if (response.data) {
          const { generalOfficeInfo: gInfo, locations: locs } = response.data;
          console.log('Extracted generalOfficeInfo:', gInfo);
          console.log('Extracted locations:', locs);

          if (gInfo) {
            setGroupInfo({
              officeOutgoingPhoneNumber: gInfo.outgoing_phone_number || '',
              officeFaxNumber: gInfo.fax_number || '',
              officeTaxId: gInfo.tax_id || '',
              officePhoneExtensions: Array.isArray(gInfo.phone_extensions)
                ? gInfo.phone_extensions
                : [],
            });

            const pws = gInfo.passwords;
            console.log('Extracted passwords:', pws);

            setPasswords(Array.isArray(pws) ? pws : []);
          } else {
            console.warn('generalOfficeInfo is undefined in the response.');
            setGroupInfo(null);
            setPasswords([]);
          }

          if (Array.isArray(locs)) {
            setLocations(locs);
          } else {
            console.warn('No locations data available.');
            setLocations([]);
          }
        } else {
          console.warn('response.data is undefined.');
          setGroupInfo(null);
          setPasswords([]);
          setLocations([]);
        }
      } catch (error) {
        console.error('Error fetching group details:', error.message);
        setError(error.message);
        setGroupInfo(null);
        setPasswords([]);
        setLocations([]);
      } finally {
        console.log('Finished fetchGroupInfo.');
        setIsLoading(false);
      }
    };

    fetchGroupInfo();
  }, []);

  return { groupInfo, locations, passwords, isLoading, error };
};

export default useGroupData;

import React, { useEffect, useCallback, useState } from 'react';
import {
  Box,
  Switch,
  Grid2,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  ProviderPersonalInfo,
  ProviderPracticeInfo,
  ProviderProfessionalInfo,
  ProviderSystemInfo,
  ProviderInsuranceInfo,
} from '../Input';
import { TherapyConfig, TestingConfig } from '../Config';
import { DriveDetails, Ten99Component } from '../DriveComponent';
import {
  isAdmin,
  isDoctor,
  isSupervisee,
  isDoctorAdminSupervisor,
  isOnlyDoctorOrSupervisor,
  isOnlyDoctorOrSupervisee,
  hasSuperAdminAccess,
} from '../../common/utils/RoleUtils';
import EventBus from '../../common/EventBus';
import ResponsiveTabs from '../UI/ResponsiveTabs';
import { clearLoading, setLoading } from '../../actions/loading';
import styles from '../../styles/ProviderConfigHomeStyles.module.css';
import {
  userService,
  driveService,
  supervisorService,
  providerService,
} from '../../services';

export default function ProviderConfigHome() {
  const [activeTab, setActiveTab] = useState('personal_info'); // Initialize with the first tab's values value
  const [users, setUsers] = React.useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedUser, setSelectedUser] = React.useState('');
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveProviderOnly, setShowActiveProviderOnly] =
    React.useState(true);
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [superviseeIds, setSuperViseeIds] = useState(); // supervisorIds setting and unsetting
  const [globalProviders, setGlobalProviders] = useState(); // Declaring Providers as a state
  const [liabilityFileExpirationDate, setLiabilityFileExpirationDate] =
    useState(); // This useState created for setting liability file expiration date
  const [accordionToOpen, setAccordionToOpen] = useState();
  // The above state is use to keep track of accordion to be opened.

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getAllProviders = async () => {
    providerService.getAllProviders().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
          providers = providers.filter((provider) => {
            return provider.id === currentUser.id;
          });
        }
        setUsers(providers);
        hideLoading();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  // The below function is responsible for setting the Tab and menu based on Active Tab selected
  const setTheSelectedProviderBasedOnActiveTab = async (providers) => {
    showLoading();
    const activeTabFromStorage = localStorage.getItem('activeTab');

    if (activeTabFromStorage) {
      const accordionToOpenFromStorage = localStorage.getItem('optionSelected');
      setAccordionToOpen(accordionToOpenFromStorage);

      const currentProviderToBeSelected = providers?.find(
        (provider) => provider.id === currentUser.id
      );

      setActiveTab(activeTabFromStorage);
      if (currentProviderToBeSelected) {
        const providerDetail = await providerService.getProviderDetail(
          currentProviderToBeSelected.id,
          currentProviderToBeSelected.email
        );
        setSelectedUserDetail({
          ...providerDetail?.data?.provider,
        });

        let foundUser = providers.find((provider) => {
          return provider.id === currentProviderToBeSelected.id;
        });
        setSelectedUser(foundUser);
      }
    }
    // Removing both the activeTab key and optionSelected key
    localStorage.removeItem('activeTab');
    localStorage.removeItem('optionSelected');
    hideLoading();
  };

  const getNormalUsers = async () => {
    userService.getAllUsers().then(
      async (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5 && user.is_active === 1;
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers

        // Setting the providers below to be used as a global and somewhere else in the code.
        setGlobalProviders(providers);

        // I am here confirming if the user is actually only with the doctor role
        if (isOnlyDoctorOrSupervisee(currentUser)) {
          let currentProviderLogin = providers.find(
            (provider) => provider.id === currentUser.id
          );
          setSelectedUser(currentProviderLogin);
          const providerDetail = await providerService.getProviderDetail(
            currentProviderLogin.id,
            currentProviderLogin.email
          );
          if (providerDetail && providerDetail.data) {
            providerDetail.data.provider.username =
              currentProviderLogin.username;
            setSelectedUserDetail(providerDetail?.data?.provider);
          }
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
        // I am here confirming if the user is actually only with the doctor or supervisor role
        else if (isOnlyDoctorOrSupervisor(currentUser)) {
          const response = await supervisorService.getSupervisorSupervisee(
            currentUser.id
          );

          let currentProviderLogin = providers.find(
            (provider) => provider.id === currentUser.id
          );
          setSelectedUser(currentProviderLogin);
          const providerDetail = await providerService.getProviderDetail(
            currentProviderLogin.id,
            currentProviderLogin.email
          );
          if (providerDetail && providerDetail.data) {
            providerDetail.data.provider.username =
              currentProviderLogin.username;
            setSelectedUserDetail(providerDetail?.data?.provider);
          }

          const superviseeIds = response.data.data.map(
            (dataObj) => dataObj.superviseeId
          );

          setSuperViseeIds(superviseeIds);
          // Above setting the superviseeIds

          const filteredProvider = providers.filter(
            (provider) =>
              superviseeIds.includes(provider.id) ||
              currentUser.id === provider.id
          ); // In the above code. I am making an OR comparison to check if providerId is equal to superviseeIds or currentUser id to providerId

          setUsers(filteredProvider);
          setSelectedUser(undefined);
          setSelectedUserDetail(undefined);
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
        // if none of the condition are satisfied then come towards this
        else {
          setUsers(providers);
          hideLoading();
          setTheSelectedProviderBasedOnActiveTab(providers);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();

    if (showActiveProviderOnly) {
      getNormalUsers();
    } else {
      getAllProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveProviderOnly]);

  useEffect(() => {
    async function getLiabilityDate() {
      const response = await driveService.viewFiles({
        folder: 'liability',
        provider_id: selectedUserDetail?.id,
      });

      // Below liability File Expiration Date set
      setLiabilityFileExpirationDate(
        response?.data?.data.files[response?.data?.data.files.length - 1]
          ?.expiry_date
      );
    }

    if (selectedUserDetail?.id) {
      getLiabilityDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail]);

  const onTagsChange = async (event, id) => {
    const selectedProvider = users.find((obj) => obj?.id === id);
    setSelectedUser(selectedProvider);

    // Update users based on role
    if (isOnlyDoctorOrSupervisor(currentUser)) {
      const filteredProvider = globalProviders?.filter(
        (provider) =>
          superviseeIds?.includes(provider?.id) ||
          provider?.id === currentUser?.id
      );
      setUsers(filteredProvider);
    }

    if (selectedProvider?.id) {
      const providerDetail = await providerService.getProviderDetail(
        selectedProvider.id,
        selectedProvider.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = selectedProvider.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  const updateProviderDetail = async () => {
    const providerDetail = await providerService.getProviderDetail(
      selectedUser.id,
      selectedUser.email
    );

    if (providerDetail && providerDetail.data) {
      setSelectedUserDetail({
        ...providerDetail?.data?.provider,
      });
    }
  };

  // Inside ProviderConfigHome.js

  const tabs = [
    {
      value: 'personal_info',
      title: 'Personal Info',
      description: 'Name, Email, DOB, etc',
      // Optional: Use a custom label for more detailed display
      label: <span>Personal Info</span>,
    },
    {
      value: 'professional_info',
      title: 'Professional Info',
      description: 'Professional details and credentials',
      label: <span>Professional Info</span>,
    },
    {
      value: 'practice_info',
      title: 'Practice Info',
      description: 'Practice-related information',
      condition: isAdmin(currentUser) && selectedUserDetail, // Display based on condition
      label: <span>Practice Info</span>,
    },
    {
      value: 'provider_systems',
      title: 'Systems',
      description: 'System configurations',
      condition: isAdmin(currentUser) && selectedUserDetail,
      label: <span>Systems</span>,
    },
    {
      value: 'provider_insurance_info',
      title: 'Insurance Info',
      description: 'Insurance-related information',
      condition: isAdmin(currentUser) && selectedUserDetail,
      label: <span>Insurance Info</span>,
    },
    {
      value: 'in_therapy',
      title: 'Approp - Therapy',
      description: 'Therapy-related approvals',
      condition: selectedUserDetail?.in_therapy === true,
      label: <span>Approp - Therapy</span>,
    },
    {
      value: 'in_testing',
      title: 'Approp - Testing',
      description: 'Testing-related approvals',
      condition: selectedUserDetail?.in_testing === true,
      label: <span>Approp - Testing</span>,
    },
    {
      value: 'drive_detail',
      title: 'Drive Details',
      description: 'Details about drive files',
      condition: isAdmin(currentUser) && selectedUserDetail,
      label: <span>Drive Details</span>,
    },
    {
      value: 'drive',
      title: 'Documents - Misc',
      description: 'Miscellaneous Documents',
      condition:
        isDoctor(currentUser) ||
        (hasSuperAdminAccess(currentUser) && selectedUserDetail),
      label: <span>Documents - Misc</span>,
    },
  ];

  const visibleTabs = tabs.filter((tab) =>
    tab.condition === undefined ? true : tab.condition
  );
  useEffect(() => {
    const validTab = visibleTabs.find((tab) => tab.value === activeTab);
    if (!validTab) {
      setActiveTab(visibleTabs.length > 0 ? visibleTabs[0].value : '');
    }
  }, [visibleTabs, activeTab]);

  const marginTop = !isDoctor(currentUser) && isMobile ? '30px' : '0px';

  return (
    <React.Fragment>
      <div
        className="main-container"
        style={{ marginTop: marginTop, paddingLeft: '1.3rem' }}
      >
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12 }}>
            {(!isDoctor(currentUser) ||
              isDoctorAdminSupervisor(currentUser) ||
              isOnlyDoctorOrSupervisor(currentUser)) && (
              <FormControl
                variant="outlined"
                className={styles.widerProviderDropdown}
              >
                <InputLabel id="provider-label">Provider</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider"
                  value={selectedUser?.id || ''}
                  onChange={(event) => onTagsChange(event, event.target.value)}
                  label="Provider"
                  className={styles.selectWithDarkBorder}
                >
                  {users.map((option) => {
                    let displayLabel = option.username;
                    if (isOnlyDoctorOrSupervisor(currentUser)) {
                      if (option.id === currentUser.id) {
                        displayLabel = `${option.username} (Me)`;
                      } else if (superviseeIds?.includes(option.id)) {
                        displayLabel = `${option.username} (Supervisee)`;
                      }
                    }
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {displayLabel}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            {/*if the user is only Doctor then displaying the current user being logged in */}
            {isOnlyDoctorOrSupervisee(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}

            {/*if the user is superVisor then displaying the selected user being logged in */}
            {isOnlyDoctorOrSupervisor(currentUser) && (
              <>
                <h3>{selectedUser?.username}</h3>
                <h5>{selectedUser?.email}</h5>
              </>
            )}
          </Grid2>
        </Grid2>

        {selectedUser && (
          <Box className={styles.tabContainer}>
            <ResponsiveTabs
              tabs={visibleTabs}
              activeTab={activeTab}
              onChange={handleTabChange}
              useUniqueValues={true} // Use unique string identifiers
            />

            {activeTab === 'personal_info' && (
              <ProviderPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                mode={'update'}
              />
            )}

            {activeTab === 'professional_info' && (
              <ProviderProfessionalInfo
                setLiabilityFileExpirationDate={setLiabilityFileExpirationDate}
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                accordionToOpen={accordionToOpen}
              />
            )}

            {activeTab === 'practice_info' && (
              <ProviderPracticeInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {activeTab === 'provider_systems' && (
              <ProviderSystemInfo
                liabilityFileExpirationDate={liabilityFileExpirationDate}
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {activeTab === 'provider_insurance_info' && (
              <ProviderInsuranceInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {activeTab === 'in_therapy' && (
              <TherapyConfig
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                isSupervisor={selectedUser?.roles?.includes(7)} // Check if the selected user is a supervisor
                isSelectedSupervisee={selectedUser?.roles?.includes(8)} // Check if the selected user is a supervisee
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {activeTab === 'in_testing' && (
              <TestingConfig
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateProviderDetail={updateProviderDetail}
              />
            )}

            {activeTab === 'drive_detail' && (
              <DriveDetails selectedUser={selectedUser} />
            )}

            {activeTab === 'drive' && (
              <Ten99Component
                selectedUserDetail={selectedUserDetail}
                selectedUser={selectedUser}
              />
            )}
          </Box>
        )}
        {!selectedUser && <div>Please select a Provider.</div>}

        {shouldShow && !selectedUser && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Switch
              checked={showActiveProviderOnly}
              onChange={(e) => setShowActiveProviderOnly(e.target.checked)}
              color="primary"
            />
            <span
              style={{
                fontSize: '1.1rem',
                fontWeight: 'bold',
              }}
            >
              {showActiveProviderOnly ? (
                <span>Show Active Providers</span> // Displays when the switch is 'on'
              ) : (
                <span>Show All Providers</span> // Displays when the switch is 'off'
              )}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'What is the testing like?',
    answer: `The testing experience will vary depending upon the needs and age of your child. Testing may be conducted in one or two days. For toddlers and young children, testing involves a combination of play-based activities, floor play, and table activities; these activities may involve completing puzzles, problem solving with toys, and answering questions. Testing will be appropriately modified for children with limited verbal skills.
      
      For older children, testing involves paper and pencil tasks and computer-based tasks; much of these activities occur while seated at a table. The experience is designed to be positive and enjoyable for your child to ensure that they perform at their best. Dr. McLean is an expert in serving neurodiverse children with varied needs and will make appropriate accommodations to support your child through the testing process.`,
  },
  {
    question: 'What tests are being performed?',
    answer: `The evaluation will be individualized depending upon your child’s needs and age. Psychological evaluations typically involve assessments of your child’s developmental functioning, intellectual skills, academic skills, and language skills. The Autism Diagnostic Observation Schedule, Second Edition (ADOS-2) will be administered as a component of each Autism evaluation.`,
  },
  {
    question: 'Will parents be in the room during testing?',
    answer: `A parent will be in the testing room during testing for toddlers (and some young children), as parent involvement and input may be necessary. To provide an ideal testing environment free from distractions and interruptions, parents will remain in the waiting room during the testing of most children. Distractions must be minimized to focus the child’s attention on the tasks being presented, and children are often distracted by the presence of their parents which impacts their performance.`,
  },
  {
    question: 'How do I prepare my child for the testing day?',
    answer: `Be sure that your child gets appropriate sleep the evening prior to testing and a healthy breakfast the morning of testing. You are also encouraged to bring a small, healthy snack and water that your child may have during testing breaks.`,
  },
  {
    question: 'Will we be directed to services that can help?',
    answer: `After your child’s evaluation, Dr. McLean will provide recommendations and referrals based upon your child’s testing results. Referrals may include specific therapy options, strategies to support your child’s learning at home, recommendations for school, and psychoeducational resources. Dr. McLean will also share specific referral agencies in the area to consider. You will receive a thorough psychological evaluation report which details these recommendations and referrals.`,
  },
  {
    question: 'Will your evaluation help us with school?',
    answer: `Yes. Dr. McLean is an expert in child development and school-based psychology. You will be advised of the best route for school programming based upon your child’s testing results. This may involve a recommendation for an Individualized Education Program (IEP), 504 Plan, or other school-based services or accommodations. Dr. McLean is also available to provide additional educational consultation, including accompanying you to school meetings.`,
  },
  {
    question: 'Are you in network with insurance?',
    answer: `Dr. McLean is an out of network provider. Our team will share the procedural codes that you need to arrange for reimbursement from your insurance company. We will also share documentation with you after your service is completed which you may share with your insurance company.`,
  },
  {
    question: 'Do you provide medication prescriptions?',
    answer: `No, psychologists do not prescribe medication. However, Dr. McLean is able to provide psychoeducation regarding medication decisions and may refer your child to a pediatric psychiatrist who can assist with medication management.`,
  },
];

function FAQ({ setHasScrolledFAQ }) {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);
  const lastFaqRef = useRef(null);

  // Split FAQs into two columns
  const midIndex = Math.ceil(faqs.length / 2);
  const leftColumnFaqs = faqs.slice(0, midIndex);
  const rightColumnFaqs = faqs.slice(midIndex);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (!lastFaqRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setHasScrolledFAQ(true);
          }
        });
      },
      {
        root: containerRef.current,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    observer.observe(lastFaqRef.current);

    return () => {
      if (lastFaqRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(lastFaqRef.current);
      }
    };
  }, [setHasScrolledFAQ]);

  return (
    <Box
      ref={containerRef}
      sx={{
        mt: 2,
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflowY: 'auto',
        maxHeight: '400px', // Adjust as needed
        width: '100%',
        p: 1,
      }}
      // Optional: Add padding or other styles as needed
    >
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          {leftColumnFaqs.map((faq, index) => {
            return (
              <Accordion
                key={index}
                expanded={expanded === `panel-left-${index}`}
                onChange={handleChange(`panel-left-${index}`)}
                sx={{
                  marginBottom: '16px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography whiteSpace="pre-line">{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>

        {/* Right Column */}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          {rightColumnFaqs.map((faq, index) => {
            const isLast = index === rightColumnFaqs.length - 1;
            return (
              <Accordion
                key={index}
                expanded={expanded === `panel-right-${index}`}
                onChange={handleChange(`panel-right-${index}`)}
                sx={{
                  marginBottom: '16px',
                }}
                ref={isLast ? lastFaqRef : null} // Attach ref to the last FAQ item
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography whiteSpace="pre-line">{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default FAQ;

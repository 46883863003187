import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Select,
  MenuItem,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';
import { groupService } from '../../services'; // Corrected import

// Constants
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

// Helper function to format 24-hour time to 12-hour format with AM/PM
const formatHour = (hour) => {
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;
  return `${hour12}:00 ${period}`;
};

// Helper to parse "HH:MM" into { hour: number, minute: number }
function parseTimeStr(timeStr) {
  const [hh, mm] = timeStr.split(':');
  return {
    hour: parseInt(hh, 10),
    minute: parseInt(mm, 10) || 0,
  };
}

// Helper to format "HH:MM" into 12-hour times (e.g., 13:15 => 1:15 PM)
function format12Hour(timeStr) {
  const [hh, mm] = timeStr.split(':');
  let hourNum = parseInt(hh, 10);
  const minuteNum = parseInt(mm, 10);
  const amPm = hourNum >= 12 ? 'PM' : 'AM';
  if (hourNum > 12) {
    hourNum -= 12;
  }
  if (hourNum === 0) {
    hourNum = 12;
  }
  return `${hourNum}:${String(minuteNum).padStart(2, '0')} ${amPm}`;
}

const RoomScheduleTable = () => {
  // State variables
  const [selectedOffice, setSelectedOffice] = useState(4); // Default to Baltimore
  const [rooms, setRooms] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [offices, setOffices] = useState([]);
  const [error, setError] = useState(null); // Error state
  const [selectedDay, setSelectedDay] = useState('');

  const hours = Array.from({ length: 13 }, (_, i) => 7 + i); // 7 AM to 9 PM

  // Setting useDispatch here.
  const dispatch = useDispatch();

  // Function to show Loading here
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  // Function to hide Loading here
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  // Refs for scrolling
  const dayRefs = useRef([]);

  // Initialize dayRefs with valid React refs
  useEffect(() => {
    dayRefs.current = daysOfWeek.map(() => React.createRef());
    console.log('Initialized dayRefs:', dayRefs.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysOfWeek.length]);

  // Fetch all offices on component mount
  useEffect(() => {
    const fetchOffices = async () => {
      try {
        // Show Loading
        showLoading();
        console.log('Fetching all office locations...');
        const response = await groupService.getAllGroupsWithLocations();
        if (response.data && response.data.locations) {
          setOffices(response.data.locations || []);
          console.log('Fetched offices:', response.data.locations);
        } else {
          console.warn('No office data found.');
        }
      } catch (fetchOfficesError) {
        console.error('Error fetching offices:', fetchOfficesError);
        setError('Failed to load offices.');
      } finally {
        // Hide Loading
        hideLoading();
      }
    };

    fetchOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch rooms and schedules whenever selectedOffice changes
  useEffect(() => {
    const fetchRoomAndSchedules = async () => {
      // Show Loading here
      showLoading();
      setError(null);
      try {
        console.log('Fetching data for selected office ID:', selectedOffice);

        // Fetch rooms
        console.log('Fetching rooms for office ID:', selectedOffice);
        const roomResponse = await groupService.getRoomsByLocation(
          selectedOffice
        );
        if (roomResponse.data) {
          setRooms(roomResponse.data || []);
          console.log('Fetched rooms:', roomResponse.data);
        } else {
          console.warn('No rooms data found for selected office.');
        }

        // Fetch schedules with provider data
        console.log('Fetching schedules for office ID:', selectedOffice);
        const scheduleResponse = await groupService.getSchedulesByLocation(
          selectedOffice
        );
        if (scheduleResponse.data) {
          setSchedules(scheduleResponse.data);
          console.log('Fetched schedules:', scheduleResponse.data);
        } else {
          console.warn('No schedules data found for selected office.');
        }
      } catch (fetchSchedulesError) {
        if (fetchSchedulesError.response) {
          console.error('Error Response:', {
            status: fetchSchedulesError.response.status,
            data: fetchSchedulesError.response.data,
            headers: fetchSchedulesError.response.headers,
          });
          setError(
            `Error: ${
              fetchSchedulesError.response.data.message ||
              'Failed to load schedules.'
            }`
          );
        } else if (fetchSchedulesError.request) {
          console.error('No Response:', fetchSchedulesError.request);
          setError('Error: No response received from the server.');
        } else {
          console.error('Request Setup Error:', fetchSchedulesError.message);
          setError(`Error: ${fetchSchedulesError.message}`);
        }
      } finally {
        hideLoading();
      }
    };

    if (selectedOffice) {
      fetchRoomAndSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice]);

  // Handle office selection change
  const handleOfficeChange = (event) => {
    setSelectedOffice(event.target.value);
    console.log('Selected office changed to ID:', event.target.value);
  };

  // Scroll to selected day
  const scrollToDay = (dayIndex) => {
    const dayRef = dayRefs.current[dayIndex];
    if (dayRef?.current) {
      dayRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      console.log(
        `Scrolling to day index ${dayIndex}: ${daysOfWeek[dayIndex]}`
      );
    } else {
      console.warn(`No reference found for day index: ${dayIndex}`);
    }
  };

  // Extract initials from provider name
  const getProviderInitials = (name) => {
    if (!name) {
      console.warn('Provider name is missing!');
      return '';
    }
    const [firstName, ...rest] = name.split(' ');
    const lastName = rest.join(' ').split(',')[0] || '';
    const initials = `${firstName[0] || ''}${lastName[0] || ''}`;
    console.log('Derived initials:', initials, 'from name:', name);
    return initials.toUpperCase(); // Ensure initials are uppercase
  };

  /**
   * Renders schedule blocks for each hour/room/day. We only show partial labels
   * (above the block) in two scenarios:
   *  1) If this row is the schedule's start hour AND start minutes are != 0.
   *  2) If this row is the schedule's end hour AND end minutes are != 0.
   * For all the middle hours (full hours) we do NOT display partial labels.
   */
  const renderScheduleCell = (day, hour, roomId) => {
    // Filter schedules for this day, hour, and room
    const matchingSchedules = schedules.filter((schedule) => {
      const startHour = parseInt(schedule.schedule.startTime.split(':')[0], 10);
      const endHour = parseInt(schedule.schedule.endTime.split(':')[0], 10);

      return (
        schedule.office_room_id === roomId &&
        schedule.schedule.days.includes(day) &&
        hour >= startHour &&
        hour <= endHour
      );
    });

    if (!matchingSchedules.length) return null;

    return (
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {matchingSchedules.map((schedule) => {
          const providerName = schedule.provider?.provider_name || '';
          const providerInitials = getProviderInitials(providerName);

          // Parse start/end times
          const { hour: startH, minute: startM } = parseTimeStr(
            schedule.schedule.startTime
          );
          const { hour: endH, minute: endM } = parseTimeStr(
            schedule.schedule.endTime
          );

          // Are we in the schedule's start hour, end hour, or middle hour?
          const isStartHourRow = hour === startH;
          const isEndHourRow = hour === endH;

          // Is partial?
          const isPartialStart = startM !== 0 && isStartHourRow;
          const isPartialEnd = endM !== 0 && isEndHourRow;

          // For each hour row, figure out how much of that hour is booked
          let startOffsetMinutes = 0;
          let endOffsetMinutes = 60;

          // If it's the start hour, shift by startM
          if (hour === startH) {
            startOffsetMinutes = startM;
          }
          // If it's the end hour, shift by endM
          if (hour === endH) {
            endOffsetMinutes = endM;
          }

          // Convert minute offsets to percentages
          const leftPercent = (startOffsetMinutes / 60) * 100;
          const widthPercent =
            ((endOffsetMinutes - startOffsetMinutes) / 60) * 100;

          // If the time block is negative or zero, skip rendering
          if (widthPercent <= 0) return null;

          return (
            <Box
              key={schedule.id}
              sx={{
                position: 'absolute',
                top: 0,
                left: `${leftPercent}%`,
                width: `${widthPercent}%`,
                height: '100%',
                backgroundColor: '#CCE5FF',
                border: '1px solid #99CCFF',
                fontSize: '0.8rem',
                // IMPORTANT: allow label above to be visible
                overflow: 'visible',
                zIndex: 2,
              }}
            >
              {/* Provider initials in the center */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontWeight: 'bold',
                  pointerEvents: 'none',
                }}
              >
                {providerInitials}
              </Box>

              {/* Show small label above ONLY if partial start for this row */}
              {isPartialStart && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-1.6em',
                    left: 0,
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    color: '#000',
                    fontSize: '0.75rem',
                    padding: '0 4px',
                    borderRadius: '4px',
                    pointerEvents: 'none',
                    zIndex: 3,
                  }}
                >
                  {format12Hour(schedule.schedule.startTime)}
                </Box>
              )}

              {/* Show small label above ONLY if partial end for this row */}
              {isPartialEnd && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-1.6em',
                    right: 0,
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    color: '#000',
                    fontSize: '0.75rem',
                    padding: '0 4px',
                    borderRadius: '4px',
                    pointerEvents: 'none',
                    zIndex: 3,
                  }}
                >
                  {format12Hour(schedule.schedule.endTime)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Room Schedule for{' '}
        {offices.find((office) => office.location_id === selectedOffice)
          ?.location_name || 'Unknown'}
      </Typography>

      {/* Dropdowns */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginBottom: 2,
        }}
      >
        {/* Office Location Dropdown */}
        <Select
          value={selectedOffice}
          onChange={handleOfficeChange}
          displayEmpty
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="" disabled>
            Select Office
          </MenuItem>
          {offices.map((office) => (
            <MenuItem key={office.location_id} value={office.location_id}>
              {office.location_name}
            </MenuItem>
          ))}
        </Select>

        {/* Scroll to Day Dropdown */}
        <Select
          displayEmpty
          sx={{ minWidth: 200 }}
          value={selectedDay}
          onChange={(e) => {
            const newSelectedDay = e.target.value;
            setSelectedDay(newSelectedDay);
            const dayIndex = daysOfWeek.indexOf(newSelectedDay);
            console.log(`Selected day: ${newSelectedDay}, Index: ${dayIndex}`);
            scrollToDay(dayIndex);
          }}
        >
          <MenuItem value="" disabled>
            Select Day
          </MenuItem>
          {daysOfWeek.map((day) => (
            <MenuItem key={day} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Error Message */}
      {error && (
        <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}

      {/* Schedule Table */}
      {!error && rooms.length > 0 ? (
        <Table
          sx={{
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
            width: '100%',
          }}
        >
          <TableHead>
            {/* Room Headers */}
            <TableRow>
              <TableCell
                sx={{
                  left: 0,
                  zIndex: 3,
                  backgroundColor: '#fff',
                  width: '150px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderRight: '2px solid #000',
                }}
              >
                Day/Hour
              </TableCell>
              {rooms.map((room) => (
                <TableCell
                  key={room.id}
                  sx={{
                    position: 'sticky',
                    top: 60,
                    zIndex: 2,
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    borderRight: '2px solid #000',
                  }}
                >
                  {room.room_letter}
                </TableCell>
              ))}
            </TableRow>

            {/* Virtual Tour Links */}
            <TableRow>
              <TableCell
                sx={{
                  left: 0,
                  zIndex: 1,
                  backgroundColor: '#fff',
                  textAlign: 'center',
                  borderRight: '2px solid #000',
                }}
              />
              {rooms.map((room) => (
                <TableCell
                  key={room.id}
                  sx={{
                    textAlign: 'center',
                    borderRight: '2px solid #000',
                  }}
                >
                  {room.virtual_tour_link && (
                    <Link
                      href={room.virtual_tour_link}
                      target="_blank"
                      rel="noopener"
                      style={{ display: 'block', marginBottom: '8px' }}
                    >
                      Virtual Tour
                    </Link>
                  )}
                </TableCell>
              ))}
            </TableRow>

            {/* Room Images */}
            <TableRow>
              <TableCell
                sx={{
                  left: 0,
                  zIndex: 1,
                  backgroundColor: '#fff',
                  textAlign: 'center',
                  borderRight: '2px solid #000',
                }}
              />
              {rooms.map((room) => (
                <TableCell
                  key={room.id}
                  sx={{
                    textAlign: 'center',
                    borderRight: '2px solid #000',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Room Image */}
                    {room.room_image_url ? (
                      <img
                        src={room.room_image_url}
                        alt={`Room ${room.room_letter}`}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                          marginTop: '8px',
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/100';
                        }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No Image Available
                      </Typography>
                    )}

                    <Typography variant="body2">
                      <strong>Dimensions:</strong> {room.dimensions || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>SQF:</strong> {room.square_footage || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Room Notes:</strong>{' '}
                      {room.multi_row_notes?.trim() || 'N/A'}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {daysOfWeek.map((day, dayIndex) => (
              <React.Fragment key={day}>
                {/* Sticky Day Row */}
                <TableRow ref={dayRefs.current[dayIndex]}>
                  <TableCell
                    colSpan={rooms.length + 1}
                    sx={{
                      position: 'sticky',
                      top: 110,
                      zIndex: 1,
                      backgroundColor: '#f0f0f0',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderBottom: '2px solid #000',
                      borderRight: '2px solid #000',
                    }}
                  >
                    {day}
                  </TableCell>
                </TableRow>

                {/* Hourly Schedule Rows */}
                {hours.map((hour) => (
                  <TableRow key={`${day}-${hour}`}>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: '#fff',
                        textAlign: 'center',
                        borderRight: '2px solid #000',
                      }}
                    >
                      {formatHour(hour)}
                    </TableCell>

                    {rooms.map((room) => (
                      <TableCell
                        key={`${day}-${hour}-${room.id}`}
                        sx={{
                          position: 'relative',
                          verticalAlign: 'middle',
                          minWidth: 80,
                          height: 48,
                          borderRight: '2px solid #000',
                          // Important: allow items to show above cell
                          overflow: 'visible',
                        }}
                      >
                        {renderScheduleCell(day, hour, room.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        !error && (
          <Typography variant="body1">No schedule data available.</Typography>
        )
      )}
    </Box>
  );
};

export default RoomScheduleTable;

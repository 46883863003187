import { useState, useEffect } from 'react';
import { providerService, userService } from '../services';

// Hook below getting Provider Professional Info from the BE.
const useProvidersProfessional = (showActiveProviders) => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProviderDetails = async (providerId) => {
      try {
        const response = await providerService.getTherapyConfig(providerId);
        const therapyLocations = response?.data?.therapy?.locations || [];
        const testingLocations = response?.data?.testing?.locations || [];
        const allLocations = [...therapyLocations, ...testingLocations].filter(
          Boolean
        );
        const uniqueLocations = [...new Set(allLocations)].join(', ');

        return {
          location: uniqueLocations || 'N/A',
          last_attestation: response?.data?.last_attestation || 'N/A',
          last_roster_submission:
            response?.data?.last_roster_submission || 'N/A',
        };
      } catch (error) {
        console.error(
          `Error fetching details for provider ${providerId}:`,
          error
        );
        return {
          location: 'N/A',
          last_attestation: 'N/A',
          last_roster_submission: 'N/A',
        };
      }
    };

    const fetchProviders = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const status = showActiveProviders ? 'active' : 'inactive';

        // Fetch providers and users in parallel
        const [providerResponse, userResponse] = await Promise.all([
          providerService.getProviders(status),
          userService.getAllUsers(),
        ]);

        const fetchedProviders = providerResponse?.data?.providers || [];
        const users = userResponse?.data?.users || [];

        // Enrich providers with user images
        const providersWithImages = fetchedProviders.map((provider) => {
          const user = users.find((user) => user.id === provider.id);
          return {
            ...provider,
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg', // Default image
          };
        });

        console.log('Providers with images:', providersWithImages);

        // Fetch additional details and map them to providers
        const enrichedProviders = await Promise.all(
          providersWithImages.map(async (provider) => {
            const additionalDetails = await fetchProviderDetails(provider.id);
            return {
              ...provider,
              ...additionalDetails,
              diplomas:
                provider.diplomas
                  ?.map(
                    (diploma) =>
                      `${diploma.graduating_school} (${new Date(
                        diploma.graduating_date
                      ).toLocaleDateString('en-US')})`
                  )
                  .join(', ') || 'N/A',
              supervisor: provider.supervisor_name || 'No supervisor',
            };
          })
        );

        console.log('Enriched Providers:', enrichedProviders);

        setProviders(enrichedProviders);
      } catch (err) {
        console.error('Error fetching professional provider data:', err);
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
        console.log('Fetch process completed.');
      }
    };

    fetchProviders();
  }, [showActiveProviders]);

  return { providers, isLoading, error };
};

export default useProvidersProfessional;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { userService, providerService, groupService } from '../../services';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareProviderFullName(a, b) {
  if (a.isGroup) return -1; // Always keep group row on top
  if (b.isGroup) return 1;

  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

const tableHeaderCellStyle = {
  borderBottom: '0.5px solid black',
  fontWeight: '900',
  fontSize: '1.0rem', // Increase font size
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#F5F5F5',
};

const tableCellStyle = { borderBottom: '0.1px solid black' };

export default function ProviderInsuranceInfoTable({ showActiveProviders }) {
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const dispatch = useDispatch();

  // Below function for showing the loaders
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    // This fetch Data is responsible for fetching group Information
    const fetchData = async () => {
      showLoading();
      const status = showActiveProviders ? 'active' : 'inactive';

      try {
        // 1. Fetch group insurance info
        const groupResponse = await groupService.getGroupInsuranceInfo();
        const groupDefault = groupResponse?.data?.data?.default || {};

        // Construct a group row object
        // Mark it with isGroup: true to handle sorting and styling if needed
        const grpRow = {
          provider_name: 'Group Practice', // Display name for the group
          npi_number: groupDefault.npi_number || 'N/A',
          caqh_number: groupDefault.caqh_number || 'N/A',
          aetna_provider_number: groupDefault.aetna_provider_number,
          aetna_effective_date: groupDefault.aetna_effective_date,
          bcbs_provider_number: groupDefault.bcbs_provider_number,
          bcbs_effective_date: groupDefault.bcbs_effective_date,
          cigna_provider_number: groupDefault.cigna_provider_number,
          cigna_effective_date: groupDefault.cigna_effective_date,
          hopkins_ehp_provider_number: groupDefault.hopkins_ehp_provider_number,
          hopkins_ehp_effective_date: groupDefault.hopkins_ehp_effective_date,
          hopkins_usfhp_provider_number:
            groupDefault.hopkins_usfhp_provider_number,
          hopkins_usfhp_effective_date:
            groupDefault.hopkins_usfhp_effective_date,
          medicaid_provider_number: groupDefault.medicaid_provider_number,
          medicaid_effective_date: groupDefault.medicaid_effective_date,
          medicare_provider_number: groupDefault.medicare_provider_number,
          medicare_effective_date: groupDefault.medicare_effective_date,
          tricare_provider_number: groupDefault.tricare_provider_number,
          tricare_effective_date: groupDefault.tricare_effective_date,
          isGroup: true, // Custom flag to identify the group row
        };

        // 2. Fetch providers as usual
        const response = await providerService.getProviders(status);
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        const usersResponse = await userService.getAllUsers();
        const users = usersResponse?.data?.users ?? [];

        const providersWithDetails = await Promise.all(
          fetchedProviders.map(async (provider) => {
            const user = users.find((user) => user.id === provider.id);
            const providerWithImage = {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
            try {
              const detail = await getProviderDetail(provider.id, provider);
              return { ...providerWithImage, ...detail };
            } catch {
              return providerWithImage;
            }
          })
        );

        // 3. Add the group row at the front
        const combined = [grpRow, ...providersWithDetails];
        setProviders(combined);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      } finally {
        hideLoading();
      }
    };

    fetchData();
  }, [showActiveProviders, enqueueSnackbar, showLoading, hideLoading]);

  // Below function for getting Provider Detail
  const getProviderDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Define columns
  const columns = useMemo(
    () => [
      { field: 'provider_name', headerName: 'Full Name' },
      { field: 'npi_number', headerName: 'NPI #' },
      { field: 'caqh_number', headerName: 'CAQH #' },
      {
        field: 'aetna_provider_info',
        headerName: 'Aetna Provider # (Effective Date)',
      },
      {
        field: 'bcbs_provider_info',
        headerName: 'BCBS Provider # (Effective Date)',
      },
      {
        field: 'cigna_provider_info',
        headerName: 'CIGNA Provider # (Effective Date)',
      },
      {
        field: 'hopkins_ehp_provider_info',
        headerName: 'JHU EHP Provider # (Effective Date)',
      },
      {
        field: 'hopkins_usfhp_provider_info',
        headerName: 'JHU USFHP Provider # (Effective Date)',
      },
      {
        field: 'medicaid_provider_info',
        headerName: 'Medicaid Provider # (Effective Date)',
      },
      {
        field: 'medicare_provider_info',
        headerName: 'Medicare Provider # (Effective Date)',
      },
      {
        field: 'tricare_provider_info',
        headerName: 'Tricare Provider # (Effective Date)',
      },
    ],
    []
  );

  // Format providers (including group row)
  const formattedProviders = useMemo(() => {
    const formatInfo = (providerNumber, effectiveDate) => {
      let result = providerNumber || 'N/A';
      if (effectiveDate && moment.utc(effectiveDate).isValid()) {
        result += `\n(${moment.utc(effectiveDate).format('M-D-YY')})`;
      }
      return result;
    };

    // Sort providers but keep group row at top
    const sortedProviders = [...providers].sort(compareProviderFullName);

    return sortedProviders.map((provider) => {
      // For the group row, just treat similarly to providers
      return {
        ...provider,
        aetna_provider_info: formatInfo(
          provider.aetna_provider_number,
          provider.aetna_effective_date
        ),
        bcbs_provider_info: formatInfo(
          provider.bcbs_provider_number,
          provider.bcbs_effective_date
        ),
        cigna_provider_info: formatInfo(
          provider.cigna_provider_number,
          provider.cigna_effective_date
        ),
        hopkins_ehp_provider_info: formatInfo(
          provider.hopkins_ehp_provider_number,
          provider.hopkins_ehp_effective_date
        ),
        hopkins_usfhp_provider_info: formatInfo(
          provider.hopkins_usfhp_provider_number,
          provider.hopkins_usfhp_effective_date
        ),
        medicaid_provider_info: formatInfo(
          provider.medicaid_provider_number,
          provider.medicaid_effective_date
        ),
        medicare_provider_info: formatInfo(
          provider.medicare_provider_number,
          provider.medicare_effective_date
        ),
        tricare_provider_info: formatInfo(
          provider.tricare_provider_number,
          provider.tricare_effective_date
        ),
      };
    });
  }, [providers]);

  // Apply pagination
  const paginatedProviders = useMemo(() => {
    return formattedProviders.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [formattedProviders, page, rowsPerPage]);

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={paginatedProviders}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

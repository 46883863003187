import { useState, useEffect } from 'react';
import {
  careCoordinatorService,
  userService,
  providerService,
} from '../services';

// The below is the hook for fetching CareCoordinators from the BE.
export default function useCareCoordinators(showActiveCareCoordinators) {
  const [careCoordinators, setCareCoordinators] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCareCoordinators = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const status = showActiveCareCoordinators ? 'active' : 'inactive';

        // Fetch care coordinators and users in parallel
        const [coordinatorResponse, userResponse] = await Promise.all([
          careCoordinatorService.getCareCoordinators(status),
          userService.getAllUsers(),
        ]);

        const fetchedCareCoordinators =
          coordinatorResponse.data
            .map((cc) => ({
              ...cc,
              state: cc.state || 'Unknown', // Ensure the state field is included
            }))
            .filter((cc) => cc.care_coordinator_name != null) ?? [];

        const users = userResponse.data.users ?? [];

        // Map care coordinators with user data
        const careCoordinatorsWithImages = fetchedCareCoordinators.map((cc) => {
          const user = users.find((user) => user.id === cc.id);
          return {
            ...cc,
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
          };
        });

        // Fetch additional details for each care coordinator
        const fetchDetailsPromises = careCoordinatorsWithImages.map((cc) =>
          getCareCoordinatorDetail(cc.id, cc)
            .then((detail) => ({
              ...cc,
              ...detail,
              hipaa: cc.hipaa ? 'Yes' : 'No',
            }))
            .catch((error) => {
              console.error(
                'Failed to fetch details for care coordinator:',
                cc.id,
                error
              );
              return {
                ...cc,
                hipaa: 'Unavailable',
              };
            })
        );

        const completedCareCoordinators = await Promise.all(
          fetchDetailsPromises
        );

        // Filter out invalid care coordinators and update state
        const validCareCoordinators = completedCareCoordinators.filter(
          (cc) => cc.care_coordinator_name != null
        );

        setCareCoordinators(validCareCoordinators);
        setLocations(validCareCoordinators); // Set as locations if required
      } catch (err) {
        console.error('Error fetching care coordinators:', err);
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCareCoordinators();
  }, [showActiveCareCoordinators]);

  // Helper function to fetch additional details for a care coordinator
  const getCareCoordinatorDetail = async (id, careCoordinator) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching care coordinator details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  return { careCoordinators, locations, isLoading, error };
}

import React, { useState } from 'react';
import {
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CalLegendContent = () => {
  return (
    <div
      style={{
        border: '2px solid black',
        padding: '10px',
        fontSize: '14px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          flexWrap: 'wrap',
        }}
      >
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
          Service:
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Psychological Testing with red border */}
          <div
            style={{
              backgroundColor: 'white',
              padding: '5px 8px',
              border: '3px solid red',
              marginRight: '10px',
              borderRadius: '4px',
            }}
          >
            Psychological Testing
          </div>
          {/* Therapy with box shadow */}
          <div
            style={{
              backgroundColor: 'white',
              padding: '5px 8px',
              boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.2)',
              borderRadius: '4px',
            }}
          >
            Therapy
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          flexWrap: 'wrap',
        }}
      >
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
          Location:
        </span>

        {/* Pair each location with its color box inline */}
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <div
            style={{
              backgroundColor: 'purple',
              width: '10px',
              height: '10px',
              marginRight: '5px',
            }}
          />
          <span>Baltimore</span>
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <div
            style={{
              backgroundColor: 'blue',
              width: '10px',
              height: '10px',
              marginRight: '5px',
            }}
          />
          <span>Columbia</span>
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <div
            style={{
              backgroundColor: 'yellow',
              width: '10px',
              height: '10px',
              marginRight: '5px',
            }}
          />
          <span>Remote</span>
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <div
            style={{
              backgroundColor: 'green',
              width: '10px',
              height: '10px',
              marginRight: '5px',
            }}
          />
          <span>Silver Spring</span>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Icon:</span>
        <Typography
          component="span"
          style={{
            marginRight: '10px',
            fontFamily: 'sans-serif',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span role="img" aria-label="hold">
            🤚 Hold
          </span>
        </Typography>

        <Typography
          component="span"
          style={{
            marginRight: '10px',
            fontFamily: 'sans-serif',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span role="img" aria-label="note">
            📝 Note
          </span>
        </Typography>
      </div>
    </div>
  );
};

const CalLegend = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const [expanded, setExpanded] = useState(false);

  if (isSmallScreen && isPortrait) {
    return (
      <div style={{ width: '100%', margin: '10px 0' }}>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="legend-content"
            id="legend-header"
          >
            <Typography style={{ fontWeight: 'bold' }}>Legend</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CalLegendContent />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  // Larger screens or non-portrait mode: show full legend
  return (
    <div
      style={{
        width: '40%',
        margin: '20px 0 0 auto', // Aligns to the right
        marginTop: '-100px',
      }}
    >
      <CalLegendContent />
    </div>
  );
};

export default CalLegend;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import { isCareCoordinator } from '../../common/utils/RoleUtils';
import { useSelector, useDispatch } from 'react-redux';
import { providerService } from '../../services';
import { clearLoading, setLoading } from '../../actions/loading';
import useCareCoordinators from '../../hooks/useCareCoordinators';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareCareCoordinatorFullName(a, b) {
  const lastNameA = a.care_coordinator_name
    ? a.care_coordinator_name.split(' ')[1] || ''
    : '';
  const lastNameB = b.care_coordinator_name
    ? b.care_coordinator_name.split(' ')[1] || ''
    : '';
  return lastNameA.localeCompare(lastNameB);
}

const tableHeaderCellStyle = {
  borderBottom: '0.5px solid black',
  fontWeight: '900',
  fontSize: '1.0rem', // Increase font size
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#F5F5F5',
};
const tableCellStyle = { borderBottom: '0.1px solid black' };

export default function CareCoordinatorGeneralTable({
  showActiveCareCoordinators,
}) {
  // The below is the hook for fetching the CareCoordinators
  const { careCoordinators, isLoading } = useCareCoordinators(
    showActiveCareCoordinators
  );

  const [locations, setLocations] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Below dispatching
  const dispatch = useDispatch();

  // Below functions for showing and hiding the loading
  const showLoading = useCallback(() => dispatch(setLoading()), [dispatch]);
  const hideLoading = useCallback(() => dispatch(clearLoading()), [dispatch]);

  // Below useEffect setting Loader here
  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const hasEmergencyContactAccess = (currentUser) => {
    return (
      currentUser.id ===
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      currentUser.id ===
        process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
      isCareCoordinator(currentUser)
    );
  };

  // Below function for getting the CareCoordinator Detail
  const getCareCoordinatorDetail = async (id, careCoordinator) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching care coordinator details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  useEffect(() => {
    const fetchDetailsPromises = careCoordinators.map((careCoordinator) =>
      getCareCoordinatorDetail(careCoordinator.id, careCoordinator)
        .then((detail) => ({ ...careCoordinator, ...detail }))
        .catch((error) => {
          console.error(
            'Failed to fetch details for care coordinator:',
            careCoordinator.id,
            error
          );
          return careCoordinator;
        })
    );

    Promise.all(fetchDetailsPromises).then((completedCareCoordinators) => {
      setLocations(completedCareCoordinators);
    });
  }, [careCoordinators]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedCareCoordinators = useMemo(() => {
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareCareCoordinatorFullName);
  }, [locations, page, rowsPerPage]);

  const formattedCareCoordinators = useMemo(() => {
    return displayedCareCoordinators.map((careCoordinator) => {
      const dob = careCoordinator.date_of_birth
        ? moment(careCoordinator.date_of_birth).format('M/D/YY')
        : '';
      const age = careCoordinator.date_of_birth
        ? moment().diff(moment(careCoordinator.date_of_birth), 'years')
        : '';
      const dateOfBirthWithAge = `${dob}${age ? `\n(${age} yrs)` : ''}`;

      const homeAddress =
        `${careCoordinator.address1 || ''} ${
          careCoordinator.address2 || ''
        }`.trim() +
        `\n${careCoordinator.city || ''}, ${careCoordinator.state || ''} ${
          careCoordinator.zip || ''
        }`.trim();

      const emergencyContactRelationship =
        careCoordinator.emergencyContactRelationship === 'Other'
          ? careCoordinator.customRelationship
          : careCoordinator.emergencyContactRelationship;

      return {
        ...careCoordinator,
        date_of_birth: dateOfBirthWithAge,
        address: homeAddress,
        emergencyContactRelationship,
      };
    });
  }, [displayedCareCoordinators]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'care_coordinator_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          width: '140px',
        },
      },
      {
        field: 'imageUrl',
        headerName: '',
        type: 'image',
        headerStyle: { width: '150px', minWidth: '150px' },
        cellStyle: { width: '150px', minWidth: '150px' },
      },

      {
        field: 'care_coordinator_email',
        headerName: 'PsychCare Email',
        headerStyle: {
          width: '210px',
          minWidth: '210px',
          fontWeight: '900',
          borderBottom: '0.5px solid black',
        },
        cellStyle: {
          width: '210px',
          minWidth: '210px',
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'personal_email',
        headerName: 'Personal Email',
        headerStyle: {
          width: '250px',
          minWidth: '250px',
          fontWeight: '900',
          borderBottom: '0.5px solid black',
        },
        cellStyle: {
          width: '250px',
          minWidth: '250px',
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'ext',
        headerName: 'Ext',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          width: '140px',
        },
      },
      {
        field: 'mobile_number',
        headerName: 'Mobile #',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          width: '140px',
        },
      },
      {
        field: 'date_of_birth',
        headerName: 'Date of Birth (Age)',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'address',
        headerName: 'Home Address',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ];

    // Deciding the columns here based on the Roles
    if (isCareCoordinator(currentUser)) {
      baseColumns.splice(7, 0, {
        field: 'ss_num',
        headerName: 'SS#',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    // Deciding the columns here based on the Roles
    if (hasEmergencyContactAccess(currentUser)) {
      baseColumns.push(
        {
          field: 'emergencyContactName',
          headerName: 'Emergency Contact Name',
          headerStyle: {
            borderBottom: '0.5px solid black',
            fontWeight: '900',
          },
          cellStyle: {
            borderBottom: '0.1px solid black',
          },
        },
        {
          field: 'emergencyContactRelationship',
          headerName: 'Emergency Contact Relationship',
          headerStyle: {
            borderBottom: '0.5px solid black',
            fontWeight: '900',
          },
          cellStyle: {
            borderBottom: '0.1px solid black',
          },
        },
        {
          field: 'emergencyContactPhone',
          headerName: 'Emergency Contact Phone#',
          headerStyle: {
            borderBottom: '0.5px solid black',
            fontWeight: '900',
          },
          cellStyle: {
            borderBottom: '0.1px solid black',
          },
        }
      );
    }

    return baseColumns;
  }, [currentUser]);

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={formattedCareCoordinators}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={careCoordinators.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

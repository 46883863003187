import React from 'react';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const displayValue = (item, col) => {
  let txt;
  if (col?.getData) {
    txt = col?.getData(item);
  } else if (col?.key) {
    txt = item[col?.key];
  }
  return txt || '';
};

function Table({ cols, data, actions, filePreviewHandler = () => {}, sx }) {
  // Changed 'action' to 'actions'
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Define which columns to hide on small screens
  const hideOnSmall = ['graduating_school']; // Adjust based on your requirements

  return (
    <TableContainer component={Paper} sx={{ overflowX: 'auto', ...sx }}>
      <MUITable
        sx={{ minWidth: 650 }} // Ensures table has a minimum width for horizontal scrolling
        aria-label="responsive table"
        size="small"
      >
        {/* Table Head */}
        <TableHead>
          <TableRow>
            {cols?.map((col, index) => {
              if (isSmallScreen && hideOnSmall.includes(col.key)) {
                return null; // Hide this column on small screens
              }
              return <TableCell key={index}>{col?.label}</TableCell>;
            })}
            {actions && actions.length > 0 && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {cols?.map((col, colIndex) => {
                if (isSmallScreen && hideOnSmall.includes(col.key)) {
                  return null; // Hide this cell on small screens
                }
                return (
                  <TableCell key={colIndex}>
                    {col?.key === 'fileName' ? (
                      <Box
                        component="span"
                        onClick={() => filePreviewHandler(row)}
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          color: '#05b9f5',
                          '&:hover': {
                            color: '#0288d1',
                          },
                          fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
                        }}
                      >
                        {row?.[col?.key]}
                      </Box>
                    ) : (
                      displayValue(row, col)
                    )}
                  </TableCell>
                );
              })}
              {actions && actions.length > 0 && (
                <TableCell>
                  {actions.map((singleAction, actionIndex) => (
                    <Tooltip
                      title={singleAction?.tooltip || ''}
                      key={actionIndex}
                      arrow // Adds an arrow to the tooltip for better visibility
                      placement="top" // Positions tooltip above the icon
                    >
                      <IconButton
                        onClick={() => singleAction?.actionHandler(row)}
                        color={singleAction?.color || 'default'}
                        sx={{
                          padding: { xs: '4px', sm: '6px' }, // Responsive padding
                          margin: { xs: '0 2px', sm: '0 4px' }, // Responsive margin
                        }}
                        aria-label={singleAction?.tooltip || 'Action'}
                      >
                        {singleAction?.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableCell>
              )}
            </TableRow>
          ))}
          {data?.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={cols?.length + (actions ? 1 : 0)}
                align="center"
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}

export default Table;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { hasSuperAdminAccess } from '../../common/utils/RoleUtils';
import { clearLoading, setLoading } from '../../actions/loading';
import moment from 'moment';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';
import useAdmins from '../../hooks/useAdmins';

function compareAdminFullName(a, b) {
  const lastNameA = a.admin_name ? a.admin_name.split(' ')[1] || '' : '';
  const lastNameB = b.admin_name ? b.admin_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

const formatScheduleForDisplay = (schedule) => {
  if (!schedule || !Array.isArray(schedule)) return 'No schedule';

  return schedule
    .map((sch) => {
      const days = sch.days.join(', '); // Display days as a comma-separated list
      const startTime = new Date(sch.startTime).toLocaleTimeString([], {
        timeStyle: 'short',
      });
      const endTime = new Date(sch.endTime).toLocaleTimeString([], {
        timeStyle: 'short',
      });
      return `${days} from ${startTime} to ${endTime} (${sch.setting})`;
    })
    .join('\n'); // Join each schedule entry with a newline
};

const tableHeaderCellStyle = {
  borderBottom: '0.5px solid black',
  fontWeight: '900',
  fontSize: '1.0rem', // Increase font size
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#F5F5F5',
};

const tableCellStyle = { borderBottom: '0.1px solid black' };

export default function AdminPracticeInfoTable({ showActiveAdmins }) {
  // The below is the hook for fetching the Admins
  const { admins, isLoading } = useAdmins(showActiveAdmins);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { user: currentUser } = useSelector((state) => state.auth);

  // Below dispatching
  const dispatch = useDispatch();

  // Below functions for showing and hiding the loading
  const showLoading = useCallback(() => dispatch(setLoading()), [dispatch]);
  const hideLoading = useCallback(() => dispatch(clearLoading()), [dispatch]);

  // Below useEffect setting Loader here
  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedAdmins = useMemo(() => {
    return admins
      .sort(compareAdminFullName)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [admins, page, rowsPerPage]);

  const formattedAdmins = useMemo(() => {
    return displayedAdmins.map((admin) => {
      const dob = admin.date_of_birth
        ? moment.utc(admin.date_of_birth).format('M/D/YY')
        : '';
      const age = admin.date_of_birth
        ? moment().diff(moment(admin.date_of_birth), 'years')
        : '';
      const dateOfBirthWithAge = `${dob}${age ? ` (${age} yrs)` : ''}`;

      const homeAddress = `${admin.address1 || ''} ${admin.address2 || ''}\n${
        admin.city || ''
      }, ${admin.state || ''} ${admin.zip || ''}`.trim();

      return {
        ...admin,
        date_of_birth: dateOfBirthWithAge,
        address: homeAddress,
        schedule: formatScheduleForDisplay(admin.schedule), // Format schedule for display
        hipaa:
          admin.hipaa != null
            ? admin.hipaa === true || admin.hipaa === 1
              ? 'Yes'
              : 'No'
            : 'Unavailable', // Ensure HIPAA field is properly mapped
      };
    });
  }, [displayedAdmins]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'admin_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'schedule',
        headerName: 'Schedule & Location',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          whiteSpace: 'pre-wrap', // Allows line breaks in cell content
        },
      },
      {
        field: 'date_start',
        headerName: 'Start Date',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'hipaa',
        headerName: 'HIPAA WorkForce',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'hrs_p_week',
        headerName: 'Hours Per Week',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ];

    if (!showActiveAdmins) {
      baseColumns.splice(4, 0, {
        field: 'date_end',
        headerName: 'End Date',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    if (hasSuperAdminAccess(currentUser)) {
      baseColumns.push({
        field: 'pw',
        headerName: 'Password',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    return baseColumns;
  }, [currentUser, showActiveAdmins]);

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={formattedAdmins}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={admins.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

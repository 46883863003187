import React, { useState, useEffect } from 'react';
import { Box, TableContainer, Typography } from '@mui/material';
import ResponsiveTabs from '../UI/ResponsiveTabs';
import { useMediaQuery, useTheme } from '@mui/material';
import GroupGeneralTable from './GroupGeneralTable';
import GroupLocationsTable from './GroupLocationsTable';
import GroupPasswordsTable from './GroupPasswordsTable';
import RoomScheduleTable from './RoomScheduleTable';
import styles from '../../styles/ProviderMetrics.module.css';

import useGroupData from '../../hooks/useGroupData';

export default function Group() {
  // Below is the hook for getting the Group Data
  const { groupInfo, locations, passwords, isLoading, error } = useGroupData();

  const [activeTab, setActiveTab] = useState('basic-info');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // State to hold fetched Group information

  // Component for tab labels with descriptions
  const TabLabelWithDescription = ({ title, description }) => (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: isSmallScreen ? '1.25rem' : '1rem' }}>
        {title}
      </div>
      <div className={styles.tabDescription}>{description}</div>
    </div>
  );

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');
    setActiveTab(newValue); // newValue corresponds to tab.value
  };

  // Define the tabs array
  const tabs = [
    {
      index: 0,
      value: 'basic-info',
      title: 'Basic Info',
      label: (
        <TabLabelWithDescription
          title="Basic Info"
          description="Practice Locations, Contact Info, etc"
        />
      ),
      className:
        activeTab === 0 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 1,
      value: 'locations',
      title: 'Locations',
      label: (
        <TabLabelWithDescription
          title="Locations"
          description="Manage Locations"
        />
      ),
      className:
        activeTab === 1 ? styles.customTabSelected : styles.customTabRoot,
    },

    {
      index: 2,
      value: 'room_schedule',
      title: 'Room Schedule',
      label: (
        <TabLabelWithDescription
          title="Room Schedule"
          description="View Room by Room Schedule of All Offices"
        />
      ),
      className:
        activeTab === 2 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 3,
      value: 'inventory',
      title: 'Inventory',
      label: (
        <TabLabelWithDescription
          title="Office Inventory"
          description="Furniture, Electronics, Etc"
        />
      ),
      className:
        activeTab === 3 ? styles.customTabSelected : styles.customTabRoot,
    },

    {
      index: 4,
      value: 'passwords',
      title: 'Passwords',
      label: (
        <TabLabelWithDescription
          title="Passwords"
          description="Manage Passwords"
        />
      ),
      className:
        activeTab === 4 ? styles.customTabSelected : styles.customTabRoot,
    },
  ];

  // No conditional tabs in this case, but keeping the structure consistent
  const visibleTabs = tabs.filter((tab) => {
    if (tab.condition === undefined) return true;
    return tab.condition;
  });

  // Ensure activeTab is within bounds
  useEffect(() => {
    if (activeTab >= visibleTabs.length) {
      setActiveTab(0);
    }
  }, [visibleTabs.length, activeTab]);

  // Function to render the content based on the actual tab index
  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'basic-info':
        return (
          <GroupGeneralTable groupInfo={groupInfo} isLoading={isLoading} />
        );
      case 'locations':
        return (
          <GroupLocationsTable locations={locations} isLoading={isLoading} />
        );
      case 'passwords':
        return (
          <GroupPasswordsTable passwords={passwords} isLoading={isLoading} />
        );

      case 'room_schedule':
        return <RoomScheduleTable />;

      case 'inventory':
        return (
          <Typography variant="h6" sx={{ padding: '16px' }}>
            Office Inventory - Coming Soon
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Tabs Component */}
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          width: '100%',
          marginTop: isSmallScreen ? '56px' : '75px', // Adjust margin for mobile
        }}
      >
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            padding: isSmallScreen ? '0 8px' : '0', // Adjust padding for mobile
          }}
        >
          <ResponsiveTabs
            tabs={visibleTabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            useUniqueValues={true}
          />
        </Box>
      </div>

      {/* Toggle Switch */}
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        ></div>
      </TableContainer>

      {/* Display Error Message if Any */}
      {error && (
        <Typography
          variant="h6"
          color="error"
          sx={{ padding: '16px', marginTop: '20px' }}
        >
          Error: {error}
        </Typography>
      )}

      {/* Render Active Tab Content */}
      {renderActiveTabContent()}
    </div>
  );
}

// FilesMapping.jsx
import React, { useEffect, useState, memo } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Corrected import
import {
  CloudUpload as CloudUploadIcon,
  Cached as CachedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import Table from '../Table';

function FilesMapping({
  setConfirmDelete,
  setSelectedFileId,
  value,
  cols,
  childValue,
  showUploadButton,
  handleChangeChild,
  uploadFileHandler,
  data,
  currentFolderType,
  moveToExpiredHandler,
}) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (value === '1') {
      setTableData(data.filter((v) => v?.type === 'current') || []);
    } else if (value === '2') {
      setTableData(data.filter((v) => v?.type === 'expired') || []);
    }
  }, [data, value]);

  const getButtonLabelAndTooltip = () => {
    if (currentFolderType === 'liability' && tableData.length > 0) {
      return {
        label: 'Renew Malpractice',
        tooltip:
          'Click to renew your malpractice insurance. This will upload the renewed documents to the system.',
      };
    } else {
      return {
        label: 'Upload File',
        tooltip: 'Upload a new file to the system.',
      };
    }
  };

  const { label, tooltip } = getButtonLabelAndTooltip();

  // Refactored: Action objects with actionHandler accepting a row
  const deleteAction = {
    tooltip: 'Delete',
    actionHandler: (file) => {
      setConfirmDelete(true);
      setSelectedFileId(file.file_id);
    },
    icon: <DeleteIcon />,
    color: 'error',
  };

  const moveToExpiredAction = {
    tooltip: 'Move To Expired',
    actionHandler: (file) => {
      moveToExpiredHandler(currentFolderType, file.file_id);
      setSelectedFileId(file.file_id);
    },
    icon: <CachedIcon sx={{ height: 16, width: 16 }} />,
    color: 'info',
  };

  // Prepare actions for the Table component
  const getTableActions = () => {
    const actionsArray = [deleteAction];
    if (
      value === '1' &&
      (currentFolderType === 'liability' || currentFolderType === 'license')
    ) {
      actionsArray.push(moveToExpiredAction);
    }
    return actionsArray;
  };

  return (
    <Box mt={4} p={2} sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* Main Content */}
        <Grid item xs={12} sx={{ width: '100%' }}>
          {showUploadButton && (
            <Box display="flex" mb={2}>
              <Tooltip title={tooltip}>
                <Button
                  onClick={uploadFileHandler}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  {label}
                </Button>
              </Tooltip>
            </Box>
          )}
          <Grid container spacing={2}>
            <Box width="100%">
              {tableData.length > 0 && (
                <Table
                  filePreviewHandler={(file) =>
                    window.open(
                      `https://drive.google.com/file/d/${file.file_id}/view`
                    )
                  }
                  cols={cols}
                  data={tableData}
                  actions={getTableActions()} // Changed 'action' to 'actions'
                  sx={{
                    width: '100%',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(FilesMapping);

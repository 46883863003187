import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';

const GroupPasswordsTable = ({ passwords, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '10px' }}>
          Loading Passwords...
        </Typography>
      </Box>
    );
  }

  // **Ensure passwords is an array**
  const displayPasswords = Array.isArray(passwords) ? passwords : [];

  if (displayPasswords.length === 0) {
    return (
      <Typography variant="h6" sx={{ padding: '16px', marginTop: '20px' }}>
        No Passwords Available
      </Typography>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: '20px', marginBottom: '20px' }}
    >
      <Typography variant="h6" sx={{ padding: '16px' }}>
        Password Management
      </Typography>
      <Table>
        {/* Table header */}
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Label</strong>
            </TableCell>
            <TableCell>
              <strong>Password</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Displaying the Passwords */}
          {displayPasswords.map((pw, idx) => (
            <TableRow key={idx}>
              <TableCell>{pw.label || 'N/A'}</TableCell>
              <TableCell>{pw.password || 'N/A'}</TableCell>
              {/* Display Actual Password */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupPasswordsTable;

import React, { useMemo, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useGroupData from '../../hooks/useGroupData';
import { clearLoading, setLoading } from '../../actions/loading';
import useCareCoordinators from '../../hooks/useCareCoordinators';
import useAdmins from '../../hooks/useAdmins';
import useProvidersGeneral from '../../hooks/useProviderGeneral';
import useProvidersProfessional from '../../hooks/useProviderProfessional';
import {
  groupDays,
  formatTime,
  formatLocationAddress,
} from '../../common/utility';
import { FormatProviderNameWithBoldLastName } from '../../common/utils/FormatProviderNameWithBoldLastName';

function CheatSheet() {
  const { groupInfo, locations, passwords } = useGroupData();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  // The below Loaders for showing Loading
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  // The below Loaders for showing Loading
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  // Hook below getting CareCoordinators from the BE. True Flag means Active
  const { careCoordinators, isLoading: isCareCoordinatorsLoading } =
    useCareCoordinators(true);

  // Hook below getting Admins from the BE. True Flag means Active
  const { admins, isLoading: isAdminsLoading } = useAdmins(true);

  // Hook below getting providers from the BE. True Flag means Active
  const { providers: generalProviders, isLoading: isGeneralProviderLoading } =
    useProvidersGeneral(true); // Force active providers

  // Hook below getting Professional providers from the BE. True Flag means Active
  const { providers: professionalProviders } = useProvidersProfessional(true); // Force active providers

  let docUploadPassword = 'Not set';
  let clientUploadPassword = 'Not set';

  if (passwords && passwords.length > 0) {
    const docUploadPwObj = passwords.find(
      (pw) => pw.label === 'Doc Upload PW (Internal)'
    );
    const clientUploadPwObj = passwords.find(
      (pw) => pw.label === 'Client Upload PW (from website)'
    );
    docUploadPassword = docUploadPwObj ? docUploadPwObj.password : 'Not set';
    clientUploadPassword = clientUploadPwObj
      ? clientUploadPwObj.password
      : 'Not set';
  }

  // the below useEffect for showing the loaders
  useEffect(() => {
    if (
      isAdminsLoading ||
      isCareCoordinatorsLoading ||
      isGeneralProviderLoading
    ) {
      showLoading();
    } else {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminsLoading, isCareCoordinatorsLoading, isGeneralProviderLoading]);

  const formattedProviders = useMemo(() => {
    const sortedProviders = generalProviders
      .map((provider) => {
        const isExternProvider = provider.isExtern;
        const yearOfBirth = provider.date_of_birth?.split('-')[0];
        const age = yearOfBirth
          ? new Date().getFullYear() - parseInt(yearOfBirth, 10)
          : 'Unknown';
        // 1. Start with either `provider_name` or `provider_name**` if extern
        const baseName = isExternProvider
          ? `${provider.provider_name}**`
          : provider.provider_name;

        // 2. If supervised, append "/SupervisorLastName"
        const isSupervised =
          provider.supervisor_name && provider.supervisor_name.trim() !== '';
        const supervisorLastName = isSupervised
          ? provider.supervisor_name.split(',')[0].split(' ').slice(-1).join('')
          : null;

        const nameWithSupervisor = isSupervised
          ? `${baseName}/${supervisorLastName}`
          : baseName;
        return {
          id: provider.id,
          // Actually use nameWithSupervisor in the returned object
          nameWithSupervisor,
          providerName: provider.provider_name, // for sorting
          marriedName: provider.marriedName || '',
          ext: provider.ext || 'N/A',
          yearOfBirth: yearOfBirth || 'Unknown',
          age,
          inTestingOnly: provider.in_testing && !provider.in_therapy,
        };
      })
      .sort((a, b) => {
        // Extract last name for sorting
        const aLastName = a.providerName.split(',')[0].split(' ').slice(-1)[0];
        const bLastName = b.providerName.split(',')[0].split(' ').slice(-1)[0];
        return aLastName.localeCompare(bLastName);
      });

    return sortedProviders;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalProviders, professionalProviders]);

  const splitProviders = useMemo(() => {
    const midpoint = Math.ceil(formattedProviders.length / 2);
    return {
      firstSet: formattedProviders.slice(0, midpoint),
      secondSet: formattedProviders.slice(midpoint),
    };
  }, [formattedProviders]);

  const line1 = '(PsychCare Psychological Services)'; // Hardcode as requested
  const line2 = '(No Psychiatrists (no meds prescribed))'; // Hardcode as requested

  const pikesvilleLoc = locations.find((loc) =>
    loc.location_name?.toLowerCase().includes('baltimore')
  );

  const columbiaLoc = locations.find((loc) =>
    loc.location_name?.toLowerCase().includes('columbia')
  );
  const silverSpringLoc = locations.find((loc) =>
    loc.location_name?.toLowerCase().includes('silver spring')
  );

  const combinedAdminData = useMemo(() => {
    if (isCareCoordinatorsLoading || isAdminsLoading) return [];
    return [
      ...careCoordinators.map((cc) => ({
        ...cc,
        type: 'Care Coordinator', // Add a type field for clarity
        name: cc.care_coordinator_name,
      })),
      ...admins.map((admin) => ({
        ...admin,
        type: 'Admin', // Add a type field for clarity
        name: admin.admin_name,
      })),
    ];
  }, [careCoordinators, admins, isCareCoordinatorsLoading, isAdminsLoading]);

  const adminRows = useMemo(() => {
    // Sort combinedAdminData: Practice Manager first, then others alphabetically, then care coordinators
    const sortedData = combinedAdminData.sort((a, b) => {
      if (a.title === 'Practice Manager') return -1; // Practice Manager on top
      if (b.title === 'Practice Manager') return 1;

      const aIsCareCoordinator = a.type === 'Care Coordinator';
      const bIsCareCoordinator = b.type === 'Care Coordinator';
      if (aIsCareCoordinator && !bIsCareCoordinator) return 1; // Care Coordinators last
      if (!aIsCareCoordinator && bIsCareCoordinator) return -1;

      const aLastName = a.name.split(' ').slice(-1)[0].toLowerCase(); // Get last name
      const bLastName = b.name.split(' ').slice(-1)[0].toLowerCase();
      return aLastName.localeCompare(bLastName);
    });

    return sortedData.map((person) => {
      const isCareCoordinator = person.type === 'Care Coordinator';
      const schedule = Array.isArray(person.schedule) ? person.schedule : [];

      let name = person.name || '(no name)';

      const scheduleMap = {};
      const officeLines = [];

      // Build the schedule map and office lines
      schedule.forEach((sch) => {
        const start = new Date(sch.startTime);
        const end = new Date(sch.endTime);
        const startStr = start.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
        });
        const endStr = end.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
        });

        const timeKey = `${startStr}-${endStr}`;
        if (!scheduleMap[timeKey]) {
          scheduleMap[timeKey] = { days: [], start, end };
        }
        scheduleMap[timeKey].days.push(...sch.days);

        if (sch.setting.toLowerCase().includes('office')) {
          const locationName = sch.setting.replace('Office - ', '');
          let abbr = '';
          if (/balti/i.test(locationName)) abbr = 'Bal';
          else if (/colum/i.test(locationName)) abbr = 'Col';
          else if (/silver/i.test(locationName)) abbr = 'SS';
          else abbr = locationName.slice(0, 3);

          sch.days.forEach((day) => {
            officeLines.push(`${abbr} - ${day}`);
          });
        }
      });

      const typicalHoursLines = Object.entries(scheduleMap).map(
        ([timeKey, info]) => {
          const dayRange = groupDays(info.days);
          const startStr = formatTime(info.start);
          const endStr = formatTime(info.end);
          return `${dayRange} ${startStr}-${endStr}`;
        }
      );

      const typicalHoursString = typicalHoursLines.join('\n') || 'No schedule';

      // Additional logic for Admins
      if (!isCareCoordinator) {
        if (person.title === 'Practice Manager' && person.mobile_number) {
          // Practice Manager logic
          name = `${person.name} - Practice Manager\n(cell: ${person.mobile_number})`;
        } else if (
          person.title &&
          schedule.some((sch) => !/remote/i.test(sch.setting))
        ) {
          // Add office location if exactly one non-remote setting exists
          const nonRemoteLocations = schedule.filter(
            (sch) => !/remote/i.test(sch.setting)
          );
          if (nonRemoteLocations.length === 1) {
            const locationName = nonRemoteLocations[0].setting.replace(
              'Office - ',
              ''
            );
            let abbr = '';
            if (/balti/i.test(locationName)) abbr = 'Bal';
            else if (/colum/i.test(locationName)) abbr = 'Col';
            else if (/silver/i.test(locationName)) abbr = 'SS';
            else abbr = locationName.slice(0, 3);

            name = `${person.name} (${abbr}) - ${person.title}`;
          } else {
            // Multiple non-remote locations: no abbreviation
            name = `${person.name} - ${person.title}`;
          }
        } else {
          // Fully remote admins
          name = `${person.name} (virt-${person.state || 'Unknown'}) - ${
            person.title
          }`;
        }
      } else {
        // Care Coordinator logic (unchanged)
        name = `${person.name} (virt-${person.state || 'Unknown'}) - Care Co.`;
      }

      const inOfficeDaysString = isCareCoordinator
        ? 'N/A'
        : officeLines.length === 1
        ? officeLines[0].split(' - ')[1] // Only show the day for single office location
        : officeLines.join('\n') || 'N/A';

      return {
        name,
        typicalHours: typicalHoursString,
        inOfficeDays: inOfficeDaysString,
        ext: person.ext || 'N/A',
      };
    });
  }, [combinedAdminData]);

  return (
    <Box
      sx={{
        // Give some padding to the entire cheat sheet
        padding: '16px',
      }}
    >
      {/* ------------- 
          Top Hardcoded + dynamic lines 
       ------------- */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {line1}
        </Typography>
        <Typography variant="h 5" sx={{ fontWeight: 'bold', marginTop: 1 }}>
          {line2}
        </Typography>
      </Box>

      {/* ------------- 
          Fax, Outgoing, Doc Upload Info 
       ------------- */}

      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Fax: {groupInfo?.officeFaxNumber} / Outgoing Phone:{' '}
          {groupInfo?.officeOutgoingPhoneNumber} /Tax ID: 45-4090290 /{' '}
          NPI:1831529767
        </Typography>

        <Typography variant="body1">
          Doc Upload PW: {docUploadPassword} (Internal) / Client Upload PW (from
          website): {clientUploadPassword}
        </Typography>
      </Box>

      {/** Below is the Divider */}
      <Divider sx={{ my: 2 }} />

      {/* ------------- 
          3 Offices on Left 
       ------------- */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmall ? 'column' : 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        {/* LEFT: 25% on desktop */}
        <Box
          sx={{
            width: isSmall ? '100%' : '25%',
            marginRight: isSmall ? 0 : '16px',
          }}
        >
          {/* ---- Baltimore/Pikesville ---- */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              (Baltimore/Pikesville)
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              {pikesvilleLoc?.after_hours_instructions ||
                '(no after-hours instructions found)'}
            </Typography>
            <Box sx={{ mt: 1 }}>{formatLocationAddress(pikesvilleLoc)}</Box>
          </Box>

          {/* ---- Columbia ---- */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              (Columbia)
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              {columbiaLoc?.after_hours_instructions ||
                '(no after-hours instructions found)'}
            </Typography>
            <Box sx={{ mt: 1 }}>{formatLocationAddress(columbiaLoc)}</Box>
          </Box>

          {/* ---- Silver Spring ---- */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              (Silver Spring)
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
              {silverSpringLoc?.after_hours_instructions ||
                '(no after-hours instructions found)'}
            </Typography>
            <Box sx={{ mt: 1 }}>{formatLocationAddress(silverSpringLoc)}</Box>
          </Box>
        </Box>

        {/* ------------- 
            Admin Staff on Right 
         ------------- */}
        {/* RIGHT: 75% on desktop, 100% on mobile */}
        <Box
          sx={{
            width: isSmall ? '100%' : '75%',
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          component={Paper}
          elevation={2}
        >
          {/* Hardcoded headers at top of the table area */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography
              variant="h6"
              sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
            >
              Admin Staff
            </Typography>
          </Box>

          {/* The table columns: Admin Staff | Typical Hours | In office days | Ext */}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Typical Hours (ET)
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    In Office Days
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Ext</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>No Admin Staff Found</TableCell>
                  </TableRow>
                ) : (
                  adminRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.typicalHours.split('\n').map((line, idx) => (
                          <div key={idx}>{line}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {row.inOfficeDays.split('\n').map((line, idx) => (
                          <div key={idx}>{line}</div>
                        ))}
                      </TableCell>
                      <TableCell>{row.ext}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Hardcoded text at the bottom */}
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                borderTop: '1px solid #ccc',
                paddingTop: '8px',
              }}
            >
              New Client Inquiries - Ext 1 | Cancellations - Ext 3
            </Typography>
          </Box>
        </Box>
      </Box>

      {/** Below is the Divider */}
      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
          backgroundColor: '#f9f9f9',
          padding: '8px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 2 }}>
          Alphabetical List of Providers (for internal purposes only){' '}
          <span style={{ color: 'red' }}>RED</span>: no therapy
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#FFFF99',
            padding: '2px 4px',
            borderRadius: '4px',
          }}
        >
          YELLOW highlight:
        </Typography>
        <Typography>
          {' '}
          <b>RPA/LMSW | ** : Externs </b>
        </Typography>
      </Box>

      {/* Boxed Section for Providers */}
      {/* Providers: if desktop => 2 columns. if mobile => 1 column */}
      {!isSmall ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 4,
            padding: '16px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          {/* General Providers */}
          {/* FIRST COLUMN */}
          <Box sx={{ flex: 1 }}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Name/(Supervisor if RPA/LMSW)
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Ext</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Year of Birth / Age
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {splitProviders.firstSet.map((provider) => {
                    const [rawName, supervisorName] =
                      provider.nameWithSupervisor.split('/');
                    const nameToDisplay = FormatProviderNameWithBoldLastName(
                      rawName,
                      provider.marriedName,
                      provider.inTestingOnly
                    );
                    return (
                      <TableRow key={provider.id}>
                        {/* Name with conditional styles */}
                        <TableCell>
                          <span
                            style={{
                              fontWeight: provider.inTestingOnly
                                ? 'bold'
                                : 'normal', // Bold for testing-only providers
                              color: provider.inTestingOnly
                                ? 'red !important'
                                : 'inherit',
                              backgroundColor: supervisorName
                                ? '#FFFF99'
                                : 'transparent', // Yellow highlight for supervised providers
                              padding: '2px 4px',
                            }}
                          >
                            {nameToDisplay}
                          </span>
                          {supervisorName && `/${supervisorName}`}{' '}
                          {/* Append supervisor name if present */}
                        </TableCell>
                        {/* Extension */}
                        <TableCell>{provider.ext}</TableCell>
                        {/* Year of Birth / Age */}
                        <TableCell>
                          {provider.yearOfBirth} / {provider.age}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Second Set of Columns */}
          <Box sx={{ flex: 1 }}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Name/(Supervisor if RPA/LMSW)
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Ext</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Year of Birth / Age
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {splitProviders.secondSet.map((provider) => {
                    console.log(
                      'Debug color =>',
                      provider.providerName,
                      'inTestingOnly:',
                      provider.inTestingOnly
                    );
                    const [rawName, supervisorName] =
                      provider.nameWithSupervisor.split('/');
                    // Pass provider.marriedName to the format function
                    const nameToDisplay = FormatProviderNameWithBoldLastName(
                      rawName,
                      provider.marriedName,
                      provider.inTestingOnly
                    );
                    return (
                      <TableRow key={provider.id}>
                        <TableCell>
                          <span
                            style={{
                              backgroundColor: supervisorName
                                ? '#FFFF99'
                                : 'transparent',
                              padding: '2px 4px',
                            }}
                          >
                            {nameToDisplay}
                          </span>
                          {supervisorName && `/${supervisorName}`}
                        </TableCell>
                        <TableCell>{provider.ext}</TableCell>
                        <TableCell>
                          {provider.yearOfBirth} / {provider.age}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        // MOBILE: single column table
        <Box
          sx={{
            mt: 4,
            padding: '16px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Name/(Supervisor if RPA/LMSW)
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Ext</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Year of Birth / Age
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedProviders.map((provider) => {
                  const [rawName, supervisorName] =
                    provider.nameWithSupervisor.split('/');
                  const nameToDisplay = FormatProviderNameWithBoldLastName(
                    rawName,
                    provider.marriedName,
                    provider.inTestingOnly
                  );
                  return (
                    <TableRow key={provider.id}>
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: supervisorName
                              ? '#FFFF99'
                              : 'transparent',
                            padding: '2px 4px',
                          }}
                        >
                          {nameToDisplay}
                        </span>
                        {supervisorName && `/${supervisorName}`}
                      </TableCell>
                      <TableCell>{provider.ext}</TableCell>
                      <TableCell>
                        {provider.yearOfBirth} / {provider.age}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Bottom Notice */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
          backgroundColor: '#f9f9f9',
          padding: '8px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', fontStyle: 'italic', mr: 2 }}
        >
          <span style={{ color: 'red' }}>IMPORTANT:</span> ALWAYS USE SCHEDULING
          APP to determine appropriateness
        </Typography>
      </Box>
    </Box>
  );
}

export default CheatSheet;

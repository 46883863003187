import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { hasSuperAdminAccess } from '../../common/utils/RoleUtils';
import { clearLoading, setLoading } from '../../actions/loading';
import useCareCoordinators from '../../hooks/useCareCoordinators';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareCareCoordinatorFullName(a, b) {
  const lastNameA = a.care_coordinator_name
    ? a.care_coordinator_name.split(' ')[1] || ''
    : '';
  const lastNameB = b.care_coordinator_name
    ? b.care_coordinator_name.split(' ')[1] || ''
    : '';

  return lastNameA.localeCompare(lastNameB);
}

const formatDays = (daysArray) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri'];
  const indices = daysArray
    .map((day) => daysOfWeek.indexOf(day))
    .sort((a, b) => a - b);

  if (indices.length === 5 && indices[0] === 0 && indices[4] === 4) {
    return 'Weekdays'; // Sunday to Thursday
  } else if (indices.length > 1) {
    let consecutive = true;
    for (let i = 1; i < indices.length; i++) {
      if (indices[i] !== indices[i - 1] + 1) {
        consecutive = false;
        break;
      }
    }
    if (consecutive) {
      return `${daysOfWeek[indices[0]]}-${
        daysOfWeek[indices[indices.length - 1]]
      }`;
    }
  }
  return daysArray.join(', '); // Default to joining the array
};

const formatScheduleForDisplay = (schedule) => {
  if (!schedule || !Array.isArray(schedule)) return 'No schedule';

  return schedule
    .map((sch) => {
      const days = formatDays(sch.days);
      const startTime = new Date(sch.startTime).toLocaleTimeString([], {
        timeStyle: 'short',
      });
      const endTime = new Date(sch.endTime).toLocaleTimeString([], {
        timeStyle: 'short',
      });
      return `${days} from ${startTime} to ${endTime} (${sch.setting})`;
    })
    .join('\n'); // Join each schedule entry with a newline
};

export default function CareCoordinatorPracticeInfoTable({
  showActiveCareCoordinators,
}) {
  const { careCoordinators, isLoading } = useCareCoordinators(
    showActiveCareCoordinators
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { user: currentUser } = useSelector((state) => state.auth);

  // Below dispatching
  const dispatch = useDispatch();

  // Below functions for showing and hiding the loading
  const showLoading = useCallback(() => dispatch(setLoading()), [dispatch]);
  const hideLoading = useCallback(() => dispatch(clearLoading()), [dispatch]);

  // Below useEffect setting Loader here
  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Below Formatting the CareCoordinators Response
  const formattedCareCoordinators = useMemo(() => {
    return careCoordinators
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareCareCoordinatorFullName)
      .map((careCoordinator) => ({
        ...careCoordinator,
        schedule: formatScheduleForDisplay(careCoordinator.schedule),
        hipaa:
          careCoordinator.hipaa != null
            ? Number(careCoordinator.hipaa) === 1
              ? 'Yes'
              : 'No'
            : 'Unavailable',
        date_start: careCoordinator.date_start || '',
        date_end: careCoordinator.date_end || '',
      }));
  }, [careCoordinators, page, rowsPerPage]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'care_coordinator_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'schedule',
        headerName: 'Schedule & Location',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          whiteSpace: 'pre-wrap',
        },
      },
      {
        field: 'date_start',
        headerName: 'Start Date',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'hipaa',
        headerName: 'HIPAA WorkForce',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'hrs_p_week',
        headerName: 'Hours Per Week',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ];

    if (!showActiveCareCoordinators) {
      baseColumns.splice(3, 0, {
        field: 'date_end',
        headerName: 'End Date',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    if (hasSuperAdminAccess(currentUser)) {
      baseColumns.push({
        field: 'pw',
        headerName: 'Password',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    return baseColumns;
  }, [currentUser, showActiveCareCoordinators]);

  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    fontSize: '1.0rem', // Increase font size
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={formattedCareCoordinators}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={careCoordinators?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

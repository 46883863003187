import React from 'react';
import { Tooltip } from '@mui/material';

export const AcceptedInsuranceTooltip = () => (
  <Tooltip
    title={
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
        }}
      >
        <table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Accepted
              </th>
              <th
                style={{
                  textAlign: 'center',
                  paddingLeft: '5px',
                }}
              >
                Not Accepted
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Allegiance (Cigna begins w/ 77 - Payer ID 62308)
              </td>
              <td
                style={{
                  textAlign: 'center',
                  paddingLeft: '5px',
                }}
              >
                Comp Psych
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Carefirst Administrators (BCBS - Payer ID 75191)
              </td>
              <td
                style={{
                  textAlign: 'center',
                  paddingLeft: '5px',
                }}
              >
                Kaiser
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Evernorth (Cigna - Payer ID 62308)
              </td>
              <td
                style={{
                  textAlign: 'center',
                  paddingLeft: '5px',
                }}
              >
                LHI
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Meritan (Typically Aetna - Payer ID 41124)
              </td>
              <td
                style={{
                  textAlign: 'center',
                  paddingLeft: '5px',
                }}
              >
                United
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Trustmark / Luminaire (Typically Cigna - Payer ID 35182)
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  paddingRight: '5px',
                  borderRight: '1px solid #ccc',
                }}
              >
                Web TPA (Aetna or Cigna - Payer ID 75261)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  >
    <span
      style={{
        textDecoration: 'underline',
        color: 'blue',
        cursor: 'pointer',
      }}
    >
      here
    </span>
  </Tooltip>
);

export const MedicaidMcoTooltip = () => (
  <Tooltip
    title={
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
        }}
      >
        <table style={{ border: '1px solid #ccc' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Medicaid MCO's</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Aetna Better Health
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                BCBS Community Health Plan Maryland
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Jai Medical Systems
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Kaiser Permanente
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Maryland Physicians Care
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                MedStar Family Choice
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Meritan (Typically Aetna)
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Priority Partners
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                United Healthcare Community Plan
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  padding: '5px',
                  border: '1px solid #ccc',
                }}
              >
                Wellpoint Maryland
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  >
    <span
      style={{
        textDecoration: 'underline',
        color: 'blue',
        cursor: 'pointer',
      }}
    >
      here
    </span>
  </Tooltip>
);

import React, { useEffect, useState, memo } from 'react';
import { Box, Tabs, Tab, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PersonSearch as PersonSearchIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  liabilityService,
  complianceService,
  driveService,
} from '../../services';
import FilesMapping from './FilesMapping';
import { DialogComponent } from '../Dialogs';
import { clearLoading, setLoading } from '../../actions/loading';
import Input from '../Input/Input';
import Select from '../Select';
import TextArea from '../TextArea';
import {
  createOrUpdateLicenseEvent,
  getLicenseEventsAndDelete,
} from '../../common/utils/DateUtils';

// The below columns to be displayed in the menu
let defaultCols = [
  { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
  { key: 'insurance', label: 'Insurance', colsSpan: 'col-span-2' },
  {
    key: 'expiry_date',
    label: 'Expire Date',
    colsSpan: 'col-span-2',
    getData: (c) => {
      return moment(c?.expiry_date).format('MM-DD-YYYY');
    },
  },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    colsSpan: 'col-span-2',
    getData: (c) => {
      return moment(c?.createdAt).format('MM-DD-YYYY');
    },
  },
];

const parentTabs = [
  { label: 'CURRENT', value: '1' },
  { label: 'EXPIRED', value: '2' },
];

const getUpdatedCredentialingTaskCreated = (
  credentialing_tasks_created,
  changesMadeForEvents,
  selectedUserDetail
) => {
  const credentialingTasksCreatedCopy = { ...credentialing_tasks_created };

  if (changesMadeForEvents.liability === true) {
    delete credentialingTasksCreatedCopy[`liability-${selectedUserDetail.id}`];
  }

  return credentialingTasksCreatedCopy;
};

function LiabilityComponent({
  selectedUser,
  selectedUserDetail,
  setTriggerAPICall,
  accordionToOpen,
  activeLiabilityType,
  setActiveLiabilityType,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childTabForCurrent, setChildTabForCurrent] = useState([]);
  const [childTabForExpired, setChildTabForExpired] = useState([]);
  const [childValue, setChildValue] = React.useState('liability');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [liabilityExpiryDate, setLiabilityExpiryDate] = useState();
  const [listArr, setListArr] = useState([
    {
      type: 'liability',
      fileStartingName: 'LIABILITY',
      liability: true,
      title: 'Liability',
      subTitle: 'Liability Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { insurance: '', expiry_date: '', other: '' },
    },
  ]);

  // Styled Button for consistency
  const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
  }));

  // if accordionOpen is true then opening the Dialog
  // And just before opening the Dialog making user scroll
  useEffect(() => {
    if (accordionToOpen?.includes('liability')) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
      setOpenFileUploadDialog(true);
    }
  }, [accordionToOpen]);

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    driveService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then(async (res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          let { current_liability, expired_liability } =
            res?.data?.drive_details;

          let lArray = [
            {
              type: 'liability',
              fileStartingName: 'LIABILITY',
              liability: true,
              title: 'Liability',
              subTitle: 'Liability Card',
              folderID: current_liability || expired_liability || '',
              imagePreview: '',
              data: { insurance: '', expiry_date: '', other: '' },
            },
          ];

          setListArr([...lArray]);
          setCurrentFolderType('liability');
          setChildValue('liability');
          setChildTabForCurrent([{ label: 'Liability', value: 'liability' }]);
          setChildTabForExpired([{ label: 'Liability', value: 'liability' }]);
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveService
      .moveToExpire({
        type: type,
        file_id: id,
        provider_id: selectedUser?.id,
      })
      .then((res) => {
        dispatch(clearLoading());
        if (res?.data?.msg) {
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);

          // The below I am getting the event and then deleting it.
          getLicenseEventsAndDelete(selectedUserDetail?.provider_name);

          enqueueSnackbar('File Moved Successfully', {
            variant: 'success',
            timeout: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      setCols([
        { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
        { key: 'insurance', label: 'Insurance', colsSpan: 'col-span-2' },
        {
          key: 'expiry_date',
          label: 'Expire Date',
          colsSpan: 'col-span-2',
          getData: (c) => {
            return moment(c?.expiry_date).format('MM-DD-YYYY');
          },
        },
        {
          key: 'createdAt',
          label: 'Uploaded Date',
          colsSpan: 'col-span-2',
          getData: (c) => {
            return moment(c?.createdAt).format('MM-DD-YYYY');
          },
        },
      ]);

      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          if (res?.data?.data?.files) {
            // Sort the data by expiry date in descending order
            const sortedData = res.data.data.files.sort((a, b) => {
              const dateA = moment(a.expiry_date);
              const dateB = moment(b.expiry_date);
              return dateB.diff(dateA);
            });

            setData(sortedData);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  // Synchronize currentFolderType when activeLiabilityType changes
  useEffect(() => {
    if (activeLiabilityType === 'current') {
      setCurrentFolderType(
        childTabForCurrent?.length > 0 && childTabForCurrent[0]?.value
      );
      setChildValue(
        childTabForCurrent?.length > 0 && childTabForCurrent[0].value
      );
    } else if (activeLiabilityType === 'expired') {
      setCurrentFolderType(
        childTabForExpired?.length > 0 && childTabForExpired[0]?.value
      );
      setChildValue(
        childTabForExpired?.length > 0 && childTabForExpired[0].value
      );
    }
  }, [activeLiabilityType, childTabForCurrent, childTabForExpired]);

  const handleChange = (event, newValue) => {
    // Map '1' to 'current' and '2' to 'expired'
    const liabilityType = newValue === '1' ? 'current' : 'expired';
    console.log('Tab changed to:', liabilityType); // Log the new liability type
    setActiveLiabilityType(liabilityType);
  };

  const handleChangeChild = (event, newValue) => {
    setData([]);
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: selectedFileId, type: childValue })
      .then((res) => {
        if (res?.data?.msg) {
          dispatch(clearLoading());
          // The below I am getting the event and then deleting it.
          getLicenseEventsAndDelete(selectedUserDetail?.provider_name);

          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Delete Successfully', {
            variant: 'success',
            timeout: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = () => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHandler = (event, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };

  const onChangeHandler = (e, index) => {
    let lArray = [...listArr];
    listArr[index].data = {
      ...listArr[index].data,
      [e.target.name]: e.target.value,
    };
    setListArr([...lArray]);

    // eslint-disable-next-line eqeqeq
    if (e.target.name == 'expiry_date') {
      // converting the below date to utc
      setLiabilityExpiryDate(e.target.value);
    }
  };

  const submitHandler = async () => {
    try {
      // here have to write the function
      const updatedCredentialingTaskCreated =
        getUpdatedCredentialingTaskCreated(
          selectedUserDetail?.credentialing_tasks_created,
          { liability: true },
          selectedUserDetail
        );

      // here I am updating the Credentialing Task created column for liability
      await complianceService.updateCredentialingTaskCreatedForLiability({
        providerId: selectedUserDetail.id,
        credentialing_tasks_created: JSON.stringify(
          updatedCredentialingTaskCreated
        ),
      });

      // Move existing files in the 'current' folder to 'expired'
      const currentFiles = data.filter((file) => file.type === 'current');

      // Ensure all files are moved to the expired folder before proceeding
      await Promise.all(
        currentFiles.map((file) =>
          moveToExpiredHandler('liability', file.file_id)
        )
      );

      // Upload new files
      for (const item of listArr) {
        if (item.folderID && item.imagePreview) {
          let formData = new FormData();
          formData.append('liability', item.liability);
          for (var key in item.data) {
            formData.append(key, item.data[key]);
          }
          formData.append('file', item.imagePreview);
          formData.append('folder', item.folderID);
          formData.append('user_id', selectedUser.id);
          formData.append('folder_name', item.fileStartingName || 'LIABILITY');
          formData.append('provider_id', selectedUser.id);
          formData.append('provider_name', selectedUserDetail.provider_name);
          dispatch(setLoading());

          try {
            const response = await driveService.uploadFiles(formData);
            createOrUpdateLicenseEvent(
              selectedUserDetail?.provider_name,
              liabilityExpiryDate
            );

            await liabilityService.updateLiabilityRenewalOptions(
              {
                policyPurchased: true,
                policyAutoRenewal: false,
                contactedInsurance: false,
                contactedInsuranceAttemptCount: 0,
              },
              response?.data?.fileId
            );

            enqueueSnackbar('All File Upload Successfully', {
              variant: 'success',
              timeout: 3000,
            });
          } catch (error) {
            console.error('File upload error:', error);
          } finally {
            dispatch(clearLoading());
          }
        }
      }

      // Refresh data after upload
      setTimeout(() => {
        getDataFromDriveFolder(listArr[0].type);
      }, 200);

      // Reset the file preview and close dialog
      let resetListData = listArr.map((v) => ({ ...v, imagePreview: '' }));
      setListArr([...resetListData]);
      setOpenFileUploadDialog(false);
    } catch (error) {
      console.error('Error moving files to expired:', error);
      dispatch(clearLoading());
    }
  };

  return (
    <>
      {confirmDelete && (
        <DialogComponent
          maxWidth={'xs'}
          open={confirmDelete}
          setOpen={setConfirmDelete}
          content={
            <Box p={2}>
              <Typography variant="h6" align="center" gutterBottom>
                Are You Sure You Want to Delete
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ fontWeight: 'bold', mb: 3 }}
              >
                This Action Cannot Be Undone
              </Typography>

              <Box display="flex" justifyContent="center">
                <StyledButton
                  variant="outlined"
                  color="inherit"
                  onClick={() => setConfirmDelete(false)}
                  sx={{ mr: 2 }}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="error"
                  onClick={deleteFileHandler}
                >
                  Delete
                </StyledButton>
              </Box>
            </Box>
          }
        />
      )}

      {/* File Upload Dialog */}
      {openFileUploadDialog && (
        <DialogComponent
          maxWidth="md"
          open={openFileUploadDialog}
          setOpen={setOpenFileUploadDialog}
          content={
            <Box p={3}>
              {/* Dialog Title */}
              <Typography variant="h6" align="center" gutterBottom>
                Document{' '}
                <Typography component="span" color="info.main">
                  Upload
                </Typography>{' '}
                Center
              </Typography>

              {/* Subheading */}
              <Typography variant="body2" align="center" sx={{ mt: 0.5 }}>
                The following are the file formats that you are able to upload -
                Png, Doc, Docx, Jpeg, Pdf, jpg
              </Typography>

              {/* Note Section */}
              <Typography
                variant="body2"
                align="center"
                sx={{
                  fontStyle: 'italic',
                  fontSize: '14px',
                  mb: 2,
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold', color: 'error.main' }}
                >
                  Note:
                </Typography>{' '}
                If you haven't been able to renew your license yet but are in
                the process, please click outside this dialog box and determine
                if there is a renewal option that best suits your situation.
              </Typography>
              {listArr?.map((v, k) => (
                <React.Fragment key={k + v}>
                  {v?.type === childValue && (
                    <div className="grid grid-col-12 mx-10 mt-7">
                      <p className="col-span-12 font-semibold flex items-center mb-1">
                        {v?.title}
                        <span className="">{v?.icon}</span>
                      </p>
                      <div className="border border-gray-300 col-span-12">
                        <div className="col-span-12 grid grid-cols-12 gap-2 p-3">
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              name={'insurance'}
                              onChange={(e) => onChangeHandler(e, k)}
                              value={v?.data?.insurance}
                              label={'Name of Malpractice Insurance'}
                              data={[
                                { value: 'Allied World', name: 'Allied World' },
                                { value: 'APA Trust', name: 'APA Trust' },
                                {
                                  value: 'CPH & Associates',
                                  name: 'CPH & Associates',
                                },
                                { value: 'HPSO', name: 'HPSO' },
                                { value: 'NASW', name: 'NASW' },
                                { value: 'Preferra', name: 'Preferra' },
                                { value: 'Other', name: 'Other' },
                              ]}
                            />
                          </div>

                          <div className="col-span-12 md:col-span-6">
                            <Input
                              onChange={(e) => onChangeHandler(e, k)}
                              value={v?.data?.expiry_date}
                              name="expiry_date"
                              label={'Expiration Date'}
                              type="date"
                            />
                          </div>

                          {v?.data?.insurance === 'Other' && (
                            <div className="col-span-12">
                              <TextArea
                                onChange={(e) => onChangeHandler(e, k)}
                                value={v?.data?.other}
                                name="other"
                                label={'Other'}
                              />
                            </div>
                          )}
                        </div>

                        <div className="grid grid-cols-1 flex items-center">
                          <div
                            className={`border border-l border-gray-300 px-2 py-3 border-b ${
                              v?.imagePreview
                                ? ' border-b-green-600 '
                                : ' border-b-blue-500 '
                            }  border-b-2 `}
                          >
                            {v?.imagePreview ? (
                              <div className="flex justify-between items-center px-3">
                                <a
                                  href={
                                    v?.imagePreview
                                      ? URL.createObjectURL(v?.imagePreview)
                                      : ''
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-sky-400 underline "
                                >
                                  {v?.imagePreview?.name || 'testing.jpg'}
                                </a>
                                <div className="flex">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: 'white',
                                      color: 'skyblue',
                                      boxShadow: 'none',
                                    }}
                                    component="label"
                                  >
                                    <input
                                      type="file"
                                      accept=".doc, .docx, .jpeg, .pdf, .png, .jpg"
                                      hidden
                                      onChange={(e) =>
                                        uploadFileAsPerDocumentHandler(e, k)
                                      }
                                    />
                                    <div className="flex flex-col items-center justify-center cursor-pointer">
                                      <PublishedWithChangesIcon className="text-green-600" />
                                      <p
                                        className="text-xs"
                                        style={{
                                          fontSize: '11px',
                                          color: 'black',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Replace
                                      </p>
                                    </div>
                                  </Button>

                                  <div
                                    className="flex flex-col items-center justify-center mr-3 cursor-pointer"
                                    onClick={() => {
                                      let lArray = [...listArr];
                                      lArray[k].imagePreview = '';
                                      setListArr([...lArray]);
                                    }}
                                  >
                                    <ClearIcon className="text-red-600" />
                                    <p className="text-xs">Clear</p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <p className="text-center">
                                <input
                                  id="file_input"
                                  key={k}
                                  type="file"
                                  onChange={(e) =>
                                    uploadFileAsPerDocumentHandler(e, k)
                                  }
                                  accept=".doc, .docx, .jpeg, .pdf, .png, .jpg"
                                  style={{ display: 'none' }}
                                />
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'skyblue',
                                    boxShadow: 'none',
                                  }}
                                  component="label"
                                >
                                  Click
                                  <input
                                    type="file"
                                    accept=".doc, .docx, .jpeg, .pdf, .png, .jpg"
                                    hidden
                                    onChange={(e) =>
                                      uploadFileAsPerDocumentHandler(e, k)
                                    }
                                  />
                                </Button>
                                here to Upload a File
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {/* 
              {/* Submit Button */}
              <Box display="flex" justifyContent="center" mt={4}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={submitHandler}
                >
                  Submit
                </StyledButton>
              </Box>
            </Box>
          }
        />
      )}

      <Tabs
        className=""
        onChange={handleChange}
        value={activeLiabilityType === 'current' ? '1' : '2'}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#1c405c' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {parentTabs.map((v, index) => (
          <Tab
            key={v.value + index}
            sx={{
              '&.Mui-selected': {
                outline: 'none',
              },
            }}
            label={v?.label}
            value={v?.value}
          />
        ))}
      </Tabs>

      <FilesMapping
        moveToExpiredHandler={moveToExpiredHandler}
        currentFolderType={currentFolderType}
        setConfirmDelete={setConfirmDelete}
        setSelectedFileId={setSelectedFileId}
        value={activeLiabilityType === 'current' ? '1' : '2'}
        cols={cols}
        showUploadButton={activeLiabilityType === 'current'}
        childValue={childValue}
        handleChangeChild={handleChangeChild}
        childTab={
          activeLiabilityType === 'current'
            ? childTabForCurrent
            : childTabForExpired
        }
        uploadFileHandler={previewFileHandler}
        data={data}
      />
    </>
  );
}

export default memo(LiabilityComponent);

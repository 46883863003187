export const isAdmin = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1
  );
};

export const isCareCoordinator = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CARE_COORDINATOR') > -1
  );
};

export const isAdminOrCareCoordinator = (currentUser) => {
  return isAdmin(currentUser) || isCareCoordinator(currentUser);
};

export const isSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1
  );
};

export const isSupervisorORSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    (currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1)
  );
};

export const isOnlyDoctorOrSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length === 2 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1 &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1
  );
};

export const isDoctorAdminSupervisor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    currentUser.roles.indexOf('ROLE_ADMIN') > -1 &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') > -1
  );
};

export const isDoctor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_DOCTOR') > -1 &&
    !isDoctorAdminSupervisor(currentUser)
  );
};

export const isCredentialer = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CREDENTIALER') > -1
  );
};

export const isAuditor = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_AUDITOR') > -1
  );
};

export const isAdminOrAuditor = (currentUser) => {
  return isAdmin(currentUser) || isAuditor(currentUser);
};

export const isClaimsManager = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_CLAIMS_MANAGER') > -1
  );
};

export const isPayrollSpecialist = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.indexOf('ROLE_PAYROLL_SPECIALIST') > -1
  );
};

// Below function verifying from the ID being set in .env
export const hasSuperAdminAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
  );
};

export const hasCredentialingAccess = (currentUser) => {
  return (
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
    // eslint-disable-next-line eqeqeq
    currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
    isCredentialer(currentUser)
  );
};

export const isUserAdminOrCareCoordinator = (user) => {
  // Admin has roleId 3 and Care Coordinator has roleId 6
  const allowedRoleIds = [3, 6];
  return allowedRoleIds.includes(user.roleId);
};

export const getUserRoleInfo = (user) => {
  const roles = [
    { label: 'Admin', color: '#f44336' },
    { label: 'Provider', color: '#4caf50' },
    { label: 'Care Coordinator', color: '#2196f3' },
    { label: 'Unknown', color: '#757575' },
  ];

  if (user.isProvider === 1) {
    return roles[1];
  } else if (user.isAdmin === 1) {
    return roles[0];
  } else if (user.isCareCoordinator === 1) {
    return roles[2];
  } else {
    return roles[3];
  }
};

export const isSelectedUserSupervisee = (userDetails) => {
  return (
    Array.isArray(userDetails.degrees_and_license) &&
    !userDetails.degrees_and_license.includes('Fully Licensed')
  );
};

export const isUserCareCoordinator = (user) => {
  const allowedRoleIds = [6];
  return allowedRoleIds.includes(user.roleId);
};

export const getSortedCareCoordinatorsOnLastName = (careCoordinators) => {
  const sortedCareCoordinators = careCoordinators?.sort((a, b) => {
    const lastNameA =
      a?.care_coordinator_name?.split(' ')[1]?.trim()?.toLowerCase() || '';
    const lastNameB =
      b?.care_coordinator_name?.split(' ')[1]?.trim()?.toLowerCase() || '';

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
  return sortedCareCoordinators;
};

export const getSortedProvidersOnLastName = (providers) => {
  const sortedProviders = providers?.sort((a, b) => {
    const lastNameA =
      a?.provider_name?.split(' ')[1]?.trim()?.toLowerCase() || '';
    const lastNameB =
      b?.provider_name?.split(' ')[1]?.trim()?.toLowerCase() || '';

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
  return sortedProviders;
};

/*
The below function filters only Active Providers checking for both flags 
if providerActiveStatus or is_Active flag
*/
export const getActiveProviders = (users) => {
  return users.filter(
    (user) => user.providerActiveStatus === 1 || user.is_active === 1
  );
};

export const isOnlyDoctorOrSupervisee = (currentUser) => {
  return (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.length < 3 &&
    (currentUser.roles.indexOf('ROLE_DOCTOR') > -1 ||
      currentUser.roles.indexOf('ROLE_SUPERVISEE') > -1) &&
    currentUser.roles.indexOf('ROLE_SUPERVISOR') < 0
  );
};

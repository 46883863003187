import React, { useState, useEffect, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { groupService } from '../../services';
import { clearLoading, setLoading } from '../../actions/loading';
import { useSnackbar } from 'notistack';

const locationMap = {
  4: 'Baltimore',
  5: 'Columbia',
  6: 'Silver Spring',
};

const OfficeRoomInfo = () => {
  // Below Redux for dispatching
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  // State to Set Room Data
  const [roomData, setRoomData] = useState({
    location_id: '',
    room_letter: '',
    virtual_tour_link: '',
    room_image_url: '',
    dimensions: '',
    square_footage: '',
    multi_row_notes: '',
  });

  // State to Set Rooms
  const [rooms, setRooms] = useState([]);

  const handleChange = (field, value) => {
    setRoomData((prev) => ({ ...prev, [field]: value }));
  };

  /**
   * The function to submit or add the Room
   */
  const handleSubmit = async () => {
    try {
      // Showing the Loading here
      showLoading();
      // Create the Room
      await groupService.createOfficeRoom(roomData);
      enqueueSnackbar('Room added successfully', { variant: 'success' });
      setRoomData({
        location_id: '',
        room_letter: '',
        virtual_tour_link: '',
        room_image_url: '',
        dimensions: '',
        square_footage: '',
        multi_row_notes: '',
      });
      fetchRooms(); // Refresh the list of rooms
    } catch (error) {
      enqueueSnackbar('Error adding room', { variant: 'error' });
    } finally {
      // Hide the Loading here
      hideLoading();
    }
  };

  // Below function to fetch the Rooms
  const fetchRooms = async () => {
    try {
      // Showing the Loading here
      showLoading();
      const response = await groupService.getAllOfficeRooms();
      setRooms(response.data);
    } catch (error) {
      enqueueSnackbar('Error fetching rooms', { variant: 'error' });
    } finally {
      // hiding the Loading
      hideLoading();
    }
  };

  useEffect(() => {
    fetchRooms(); // Fetch rooms when the component loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to show Loading
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  // Function to hide Loading
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  return (
    <div>
      {/**
       * New Office Room Section
       */}
      <Paper style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6">Add New Office Room</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Location ID"
              value={roomData.location_id}
              onChange={(e) => handleChange('location_id', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Room Letter"
              value={roomData.room_letter}
              onChange={(e) => handleChange('room_letter', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Virtual Tour Link"
              value={roomData.virtual_tour_link}
              onChange={(e) =>
                handleChange('virtual_tour_link', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Room Image URL"
              value={roomData.room_image_url}
              onChange={(e) => handleChange('room_image_url', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Dimensions"
              value={roomData.dimensions}
              onChange={(e) => handleChange('dimensions', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Square Footage"
              type="number"
              value={roomData.square_footage}
              onChange={(e) => handleChange('square_footage', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Multi Row Notes"
              value={roomData.multi_row_notes}
              onChange={(e) => handleChange('multi_row_notes', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add Room
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Display the list of rooms */}
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Typography variant="h6" style={{ padding: '10px' }}>
          Office Rooms
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Room Letter</TableCell>
              <TableCell>Dimensions</TableCell>
              <TableCell>Square Footage</TableCell>
              <TableCell>Room Image</TableCell>
              <TableCell>Virtual Tour</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.id}>
                <TableCell>
                  {locationMap[room.location_id] || 'Unknown'}
                </TableCell>
                <TableCell>{room.room_letter}</TableCell>
                <TableCell>{room.dimensions}</TableCell>
                <TableCell>{room.square_footage}</TableCell>
                <TableCell>
                  <img
                    src={
                      room.room_image_url || 'https://via.placeholder.com/50'
                    }
                    alt={`Room ${room.room_letter}`}
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = 'https://via.placeholder.com/50'; // Placeholder image
                    }}
                  />
                </TableCell>
                <TableCell>
                  {room.virtual_tour_link && (
                    <a
                      href={room.virtual_tour_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Virtual Tour
                    </a>
                  )}
                </TableCell>
                <TableCell>{room.multi_row_notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default memo(OfficeRoomInfo);

import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import { Schedule, Phone, HelpOutline } from '@mui/icons-material';
import styles from '../../styles/CustomForm.module.css';
import { clientService, contactFormService } from '../../services';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ThankYouPage from '../UI/Thankyou';
import ErrorPage from '../UI/Error';
import { sanitizePayload } from '../../common/utils/ValidationUtils';
import FAQ from './FAQForm.js';

const theme = createTheme({
  palette: {
    primary: { main: '#3f51b5' },
    secondary: { main: '#f50057' },
  },
});

// The below is use to sanitize (To verify if all the fields are present)
const allFields = [
  'patientType',
  'FirstName',
  'LastName',
  'dateOfBirth',
  'relation',
  'requesterName',
  'requesterAge',
  'phoneNumber',
  'email',
  'confirmEmail',
  'serviceType',
  'testingIssue',
  'otherTestingIssue',
  'therapyType',
  'issue',
  'otherIssue',
  'insuranceCarrier',
  'insuranceMemberID',
  'daysAvailable',
  'timeOfDay',
  'interestedClinician',
  'clinician',
  'willingToSeeDifferentClinician',
  'appointmentPreference',
  'preferredLocation',
  'state',
  'descriptionOfSeekingHelp',
  'referredBy',
  'otherReferredBy',
  'newsletter',
  'status',
  'source',
];
// Helper function to validate
const validateEmail = (emailVal) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(emailVal);
};

function ContactForm() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();

  const [prospectiveClientDetails, setProspectiveClientDetails] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [hasScrolledFAQ, setHasScrolledFAQ] = useState(false);
  const [wantsConsultation, setWantsConsultation] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState(false);
  const [emailMatchError, setEmailMatchError] = useState(false);

  const [additionalTimes, setAdditionalTimes] = useState([
    { daysAvailable: '', timeOfDay: '' },
  ]);

  // Unified formData with all potential fields
  const [formData, setFormData] = useState({
    patientType: 'New Patient',
    FirstName: '',
    LastName: '',
    dateOfBirth: '',
    relation: '',
    requesterName: '',
    requesterAge: '',
    phoneNumber: '',
    email: '',
    confirmEmail: '',
    serviceType: 'Psychological Testing',
    // testingIssue: 'Autism Spectrum Disorder Evaluations for Children',
    testingIssue: 'ADHD Child Eval (Google Ads Form)',
    otherTestingIssue: '',
    therapyType: '',
    issue: '',
    otherIssue: '',
    insuranceCarrier: '',
    insuranceMemberID: '',
    daysAvailable: '',
    timeOfDay: '',
    interestedClinician: '',
    clinician: '',
    willingToSeeDifferentClinician: '',
    appointmentPreference: '',
    preferredLocation: '',
    state: '',
    descriptionOfSeekingHelp: '',
    referredBy: 'Google Ads',
    otherReferredBy: '',
    newsletter: '',
    status: 'Active',
    source: 'Google Ads Form',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    let phone = value.replace(/\D/g, '');
    if (phone.length > 10) {
      phone = phone.slice(0, 10);
    }

    let formattedPhone = phone;
    if (phone.length === 10) {
      formattedPhone = `(${phone.slice(0, 3)}) ${phone.slice(
        3,
        6
      )}-${phone.slice(6)}`;
    }

    setFormData((prev) => ({ ...prev, phoneNumber: formattedPhone }));
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, email: value }));
    setEmailError(false);
    setEmailMatchError(false);
    if (value && !validateEmail(value)) {
      setEmailError(true);
    }
  };

  const handleConfirmEmailChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, confirmEmail: value }));
    setConfirmEmailError(false);
    setEmailMatchError(false);
    if (value && formData.email && value !== formData.email) {
      setEmailMatchError(true);
    }
  };

  const handleRecaptchaChange = () => {
    setIsVerified(true);
  };

  const handleAdditionalTimeChange = (index, field, value) => {
    const updated = [...additionalTimes];
    updated[index][field] = value;
    setAdditionalTimes(updated);
  };

  const handleAddAdditionalTimes = () => {
    setAdditionalTimes([
      ...additionalTimes,
      { daysAvailable: '', timeOfDay: '' },
    ]);
  };

  const handleRemoveAdditionalTimes = (index) => {
    const updated = [...additionalTimes];
    updated.splice(index, 1);
    setAdditionalTimes(updated);
  };

  const handleBookAppointment = async (e) => {
    e.preventDefault();

    // Validate emails
    setEmailError(false);
    setConfirmEmailError(false);
    setEmailMatchError(false);

    let valid = true;
    if (!validateEmail(formData.email)) {
      setEmailError(true);
      valid = false;
    }
    if (!validateEmail(formData.confirmEmail)) {
      setConfirmEmailError(true);
      valid = false;
    }
    if (formData.email !== formData.confirmEmail) {
      setEmailMatchError(true);
      valid = false;
    }

    // Check required fields
    if (
      !formData.FirstName ||
      !formData.LastName ||
      !formData.phoneNumber ||
      !formData.email ||
      !formData.confirmEmail
    ) {
      enqueueSnackbar('Please answer all the required questions.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!isVerified) {
      enqueueSnackbar('Please fill out the captcha', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!valid) return;

    setFormLoadingState(true);

    // const testingIssueValue =
    //   'Autism Spectrum Disorder Evaluations for Children';
    const testingIssueValue = 'Google Ads Form - ADHD Eval';
    const initialBody = {
      ...formData,
      // Override or add fields if needed
      serviceType: 'Psychological Testing',
      testingIssue: testingIssueValue,
      referredBy: 'Google Ads',
      source: 'Google Ads Form',
      status: 'Active',
    };

    // For appointment, we don't necessarily need additionalTimes
    // If you want to pass them, combine them into daysAvailable field:
    const daysAvailableStr = additionalTimes
      .filter((t) => t.daysAvailable && t.timeOfDay)
      .map((t) => `${t.daysAvailable} - ${t.timeOfDay}`)
      .join(', ');

    initialBody.daysAvailable = daysAvailableStr || '';

    const body = sanitizePayload(initialBody, allFields);

    console.log('Payload being sent for Appointment:', body);

    try {
      let response;
      if (
        prospectiveClientDetails &&
        Object.keys(prospectiveClientDetails).length > 0
      ) {
        response = await contactFormService.updateCustomFormEntry(
          body,
          prospectiveClientDetails.dockHealthTaskId
        );
      } else {
        response = await contactFormService.createContactFormEntry(body);
      }

      if (response.status === 200) {
        setShowThankYouPage(true);
      } else {
        enqueueSnackbar('Something went wrong, form not submitted', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setShowErrorPage(true);
    } finally {
      setFormLoadingState(false);
    }
  };

  const handleBookConsultation = async (e) => {
    e.preventDefault();

    setEmailError(false);
    setConfirmEmailError(false);
    setEmailMatchError(false);

    let valid = true;
    if (!validateEmail(formData.email)) {
      setEmailError(true);
      valid = false;
    }
    if (!validateEmail(formData.confirmEmail)) {
      setConfirmEmailError(true);
      valid = false;
    }
    if (formData.email !== formData.confirmEmail) {
      setEmailMatchError(true);
      valid = false;
    }

    // Check required fields
    if (
      !formData.FirstName ||
      !formData.LastName ||
      !formData.phoneNumber ||
      !formData.email ||
      !formData.confirmEmail
    ) {
      enqueueSnackbar('Please answer all the required questions.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!isVerified) {
      enqueueSnackbar('Please fill out the captcha', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!valid) return;

    setFormLoadingState(true);

    // const testingIssueValue =
    //   '15 Min Phone Consult for Autism Spectrum Disorder Evaluations for Children';
    const testingIssueValue = 'Phone Consult - ADHD Eval - Google Ads Form';

    const initialBody = {
      ...formData,
      serviceType: 'Psychological Testing',
      testingIssue: testingIssueValue,
      referredBy: 'Google Ads',
      source: 'Google Ads Form',
      status: 'Active',
      // For consultation, you might not need daysAvailable depending on logic
      // If you want to include them, do similarly as above
    };

    // Handle additionalTimes if needed for consultation:
    const daysAvailableStr = additionalTimes
      .filter((t) => t.daysAvailable && t.timeOfDay)
      .map((t) => `${t.daysAvailable} - ${t.timeOfDay}`)
      .join(', ');
    initialBody.daysAvailable = daysAvailableStr || '';

    const body = sanitizePayload(initialBody, allFields);

    console.log('Payload being sent for Consultation:', body);

    try {
      let response;
      if (
        prospectiveClientDetails &&
        Object.keys(prospectiveClientDetails).length > 0
      ) {
        response = await contactFormService.updateCustomFormEntry(
          body,
          prospectiveClientDetails.dockHealthTaskId
        );
      } else {
        response = await contactFormService.createContactFormEntry(body);
      }

      if (response.status === 200) {
        setShowThankYouPage(true);
      } else {
        enqueueSnackbar('Something went wrong, form not submitted', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setShowErrorPage(true);
    } finally {
      setFormLoadingState(false);
    }
  };

  useEffect(() => {
    dispatch(setLoading());
    const queryParams = new URLSearchParams(location.search);
    const uniqueIdParam = queryParams.get('uniqueId');

    if (uniqueIdParam) {
      clientService.getProspectiveClientDetails(uniqueIdParam).then(
        (response) => {
          setProspectiveClientDetails({ ...response?.data?.data[0] });
          dispatch(clearLoading());
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log('error', _content);
          dispatch(clearLoading());
        }
      );
    } else {
      dispatch(clearLoading());
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    if (
      prospectiveClientDetails &&
      Object.keys(prospectiveClientDetails).length > 0
    ) {
      // Populate formData with existing details
      setFormData((prev) => ({
        ...prev,
        FirstName: prospectiveClientDetails.FirstName || '',
        LastName: prospectiveClientDetails.LastName || '',
        email: prospectiveClientDetails.email || '',
        confirmEmail: prospectiveClientDetails.email || '',
        phoneNumber: prospectiveClientDetails.phoneNumber || '',
      }));
    }
  }, [prospectiveClientDetails]);

  if (showErrorPage) return <ErrorPage />;
  if (showThankYouPage) return <ThankYouPage />;

  return (
    <ThemeProvider theme={theme}>
      <Card
        style={{ maxWidth: 900, margin: 'auto', marginTop: 20, padding: 20 }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {/* Left Column with Image */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  borderRadius: 1,
                }}
              >
                <img
                  src="https://www.psychcaremd.com/wp-content/uploads/2024/03/blog-6.jpg"
                  alt="Booking Graphic"
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
              </Box>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={8}>
              {/* Main Title */}
              <Typography variant="h5" align="center" gutterBottom>
                Child Comprehensive ADHD Evaluation
              </Typography>
              {/* Subtitle */}
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                sx={{ fontStyle: 'italic', fontSize: '1.1rem' }}
              >
                PsychCare has immediate availability for children who need an
                ADHD evaluation. We work with children as young as 4 years old.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Please begin by entering your first name. After reviewing the
                FAQs below, there will be additional fields where you can either
                book an appointment or consider a free 15-minute phone
                consultation if still unsure.
              </Typography>

              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="First Name *"
                  variant="outlined"
                  name="FirstName"
                  value={formData.FirstName}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box
                sx={{
                  mt: 2,
                  width: '100%',
                  overflow: 'hidden',
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <FAQ setHasScrolledFAQ={setHasScrolledFAQ} />
                {/* Optional: Placeholder or instruction */}
                {/* {hasScrolledFAQ && <Typography>You've reached the end of FAQs!</Typography>} */}
              </Box>

              {hasScrolledFAQ && (
                <>
                  <Box sx={{ mt: 4 }}>
                    <TextField
                      fullWidth
                      label="Last Name *"
                      variant="outlined"
                      name="LastName"
                      value={formData.LastName}
                      onChange={handleChange}
                      required
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </Box>

                  {/* Consent Tooltip */}
                  <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      Consent for Follow Up
                    </Typography>
                    <Tooltip
                      title="By entering your phone number, you're consenting to follow-up calls or text messages from our administrative team. We do not sell, rent, or otherwise disclose personal information collected by our site to third parties in the ordinary course of business."
                      placement="right"
                    >
                      <HelpOutline style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Email *"
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      onChange={handleEmailChange}
                      error={emailError || emailMatchError}
                      helperText={
                        emailError
                          ? 'Please enter a valid email address'
                          : emailMatchError
                          ? 'Email addresses do not match'
                          : ''
                      }
                      required
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Confirm Email *"
                      variant="outlined"
                      name="confirmEmail"
                      value={formData.confirmEmail}
                      onChange={handleConfirmEmailChange}
                      error={confirmEmailError || emailMatchError}
                      helperText={
                        confirmEmailError
                          ? 'Please confirm your email address'
                          : emailMatchError
                          ? 'Email addresses do not match'
                          : ''
                      }
                      required
                    />
                  </Box>

                  {!wantsConsultation && (
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h5" gutterBottom>
                        Ready to Book an Appointment?
                      </Typography>

                      <Box
                        sx={{
                          mt: 4, // Margin top for spacing
                          display: 'flex', // Enable flexbox
                          justifyContent: 'center', // Center horizontally
                          alignItems: 'center', // Center vertically (optional)
                          // Responsive scaling
                          transform: {
                            xs: 'scale(0.65)', // Extra-small devices
                            sm: 'scale(0.70)', // Small devices
                            md: 'scale(0.75)', // Medium devices
                            lg: 'scale(1)', // Large devices
                          },
                          transformOrigin: 'center', // Ensure scaling originates from the center
                        }}
                      >
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_CAPTACHA_KEY}
                          onChange={handleRecaptchaChange}
                        />
                      </Box>

                      <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{ maxWidth: 400, margin: '0 auto' }}
                          startIcon={<Schedule />}
                          onClick={handleBookAppointment}
                          disabled={
                            !isVerified || formLoadingState || wantsConsultation
                          }
                        >
                          {formLoadingState ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Book Appointment'
                          )}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {/**
                   * The below is unsure form when a client is not sure to book
                   * an appointment.
                   */}
                  <Box mt={4} p={2} bgcolor="#f0f0f0" borderRadius={4}>
                    <Typography variant="h6" gutterBottom>
                      Still Unsure?
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      After reviewing the FAQs, if you’re not ready to book an
                      appointment, consider reviewing the additional section
                      below or schedule a Free 15-minute Phone Consultation.
                    </Typography>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wantsConsultation}
                          onChange={(e) => {
                            setWantsConsultation(e.target.checked);
                            if (!e.target.checked) {
                              setAdditionalTimes([
                                { daysAvailable: '', timeOfDay: '' },
                              ]);
                            }
                          }}
                          color="primary"
                        />
                      }
                      label="Yes, I'm interested in the Free 15 Minute Phone Consultation"
                    />

                    {wantsConsultation && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Please select your general availability for the free
                          15 minute phone consultation:
                        </Typography>

                        {additionalTimes.map((time, index) => (
                          <Grid
                            container
                            spacing={2}
                            className={styles.formRow}
                            key={index}
                            sx={{ justifyContent: 'center' }}
                          >
                            <Grid
                              size={{ xs: 12, sm: 12, md: 6 }}
                              // className={styles.formField}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <label className={styles.formLabel}>
                                  Days Available:
                                </label>
                                <FormControl
                                  variant="outlined"
                                  required
                                  sx={{
                                    marginTop: 1,
                                    minWidth: '200px',
                                    maxWidth: 300,
                                    width: '100%',
                                    mx: { xs: 'auto', sm: 'auto', md: 0 },
                                  }}
                                >
                                  <InputLabel
                                    id={`daysAvailable-label-${index}`}
                                  >
                                    Days Available
                                  </InputLabel>
                                  <Select
                                    labelId={`daysAvailable-label-${index}`}
                                    id={`daysAvailable-${index}`}
                                    name={`daysAvailable-${index}`}
                                    value={time.daysAvailable}
                                    label="Days Available"
                                    fullWidth
                                    onChange={(e) =>
                                      handleAdditionalTimeChange(
                                        index,
                                        'daysAvailable',
                                        e.target.value
                                      )
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Please Select
                                    </MenuItem>
                                    <MenuItem value="Any Weekday">
                                      Any Weekday
                                    </MenuItem>
                                    <MenuItem value="Sunday">Sunday</MenuItem>
                                    <MenuItem value="Monday">Monday</MenuItem>
                                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                                    <MenuItem value="Wednesday">
                                      Wednesday
                                    </MenuItem>
                                    <MenuItem value="Thursday">
                                      Thursday
                                    </MenuItem>
                                    <MenuItem value="Friday">Friday</MenuItem>
                                  </Select>
                                </FormControl>

                                {additionalTimes.length > 1 && (
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveAdditionalTimes(index)
                                    }
                                    sx={{
                                      mt: 1,
                                      color: 'red',
                                      border: 'none',
                                      background: 'none',
                                      cursor: 'pointer',
                                      fontSize: '1.2em',
                                    }}
                                    aria-label="Remove availability slot"
                                  >
                                    🗑️
                                  </Button>
                                )}
                              </Box>
                            </Grid>

                            <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <label className={styles.formLabel}>
                                  Time of Day:
                                </label>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  required
                                  sx={{
                                    marginTop: 1,
                                    minWidth: '200px',
                                    maxWidth: 300,
                                    width: '100%',
                                    mx: { xs: 'auto', sm: 'auto', md: 0 },
                                    '& .MuiInputBase-root': {
                                      fontSize: '16px',
                                    },
                                  }}
                                >
                                  <InputLabel id={`timeOfDay-label-${index}`}>
                                    Time of Day
                                  </InputLabel>
                                  <Select
                                    labelId={`timeOfDay-label-${index}`}
                                    id={`timeOfDay-${index}`}
                                    name={`timeOfDay-${index}`}
                                    value={time.timeOfDay}
                                    label="Time of Day"
                                    onChange={(e) =>
                                      handleAdditionalTimeChange(
                                        index,
                                        'timeOfDay',
                                        e.target.value
                                      )
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Please Select
                                    </MenuItem>
                                    <MenuItem value="Anytime - No Restrictions">
                                      Anytime - No Restrictions
                                    </MenuItem>
                                    <MenuItem value="Mornings (8am-Noon)">
                                      Mornings (8am-Noon)
                                    </MenuItem>
                                    <MenuItem value="Early Afternoon (Noon-2pm)">
                                      Early Afternoon (Noon-2pm)
                                    </MenuItem>
                                    <MenuItem value="Later Afternoon (2pm-5pm)">
                                      Later Afternoon (2pm-5pm)
                                    </MenuItem>
                                    <MenuItem value="Evenings (5pm-9pm)">
                                      Evenings (5pm-9pm)
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}

                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={handleAddAdditionalTimes}
                            startIcon={<Schedule />}
                          >
                            + Add Additional Day/Time
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            mt: 4, // Margin top for spacing
                            display: 'flex', // Enable flexbox
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center', // Center vertically (optional)
                            // Responsive scaling
                            transform: {
                              xs: 'scale(0.70)', // Extra-small devices
                              sm: 'scale(0.75)', // Small devices
                              md: 'scale(0.85)', // Medium devices
                              lg: 'scale(1)', // Large devices
                            },
                            transformOrigin: 'center', // Ensure scaling originates from the center
                          }}
                        >
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTACHA_KEY}
                            onChange={handleRecaptchaChange}
                          />
                        </Box>

                        <Box sx={{ mt: 3, textAlign: 'center' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Phone />}
                            onClick={handleBookConsultation}
                            disabled={
                              !isVerified ||
                              formLoadingState ||
                              additionalTimes.some(
                                (time) => !time.daysAvailable || !time.timeOfDay
                              )
                            }
                          >
                            {/**
                             * if formLoadingState is true then Show circularProgress
                             */}
                            {formLoadingState ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              'Book Free 15 Minute Phone Consultation'
                            )}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default ContactForm;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as dates from 'date-arithmetic';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid'; // Ensure correct import path

function ThreeDayView({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}) {
  const range = useMemo(
    () => ThreeDayView.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <TimeGrid
      {...props}
      date={date}
      localizer={localizer}
      min={min}
      max={max}
      scrollToTime={scrollToTime}
      step={30}
      timeslots={2}
      range={range}
    />
  );
}

ThreeDayView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object.isRequired,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

// Define the range of dates the view will display (3 days)
ThreeDayView.range = (date, { localizer }) => {
  const start = localizer.startOf(date, 'day');
  const end = dates.add(start, 2, 'day'); // 3-day range

  let current = start;
  const range = [];

  while (localizer.lte(current, end, 'day')) {
    range.push(current);
    current = localizer.add(current, 1, 'day');
  }

  return range;
};

// Define how navigation should work for this view
ThreeDayView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, 'day');
    case Navigate.NEXT:
      return localizer.add(date, 3, 'day');
    default:
      return date;
  }
};

// Define the title for the current range
ThreeDayView.title = (date, { localizer }) => {
  const start = localizer.format(date, 'MMM dd', 'en');
  const end = localizer.format(dates.add(date, 2, 'day'), 'MMM dd', 'en');
  return `3-Day View: ${start} - ${end}`;
};

export default ThreeDayView;

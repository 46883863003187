import { TextField, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState, memo } from 'react';
import { driveService } from '../../services';
import { useSnackbar } from 'notistack';
import styles from '../../styles/ProviderConfig.module.css';

function DriveDetails({ selectedUser }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [sendingObj, setSendingObj] = useState({
    current_cv_id: '',
    current_diploma: '',
    current_liability: '',
    current_license_document: '',
    expired_cv: '',
    expired_diploma: '',
    expired_liability: '',
    expired_license_document: '',
    current_1099: '',
  });
  useEffect(() => {
    setLoading(true);
    driveService
      .getDriveDetail({ provider_id: selectedUser.id })
      .then((res) => {
        setSendingObj(res?.data?.drive_details);
        setLoading(false);
      });
  }, [selectedUser]);
  const onChangeHanlder = (e) => {
    setSendingObj({ ...sendingObj, [e.target.name]: e.target.value });
  };
  const submitHandler = () => {
    setLoading(true);
    if (selectedUser?.id) {
      driveService
        .updateDriveDetail({
          ...sendingObj,
          provider_id: selectedUser.id,
        })
        .then((response) => {
          setLoading(false);
          if (response && response.data && response.data.success) {
            enqueueSnackbar('Provider details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          } else {
            enqueueSnackbar('Updated Provider', {
              variant: 'success',
              timeout: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(
            'Error updating provider details. Please try again.',
            {
              variant: 'error',
              timeout: 3000,
            }
          );
        });
    } else {
      setLoading(false);
      enqueueSnackbar('No provider selected.', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  return (
    <div className={styles.tabsContentContainer}>
      <div
        style={{
          fontSize: '20px',
          marginLeft: '1px',

          marginBottom: '14px',
          fontWeight: 'semibold',
        }}
      >
        Current
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="Cv ID"
            name="current_cv_id"
            value={sendingObj.current_cv_id}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="Diploma ID"
            name="current_diploma"
            value={sendingObj.current_diploma}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="Liability ID"
            name="current_liability"
            value={sendingObj.current_liability}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="License Document ID"
            name="current_license_document"
            value={sendingObj.current_license_document}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="1099"
            name="current_1099"
            value={sendingObj.current_1099}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          fontSize: '20px',
          marginLeft: '1px',
          marginTop: '14px',
          marginBottom: '14px',

          fontWeight: 'semibold',
        }}
      >
        Expired
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="Liability ID"
            name="expired_liability"
            value={sendingObj.expired_liability}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            className="inputStyle"
            fullWidth
            autoComplete="off"
            variant="outlined"
            label="License Document ID"
            name="expired_license_document"
            value={sendingObj.expired_license_document}
            onChange={(e) => {
              onChangeHanlder(e);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={submitHandler}
            style={{ width: '200px' }}
          >
            Save
          </Button>
        </div>
      </Grid>
    </div>
  );
}

export default memo(DriveDetails);

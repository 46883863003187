import React, { useEffect, useState, memo } from 'react';
import {
  Tabs,
  Tab,
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  PublishedWithChanges as PublishedWithChangesIcon,
  Clear as ClearIcon,
  PersonSearch as PersonSearchIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { driveService } from '../../services';
import { clearLoading, setLoading } from '../../actions/loading';

function Ten99Component({
  selectedUser,
  selectedUserDetail,
  setTriggerAPICall,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = useState('1');
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [selectedFileId] = useState('');
  const [documentList, setDocumentList] = useState([
    {
      type: '1099',
      fileStartingName: '1099',
      1099: true,
      title: '1099',
      subTitle: '1099 Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
    },
  ]);

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    driveService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then((response) => {
        if (response && response.data && response.data.drive_details) {
          dispatch(clearLoading());
          const { current_1099 } = response.data.drive_details;

          const newDocumentList = [
            {
              type: '1099',
              fileStartingName: '1099',
              1099: true,
              title: '1099',
              subTitle: '1099 Card',
              folderID: current_1099 || '',
              imagePreview: '',
            },
          ];

          setDocumentList([...newDocumentList]);
          setCurrentFolderType('1099');
        } else {
          dispatch(clearLoading());
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      dispatch(setLoading());
      driveService
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.files
          ) {
            setData(response.data.data.files);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentFolderType('1099');
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveService
      .deleteFiles({ fileId: selectedFileId, type: '1099' })
      .then((response) => {
        if (response && response.data && response.data.msg) {
          dispatch(clearLoading());
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Deleted Successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const uploadFileAsPerDocumentHandler = (event, index) => {
    const tempDocumentList = [...documentList];
    tempDocumentList[index].imagePreview = event.target.files[0];
    setDocumentList([...tempDocumentList]);
  };

  const submitHandler = () => {
    for (let i = 0; i < documentList.length; i++) {
      if (documentList[i].folderID && documentList[i].imagePreview) {
        const formData = new FormData();
        formData.append('1099', documentList[i]['1099']);
        formData.append('file', documentList[i].imagePreview);
        formData.append('folder', documentList[i].folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append(
          'folder_name',
          documentList[i].fileStartingName || '1099'
        );
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        dispatch(setLoading());

        driveService
          .uploadFiles(formData)
          .then(() => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All Files Uploaded Successfully', {
              variant: 'success',
              autoHideDuration: 3000,
            });
            setTimeout(() => {
              getDataFromDriveFolder(documentList[i].type);
            }, 200);
            dispatch(clearLoading());
            setTriggerAPICall((prevTriggerCall) => !prevTriggerCall);
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
      const resetListData = documentList.map((fileItem) => ({
        ...fileItem,
        imagePreview: '',
      }));
      setDocumentList([...resetListData]);
      setOpenFileUploadDialog(false);
    }
  };

  const parentTabs = [{ label: '1099', value: '1' }];

  // Columns configuration for DataGrid
  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      minWidth: 200,
      renderHeader: (params) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 'normal',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            File Name
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: '0.7rem', lineHeight: 1 }}
          >
            (Click File Name to View File)
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const fileId = params.row?.file_id;
        const fileLink = fileId
          ? `https://drive.google.com/file/d/${fileId}/view?usp=sharing`
          : '#';

        return (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            <Link
              href={fileLink}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              color="primary"
            >
              {params.value}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Uploaded Date',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const date = params.row?.createdAt;
        return (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            {date ? moment(date).format('MM-DD-YYYY') : ''}
          </Typography>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,

      renderCell: (params) => {
        const filePreview = params.row?.imagePreview; // Check if preview exists
        const fileId = params.row?.file_id;

        const downloadFile = () => {
          if (filePreview) {
            // If preview exists, create a download link from it
            const objectURL = URL.createObjectURL(filePreview);
            const link = document.createElement('a');
            link.href = objectURL;
            link.download = params.row?.fileName || 'downloaded-file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Delay revoking the object URL to ensure download is complete
            setTimeout(() => {
              URL.revokeObjectURL(objectURL);
            }, 1000); // 1-second delay
          } else {
            // Fallback to Google Drive link if no preview exists
            const driveLink = `https://drive.google.com/uc?export=download&id=${fileId}`;
            window.open(driveLink, '_blank');
          }
        };

        return (
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              size="small"
              onClick={downloadFile}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Download
            </Button>
          </Box>
        );
      },
    },
  ];

  const rows = data.map((item, index) => ({
    ...item,
    id: item.file_id || index,
  }));

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={deleteFileHandler}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={openFileUploadDialog}
        onClose={() => setOpenFileUploadDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h6" align="center">
            Document <span style={{ color: '#0ea5e9' }}>Upload</span> Center
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            The following are the file formats that you are able to upload -
            Doc, Docx, Jpg, Jpeg, Png, Pdf
          </DialogContentText>

          {documentList.map((fileItem, fileIndex) => (
            <Box
              key={fileIndex}
              sx={{
                border: '1px solid #ccc',
                borderLeft: '2px solid #ccc',
                borderBottom: '2px solid #0ea5e9',
                p: 2,
                mt: 2,
                mb: 2,
              }}
            >
              {fileItem.imagePreview ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box flex={1} mr={2}>
                    <Link
                      href={URL.createObjectURL(fileItem.imagePreview)}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: '#0ea5e9' }}
                    >
                      {fileItem.imagePreview.name || 'uploaded-file.jpg'}
                    </Link>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <PublishedWithChangesIcon />
                        <Typography variant="caption">Replace</Typography>
                      </Box>
                      <input
                        type="file"
                        accept=".doc, .docx, .jpeg, .pdf, .jpg, .png"
                        hidden
                        onChange={(e) =>
                          uploadFileAsPerDocumentHandler(e, fileIndex)
                        }
                      />
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        const tempDocumentList = [...documentList];
                        tempDocumentList[fileIndex].imagePreview = '';
                        setDocumentList([...tempDocumentList]);
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <ClearIcon />
                        <Typography variant="caption">Clear</Typography>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Typography variant="body2" align="center" width="100%">
                  Click{' '}
                  <Button
                    variant="text"
                    component="label"
                    sx={{ color: '#0ea5e9', textDecoration: 'underline' }}
                  >
                    HERE
                    <input
                      type="file"
                      accept=".doc, .docx, .jpeg, .pdf, .jpg, .png"
                      hidden
                      onChange={(e) =>
                        uploadFileAsPerDocumentHandler(e, fileIndex)
                      }
                    />
                  </Button>{' '}
                  to Upload a File
                </Typography>
              )}
            </Box>
          ))}

          <Box display="flex" justifyContent="center" mt={4}>
            <Button variant="contained" color="primary" onClick={submitHandler}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            textTransform: 'none',
          },
          '& .Mui-selected': {
            backgroundColor: '#ebeded',
          },
        }}
      >
        {parentTabs.map((tabItem) => (
          <Tab
            key={tabItem.value}
            label={tabItem.label}
            value={tabItem.value}
            sx={{
              '&.Mui-selected': {
                color: 'white', // Adjust text color for better visibility with dark background
                backgroundColor: '#3f51b5', // Sets the background color of the selected tab
              },
            }}
          />
        ))}
      </Tabs>

      {value === '1' && (
        <Box
          sx={{
            p: { xs: 1, sm: 2, md: 2 },
            width: '100%',
            overflowX: 'auto', // Allow horizontal scroll on small devices if needed
          }}
        >
          <Box display="flex" justifyContent="flex-end" mb={2}>
            {/*
            <Button variant="contained" onClick={previewFileHandler}>
              Upload File
            </Button>
            */}
          </Box>
          <Box
            sx={{ width: '100%', minWidth: { xs: 400, sm: 600, md: '100%' } }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              disableRowSelectionOnClick
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                '.MuiDataGrid-cell': {
                  alignItems: 'center',
                },
                '.MuiDataGrid-columnHeader': {
                  lineHeight: 'normal',
                },
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default memo(Ten99Component);

import { useState, useEffect } from 'react';
import { providerService, userService } from '../services';

// Hook below getting Providers from the BE.
const useProvidersGeneral = (showActiveProviders) => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // [ADDED] Aggregates multiple user rows into one user object with roleId: []
  const aggregateUsers = (usersArray) => {
    const userMap = {};

    usersArray.forEach((u) => {
      if (!userMap[u.id]) {
        userMap[u.id] = {
          ...u,
          // Convert single roleId to an array
          roleId: [u.roleId],
        };
      } else {
        // If we already have an entry for this user, push the new roleId
        userMap[u.id].roleId.push(u.roleId);
      }
    });

    return Object.values(userMap); // Convert back to an array
  };

  // [ADDED] Helper to fetch more details about the provider (existing logic)
  const fetchProviderDetails = async (id) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || [];
      const testingLocations = response?.data?.testing?.locations || [];
      const allLocations = [...therapyLocations, ...testingLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(', ');

      return {
        location: uniqueLocations || 'N/A',
        last_attestation: response?.data?.last_attestation || 'N/A',
        last_roster_submission: response?.data?.last_roster_submission || 'N/A',
      };
    } catch (error) {
      console.error(`Error fetching details for provider ${id}:`, error);
      return {
        location: 'N/A',
        last_attestation: 'N/A',
        last_roster_submission: 'N/A',
      };
    }
  };

  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const status = showActiveProviders ? 'active' : 'inactive';

        // 1) Fetch providers and all users in parallel
        const [providersResponse, usersResponse] = await Promise.all([
          providerService.getProviders(status),
          userService.getAllUsers(),
        ]);

        console.log('Providers Response:', providersResponse);
        console.log('Users Response:', usersResponse);

        // 2) Filter out providers with null provider_name, etc.
        const fetchedProviders =
          providersResponse?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        // 3) Aggregate any duplicate user rows => user.roleId is an array
        const allUsersRaw = usersResponse?.data?.users ?? [];
        const aggregatedUsers = aggregateUsers(allUsersRaw);

        console.log('Fetched Providers:', fetchedProviders);
        console.log('Aggregated Users:', aggregatedUsers);

        // 4) Map each provider to the matching user
        const providersWithImages = fetchedProviders.map((provider) => {
          // Find the user record whose "id" matches the provider's "id"
          const matchingUser = aggregatedUsers.find(
            (u) => u.id === provider.id
          );

          // matchingUser?.roleId is an array now; e.g. [5, 15]
          const userRoleIds = matchingUser?.roleId || [];
          const isExtern = userRoleIds.includes(15);

          return {
            ...provider,
            imageUrl:
              matchingUser?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            roleId: userRoleIds, // e.g. [5, 15]
            isExtern, // true if roleId array includes 15
            in_therapy: provider.in_therapy,
            in_testing: provider.in_testing,
          };
        });

        console.log(
          'Providers with Images + aggregated roleId:',
          providersWithImages
        );

        // 5) Fetch additional details (therapy config) for each provider
        const enrichedProviders = await Promise.all(
          providersWithImages.map(async (provider) => {
            const additionalDetails = await fetchProviderDetails(provider.id);
            return { ...provider, ...additionalDetails };
          })
        );

        console.log('Enriched Providers:', enrichedProviders);

        setProviders(enrichedProviders);
      } catch (err) {
        console.error('Error fetching providers:', err);
        setError(err.message || 'An error occurred');
      } finally {
        setIsLoading(false);
        console.log('Fetch process completed.');
      }
    };

    fetchProviders();
  }, [showActiveProviders]);

  return { providers, isLoading, error };
};

export default useProvidersGeneral;

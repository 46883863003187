export function FormatProviderNameWithBoldLastName(
  fullName,
  marriedName,
  inTestingOnly
) {
  // Build a style object based on inTestingOnly
  const testingStyle = inTestingOnly
    ? { color: 'red', fontWeight: 'bold' }
    : { color: 'inherit', fontWeight: 'normal' };
  // If there's no comma, just return the name (possibly with marriedName appended) as is
  if (!fullName.includes(',')) {
    if (marriedName && marriedName.trim() !== '') {
      return (
        <span style={testingStyle}>
          {fullName} (married name: {marriedName})
        </span>
      );
    }
    return <span style={testingStyle}>{fullName}</span>;
  }
  // Example "John Doe, Ph.D."
  const [namePart, suffixPart] = fullName.split(',');
  const trimmedNamePart = namePart.trim(); // "John Doe"
  const trimmedSuffixPart = suffixPart.trim(); // "Ph.D."

  // Split namePart into words to isolate the last name
  const nameWords = trimmedNamePart.split(' ');
  if (nameWords.length < 2) {
    // No separate last name, just return it (add marriedName if present)
    if (marriedName && marriedName.trim() !== '') {
      return (
        <span style={testingStyle}>
          {trimmedNamePart} (married name: {marriedName}), {trimmedSuffixPart}
        </span>
      );
    }
    return (
      <span style={testingStyle}>
        {trimmedNamePart}, {trimmedSuffixPart}
      </span>
    );
  }

  const lastName = nameWords.pop(); // "Doe"
  const firstNames = nameWords.join(' '); // "John"

  // Build the lastName portion (bold) plus optional marriedName
  let finalLastName = <strong>{lastName}</strong>;
  if (marriedName && marriedName.trim() !== '') {
    finalLastName = (
      <>
        <strong>{lastName}</strong> (married name: {marriedName})
      </>
    );
  }

  return (
    <span style={testingStyle}>
      {firstNames} {finalLastName}
      {`, ${trimmedSuffixPart}`}
    </span>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { clearLoading, setLoading } from '../../actions/loading';
import { useSelector, useDispatch } from 'react-redux';
import ResponsiveTabs from '../UI/ResponsiveTabs';
import styles from '../../styles/ProviderConfigHomeStyles.module.css';

import {
  GroupBasicInfo,
  GroupInsuranceInfo,
  GroupSystemInfo,
  OfficeRoomInfo,
} from '../Input';

import { isDoctor } from '../../common/utils/RoleUtils';

import { groupService } from '../../services';

// Define tabs similar to ProviderConfigHome
const tabs = [
  {
    value: 'group_basic_info',
    title: 'Basic Info',
    description: 'Basic Details',
    label: <span>Basic Info</span>,
  },
  {
    value: 'group_systems',
    title: 'Systems',
    description: 'System configurations',
    label: <span>Systems</span>,
  },
  {
    value: 'group_insurance_info',
    title: 'Group Insurance Info',
    description: 'Group Insurance Information',
    label: <span>Insurance Info</span>,
  },
  {
    value: 'office_rooms', // New tab for Office Rooms
    title: 'Office Rooms',
    description: 'Manage Office Rooms',
    label: <span>Office Rooms</span>,
  },
];

export default function GroupConfigHome() {
  const [activeTab, setActiveTab] = useState('personal_info');

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user: currentUser } = useSelector((state) => state.auth);

  // update for Group Below
  const [selectedUserDetail, setSelectedUserDetail] = useState();

  // update for Group Above
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const updateGroupDetail = async () => {
    const groupDetail = await groupService.getGroupDetail();
    if (groupDetail && groupDetail.data) {
      setSelectedUserDetail(groupDetail?.data?.group);
    }
  };

  const visibleTabs = tabs; // All tabs are visible for now, no conditions used

  useEffect(() => {
    const validTab = visibleTabs.find((tab) => tab.value === activeTab);
    if (!validTab) {
      setActiveTab(visibleTabs.length > 0 ? visibleTabs[0].value : '');
    }
  }, [visibleTabs, activeTab]);

  const marginTop = !isDoctor(currentUser) && isMobile ? '30px' : '0px';

  return (
    <React.Fragment>
      <div
        className="main-container"
        style={{ marginTop: marginTop, paddingLeft: '2.3rem' }}
      >
        {/* May need to update for Group*/}

        <Box className={styles.tabContainer}>
          {/* Use ResponsiveTabs here */}
          <ResponsiveTabs
            tabs={visibleTabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            useUniqueValues={true}
          />

          {/*Showing Group Basic Info*/}
          {activeTab === 'group_basic_info' && (
            <GroupBasicInfo
              isProvider={isDoctor(currentUser)}
              showLoading={showLoading}
              hideLoading={hideLoading}
            />
          )}

          {/*Showing Group Systems*/}
          {activeTab === 'group_systems' && (
            <GroupSystemInfo
              isProvider={isDoctor(currentUser)}
              selectedUserDetail={selectedUserDetail}
              updatePGroupDetail={updateGroupDetail}
              showLoading={showLoading}
              hideLoading={hideLoading}
            />
          )}

          {/*Showing Group Insurance Info*/}
          {activeTab === 'group_insurance_info' && (
            <GroupInsuranceInfo
              isProvider={isDoctor(currentUser)}
              selectedUserDetail={selectedUserDetail}
            />
          )}

          {/*Showing Office Rooms*/}
          {activeTab === 'office_rooms' && ( // New tab content for Office Rooms
            <OfficeRoomInfo />
          )}
        </Box>
      </div>
    </React.Fragment>
  );
}

import React, { useCallback, useState, useEffect, memo } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import styles from '../../styles/ProviderConfig.module.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { groupService } from '../../services';
import { isDoctorAdminSupervisor } from '../../common/utils/RoleUtils';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const GroupInsuranceInfo = (props) => {
  // Having all the states below
  const [npi_number, setNpiNumber] = useState('');
  const [caqh_number, setCaqhNumber] = useState('');
  const [aetna_provider_number, setAetnaProviderNumber] = useState('');
  const [aetna_effective_date, setAetnaEffectiveDate] = useState('');
  const [bcbs_provider_number, setBcbsProviderNumber] = useState('');
  const [bcbs_effective_date, setBcbsEffectiveDate] = useState('');
  const [cigna_provider_number, setCignaProviderNumber] = useState('');
  const [cigna_effective_date, setCignaEffectiveDate] = useState('');
  const [hopkins_ehp_provider_number, setHopkinsEhpProviderNumber] =
    useState('');
  const [hopkinsEhpEffectiveDateValue, setHopkinsEhpEffectiveDateValue] =
    useState('');
  const [hopkins_usfhp_provider_number, setHopkinsUsfhpProviderNumber] =
    useState('');
  const [hopkinsUsfhpEffectiveDateValue, setHopkinsUsfhpEffectiveDateValue] =
    useState('');
  const [medicaid_provider_number, setMedicaidProviderNumber] = useState('');
  const [medicaid_effective_date, setMedicaidEffectiveDate] = useState('');
  const [medicare_provider_number, setMedicareProviderNumber] = useState('');
  const [medicare_effective_date, setMedicareEffectiveDate] = useState('');
  const [tricare_provider_number, setTricareProviderNumber] = useState('');
  const [tricare_effective_date, setTricareEffectiveDate] = useState('');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  // Fetch insurance info from backend if no groupPracticeInfo is provided,
  // or if you always want to fetch the latest from the server.
  useEffect(() => {
    if (!props.groupPracticeInfo) {
      showLoading();
      groupService
        .getGroupInsuranceInfo()
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.default
          ) {
            const info = response.data.data.default;
            setNpiNumber(info.npi_number || '');
            setCaqhNumber(info.caqh_number || '');
            setAetnaProviderNumber(info.aetna_provider_number || '');
            setAetnaEffectiveDate(
              info.aetna_effective_date
                ? new Date(info.aetna_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setBcbsProviderNumber(info.bcbs_provider_number || '');
            setBcbsEffectiveDate(
              info.bcbs_effective_date
                ? new Date(info.bcbs_effective_date).toISOString().split('T')[0]
                : ''
            );
            setCignaProviderNumber(info.cigna_provider_number || '');
            setCignaEffectiveDate(
              info.cigna_effective_date
                ? new Date(info.cigna_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setHopkinsEhpProviderNumber(info.hopkins_ehp_provider_number || '');
            setHopkinsEhpEffectiveDateValue(
              info.hopkins_ehp_effective_date
                ? new Date(info.hopkins_ehp_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setHopkinsUsfhpProviderNumber(
              info.hopkins_usfhp_provider_number || ''
            );
            setHopkinsUsfhpEffectiveDateValue(
              info.hopkins_usfhp_effective_date
                ? new Date(info.hopkins_usfhp_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setMedicaidProviderNumber(info.medicaid_provider_number || '');
            setMedicaidEffectiveDate(
              info.medicaid_effective_date
                ? new Date(info.medicaid_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setMedicareProviderNumber(info.medicare_provider_number || '');
            setMedicareEffectiveDate(
              info.medicare_effective_date
                ? new Date(info.medicare_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
            setTricareProviderNumber(info.tricare_provider_number || '');
            setTricareEffectiveDate(
              info.tricare_effective_date
                ? new Date(info.tricare_effective_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            );
          }
        })
        .catch((error) => {
          console.error('Error fetching group insurance info:', error);
          enqueueSnackbar('Error fetching Group Insurance info.', {
            variant: 'error',
          });
        })
        .finally(() => {
          hideLoading();
        });
    }
  }, [props.groupPracticeInfo, showLoading, hideLoading, enqueueSnackbar]);

  // If props.groupPracticeInfo is provided, initialize fields from it
  useEffect(() => {
    if (props.groupPracticeInfo) {
      setNpiNumber(props.groupPracticeInfo?.npi_number ?? '');
      setCaqhNumber(props.groupPracticeInfo?.caqh_number ?? '');
      setAetnaProviderNumber(
        props.groupPracticeInfo?.aetna_provider_number ?? ''
      );
      setAetnaEffectiveDate(
        props.groupPracticeInfo?.aetna_effective_date
          ? new Date(props.groupPracticeInfo.aetna_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setBcbsProviderNumber(
        props.groupPracticeInfo?.bcbs_provider_number ?? ''
      );
      setBcbsEffectiveDate(
        props.groupPracticeInfo?.bcbs_effective_date
          ? new Date(props.groupPracticeInfo.bcbs_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setCignaProviderNumber(
        props.groupPracticeInfo?.cigna_provider_number ?? ''
      );
      setCignaEffectiveDate(
        props.groupPracticeInfo?.cigna_effective_date
          ? new Date(props.groupPracticeInfo.cigna_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setHopkinsEhpProviderNumber(
        props.groupPracticeInfo?.hopkins_ehp_provider_number ?? ''
      );
      setHopkinsEhpEffectiveDateValue(
        props.groupPracticeInfo?.hopkins_ehp_effective_date
          ? new Date(props.groupPracticeInfo.hopkins_ehp_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setHopkinsUsfhpProviderNumber(
        props.groupPracticeInfo?.hopkins_usfhp_provider_number ?? ''
      );
      setHopkinsUsfhpEffectiveDateValue(
        props.groupPracticeInfo?.hopkins_usfhp_effective_date
          ? new Date(props.groupPracticeInfo.hopkins_usfhp_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setMedicaidProviderNumber(
        props.groupPracticeInfo?.medicaid_provider_number ?? ''
      );
      setMedicaidEffectiveDate(
        props.groupPracticeInfo?.medicaid_effective_date
          ? new Date(props.groupPracticeInfo.medicaid_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setMedicareProviderNumber(
        props.groupPracticeInfo?.medicare_provider_number ?? ''
      );
      setMedicareEffectiveDate(
        props.groupPracticeInfo?.medicare_effective_date
          ? new Date(props.groupPracticeInfo.medicare_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
      setTricareProviderNumber(
        props.groupPracticeInfo?.tricare_provider_number ?? ''
      );
      setTricareEffectiveDate(
        props.groupPracticeInfo?.tricare_effective_date
          ? new Date(props.groupPracticeInfo.tricare_effective_date)
              .toISOString()
              .split('T')[0]
          : ''
      );
    }
  }, [props.groupPracticeInfo]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      showLoading();
      // Construct payload
      const defaultPayload = {
        npi_number,
        caqh_number,
        aetna_provider_number,
        aetna_effective_date: aetna_effective_date || null,
        bcbs_provider_number,
        bcbs_effective_date: bcbs_effective_date || null,
        cigna_provider_number,
        cigna_effective_date: cigna_effective_date || null,
        hopkins_ehp_provider_number,
        hopkins_ehp_effective_date: hopkinsEhpEffectiveDateValue || null,
        hopkins_usfhp_provider_number,
        hopkins_usfhp_effective_date: hopkinsUsfhpEffectiveDateValue || null,
        medicaid_provider_number,
        medicaid_effective_date: medicaid_effective_date || null,
        medicare_provider_number,
        medicare_effective_date: medicare_effective_date || null,
        tricare_provider_number,
        tricare_effective_date: tricare_effective_date || null,
      };

      const payload = {
        default: defaultPayload,
        locations: [],
      };

      const response = await groupService.updateGroupInsuranceInfo(payload);

      if (response && response.data) {
        enqueueSnackbar('Group Insurance details updated successfully.', {
          variant: 'success',
          timeout: 3000,
        });
      }

      props.updateGroupPracticeDetail && props.updateGroupPracticeDetail();
    } catch (error) {
      console.log('handleOnSubmit.error', error);
      enqueueSnackbar('Error updating Group Insurance details.', {
        variant: 'error',
        timeout: 3000,
      });
    } finally {
      hideLoading();
    }
  };
  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the provider configuration are read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className={styles.providerNotice1}>
            The "Personal Info" section of the provider profile is read-only.
            Please reach out to Levi or Victoria if there is any information
            that you would like to update.
          </Typography>
        )}

      <div className={styles.tabsContentContainer}>
        <Grid container spacing={3} className={styles.gridItemTextField}>
          <Grid container spacing={3} className={styles.gridItemTextField}>
            {/**
             * NPI Field
             */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="npi_number"
                label="NPI #"
                value={npi_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setNpiNumber(e.target.value);
                }}
              />
            </Grid>

            {/**
             * CAQH Field
             */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="caqh_number"
                label="CAQH #"
                value={caqh_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setCaqhNumber(e.target.value);
                }}
              />
            </Grid>

            {/* Aetna Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="aetna_provider_number"
                label="Aetna Provider #"
                value={aetna_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setAetnaProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* Aetna Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="aetna_effective_date"
                label="Aetna Effective Date"
                type="date"
                value={aetna_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setAetnaEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* BCBS Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="bcbs_provider_number"
                label="BCBS Provider #"
                value={bcbs_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setBcbsProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* BCBS Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="bcbs_effective_date"
                label="BCBS Effective Date"
                type="date"
                value={bcbs_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setBcbsEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* CIGNA Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="cigna_provider_number"
                label="CIGNA Provider #"
                value={cigna_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setCignaProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* Cigna Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="cigna_effective_date"
                label="Cigna Effective Date"
                type="date"
                value={cigna_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setCignaEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* HOPKINS EHP Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="hopkins_ehp_provider_number"
                label="Hopkins EHP Provider #"
                value={hopkins_ehp_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setHopkinsEhpProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* HOPKINS EHP Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="hopkins_ehp_effective_date"
                label="Hopkins EHP Effective Date"
                type="date"
                value={hopkinsEhpEffectiveDateValue}
                disabled={props.isProvider}
                onChange={(e) => {
                  setHopkinsEhpEffectiveDateValue(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* HOPKINS USFHP FIELDS */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="hopkins_usfhp_provider_number"
                label="Hopkins USFHP Provider #"
                value={hopkins_usfhp_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setHopkinsUsfhpProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* HOPKINS USFHP Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="hopkins_usfhp_effective_date"
                label="Hopkins USFHP Effective Date"
                type="date"
                value={hopkinsUsfhpEffectiveDateValue}
                disabled={props.isProvider}
                onChange={(e) => {
                  setHopkinsUsfhpEffectiveDateValue(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* MEDICAID Provider Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="medicaid_provider_number"
                label="Medicaid Provider #"
                value={medicaid_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setMedicaidProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* MEDICAID Effective Date Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="medicaid_effective_date"
                label="Medicaid Effective Date"
                type="date"
                value={medicaid_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setMedicaidEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* MEDICARE Provider Fields */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="medicare_provider_number"
                label="Medicare Provider #"
                value={medicare_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setMedicareProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* MEDICARE Effective Date Fields */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="medicare_effective_date"
                label="Medicare Effective Date"
                type="date"
                value={medicare_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setMedicareEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* MEDICARE TRICARE Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="tricare_provider_number"
                label="Tricare Provider #"
                value={tricare_provider_number}
                disabled={props.isProvider}
                onChange={(e) => {
                  setTricareProviderNumber(e.target.value);
                }}
              />
            </Grid>

            {/* MEDICARE TRICARE Effective Field */}
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="tricare_effective_date"
                label="Tricare Effective Date"
                type="date"
                value={tricare_effective_date}
                disabled={props.isProvider}
                onChange={(e) => {
                  setTricareEffectiveDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          {/**
           * Save Button
           */}
          <Grid container spacing={3} className={styles.gridItemTextField}>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <Button
                variant="contained"
                color="primary"
                className={styles.buttonWithMargin}
                onClick={handleOnSubmit}
                disabled={props.isProvider}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(GroupInsuranceInfo);

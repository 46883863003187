import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  isCareCoordinator,
  hasCredentialingAccess,
} from '../../common/utils/RoleUtils';
import { useDispatch } from 'react-redux';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';
import { clearLoading, setLoading } from '../../actions/loading';
import useProvidersGeneral from '../../hooks/useProviderGeneral';

// Define the styles for the table header and cells
const tableHeaderCellStyle = {
  borderBottom: '0.5px solid black',
  fontWeight: '900',
  fontSize: '1.0rem', // Increase font size
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#F5F5F5',
};

const tableCellStyle = { borderBottom: '0.1px solid black' };

function compareProviderFullName(a, b) {
  // Safely split the provider_name, default to empty string if provider_name is missing
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';

  return lastNameA.localeCompare(lastNameB);
}

export default function ProviderGeneralTable({ showActiveProviders }) {
  // Below is the hook used to fetch the Providers
  const { providers, isLoading } = useProvidersGeneral(showActiveProviders);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  // The below Loaders for showing Loading
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  // The below Loaders for showing Loading
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const hasEmergencyContactAccess = (currentUser) => {
    return (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE ||
      isCareCoordinator(currentUser)
    );
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // Handle side effects for loading state
  useEffect(() => {
    if (isLoading) {
      showLoading(); // Show loading spinner
    } else {
      hideLoading(); // Hide loading spinner
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // Defining the columns with access Depending on the roles
  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'provider_name',
        headerName: 'Full Name',
        headerStyle: { width: '120px', minWidth: '120px' },
        cellStyle: { width: '120px', minWidth: '120px' },
      },
      {
        field: 'imageUrl',
        headerName: '',
        type: 'image',
        headerStyle: { width: '150px', minWidth: '150px' },
        cellStyle: { width: '150px', minWidth: '150px' },
        renderCell: (cellValues) => (
          <img
            src={cellValues.value}
            alt="Profile"
            style={{
              width: '100px', // Set the desired width
              height: '100px', // Set the desired height
              objectFit: 'cover', // Adjust how the image fits within the frame
              borderRadius: '8px', // Optional, for rounded corners
            }}
          />
        ),
      },
      {
        field: 'personal_email',
        headerName: 'Personal Email',
        headerStyle: { width: '200px', minWidth: '200px' },
        cellStyle: { width: '200px', minWidth: '200px' },
      },
      { field: 'mobile_number', headerName: 'Mobile #' },
      {
        field: 'date_of_birth',
        headerName: 'Date of Birth (Age)',
        type: 'date',
      },
    ];

    if (showActiveProviders) {
      baseColumns.splice(2, 0, {
        field: 'provider_email',
        headerName: 'PsychCare Email',
        headerStyle: { width: '250px', minWidth: '250px' },
        cellStyle: { width: '250px', minWidth: '250px' },
      });
      baseColumns.splice(4, 0, { field: 'ext', headerName: 'Ext' });
      baseColumns.push({ field: 'address', headerName: 'Home Address' });
    }

    if (hasCredentialingAccess(currentUser)) {
      baseColumns.push({ field: 'ss_num', headerName: 'SS#' });
    }

    if (showActiveProviders && hasEmergencyContactAccess(currentUser)) {
      baseColumns.push(
        { field: 'emergencyContactName', headerName: 'Emergency Contact Name' },
        {
          field: 'emergencyContactRelationship',
          headerName: 'Emergency Contact Relationship',
        },
        {
          field: 'emergencyContactPhone',
          headerName: 'Emergency Contact Phone#',
        }
      );
    }

    return baseColumns;
  }, [showActiveProviders, currentUser]);

  /**
   * Below Formatting the Providers to match with the
   * UI
   */
  const formattedProviders = useMemo(() => {
    const sortedProviders = [...providers].sort(compareProviderFullName);

    return sortedProviders.map((provider) => {
      const age = moment().diff(moment(provider?.date_of_birth), 'years');
      const formattedDOB = moment(provider?.date_of_birth ?? '').format(
        'M/D/YY'
      );

      return {
        ...provider,
        date_of_birth: `${formattedDOB} (${age} yrs)`,
        address:
          provider?.address1 ||
          provider?.address2 ||
          provider?.city ||
          provider?.state ||
          provider?.zip
            ? `${provider?.address1 || ''} ${provider?.address2 || ''}
              ${provider?.city || ''}, ${provider?.state || ''} ${
                provider?.zip || ''
              }`
            : '',
        emergencyContactRelationship:
          provider.emergencyContactRelationship === 'Other'
            ? provider.customRelationship
            : provider.emergencyContactRelationship,
      };
    });
  }, [providers]);

  const paginatedProviders = useMemo(() => {
    return formattedProviders.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [formattedProviders, page, rowsPerPage]);

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={paginatedProviders}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

import React, { useEffect, memo, useState, useCallback } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2'; // Using Grid2 as in your code
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { clearLoading, setLoading } from '../../actions/loading';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { groupService } from '../../services'; // Adjust to your service path
import styles from '../../styles/ProviderConfig.module.css'; // Adjust path if needed

const GroupSystemInfoPasswords = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  // Structure for "generalOfficeInfo" with `passwords`
  const [generalOfficeInfo, setGeneralOfficeInfo] = useState({
    passwords: [],
  });

  // The below Loaders for showing Loading
  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  // The below Loaders for showing Loading
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  // 1) On mount, fetch existing "general office info" from your backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        const response = await groupService.getAllGroupsWithLocations();
        // Expecting { generalOfficeInfo, locations } in response.data
        if (response.data) {
          const { generalOfficeInfo } = response.data;
          if (generalOfficeInfo) {
            setGeneralOfficeInfo({
              passwords: Array.isArray(generalOfficeInfo.passwords)
                ? generalOfficeInfo.passwords
                : [],
            });
          } else {
            enqueueSnackbar('No general office info found.', {
              variant: 'warning',
            });
          }
        } else {
          enqueueSnackbar('No group details available.', {
            variant: 'warning',
          });
        }
      } catch (error) {
        console.error('Error fetching general office info:', error);
        enqueueSnackbar('Failed to load general office info.', {
          variant: 'error',
        });
      } finally {
        hideLoading();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2) Add a new password entry
  const addPasswordEntry = () => {
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      passwords: [...prev.passwords, { label: '', password: '' }],
    }));
  };

  // 3) Remove an existing password entry
  const removePasswordEntry = (index) => {
    const updatedPasswords = [...generalOfficeInfo.passwords];
    updatedPasswords.splice(index, 1);
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      passwords:
        updatedPasswords.length > 0
          ? updatedPasswords
          : [{ label: '', password: '' }],
    }));
  };

  // 4) Handle changes in password entries
  const handlePasswordChange = (idx, field, value) => {
    const updated = [...generalOfficeInfo.passwords];
    updated[idx][field] = value;
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      passwords: updated,
    }));
  };

  // 5) On "Save Passwords", call `updateGroupInfoDetails` with the updated array
  const handleSavePasswords = async () => {
    try {
      // Showing Loading
      showLoading();

      // Construct the payload similarly to how you do for phone extensions
      const payload = {
        // If your backend expects certain fields,
        // e.g. outgoing_phone_number, fax_number, we can omit or keep them.
        // For this snippet, just include `passwords`.
        passwords: generalOfficeInfo.passwords,
      };

      console.log('Submitting password payload:', payload);

      // Make the API call
      await groupService.updateGroupInfoDetails(payload);

      enqueueSnackbar('Passwords updated successfully.', {
        variant: 'success',
      });
    } catch (error) {
      console.error('handleSavePasswords.error', error);
      enqueueSnackbar('Error updating passwords.', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  // 6) Render
  return (
    <React.Fragment>
      <Box className={styles.tabsContentContainer}>
        <Typography variant="h6">Password Management</Typography>

        <Paper variant="outlined" sx={{ padding: '20px', marginTop: '20px' }}>
          {generalOfficeInfo.passwords.map((pwEntry, idx) => (
            <Grid
              container
              spacing={2}
              key={idx}
              alignItems="center"
              sx={{ marginBottom: '10px' }}
            >
              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Label"
                  value={pwEntry.label}
                  onChange={(e) =>
                    handlePasswordChange(idx, 'label', e.target.value)
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Password"
                  type="text" // or "password" if you want to mask it
                  value={pwEntry.password}
                  onChange={(e) =>
                    handlePasswordChange(idx, 'password', e.target.value)
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                <IconButton
                  onClick={() => removePasswordEntry(idx)}
                  color="error"
                  aria-label="remove-password"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Button
            variant="outlined"
            startIcon={<AddCircleOutline />}
            onClick={addPasswordEntry}
            sx={{ marginTop: '10px' }}
          >
            Add Password
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSavePasswords}
            >
              Save Passwords
            </Button>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default memo(GroupSystemInfoPasswords);

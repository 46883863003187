import React, { useState } from 'react';
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  hasSuperAdminAccess,
  isCareCoordinator,
  isAdmin,
} from '../../common/utility';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CheatSheet from '../Table/CheatSheet';

/* 
This button gets appeared on the bottom right of the page Clicking on it
Opens the CheatSheet
*/
export default function FloatingCheatSheetButton({ currentUser }) {
  const [open, setOpen] = useState(false);

  // Use MUI breakpoints to detect mobile
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Decide if we should show the button (e.g., Admin, CareCoord, or SuperAdmin)
  const canShowCheatSheet =
    hasSuperAdminAccess(currentUser) ||
    isAdmin(currentUser) ||
    isCareCoordinator(currentUser);

  // If user does NOT have the roles, return null (no button)
  if (!canShowCheatSheet) {
    return null;
  }

  return (
    <>
      {/**
       * The below is the button that gets appeared. Upon clicking it opens the below
       * Dialog
       */}
      <Fab
        color="primary"
        aria-label="open cheat sheet"
        // If it's a small screen, set bottom to ~70 to clear the bottom nav
        sx={{
          position: 'fixed',
          bottom: isSmallScreen ? 70 : 16,
          right: 16,
          zIndex: 1500,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
        // Toggle open/close on each click
        onClick={() => setOpen(!open)}
      >
        <RocketLaunchIcon />
      </Fab>

      <Dialog
        open={open}
        // Clicking outside or hitting ESC also closes it
        onClose={() => setOpen(false)}
        // If you want the entire screen for mobile, set fullScreen={isSmallScreen}
        fullScreen={false}
        maxWidth="lg" // "md" or "xl" as desired
      >
        <DialogTitle>Cheat Sheet</DialogTitle>
        <DialogContent>
          <CheatSheet />
        </DialogContent>
      </Dialog>
    </>
  );
}

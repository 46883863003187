import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@mui/material';

function Select({ label, value, onChange, data, name, error, helperText }) {
  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${name}-label`}
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        // You can customize the Select component further using the `sx` prop if needed
      >
        <MenuItem value="">
          <em>Select an option</em>
        </MenuItem>
        {data?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

// Define PropTypes for better type checking and documentation
Select.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

// Define default props in case some props are not provided
Select.defaultProps = {
  error: false,
  helperText: '',
};

export default memo(Select);

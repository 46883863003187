import React, { useCallback, useEffect, memo, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';

import { providerService, groupService } from '../../services';
import { isSelectedUserSupervisee } from '../../common/utils/RoleUtils';
import styles from '../../styles/ProviderConfig.module.css';

// We keep the old database values the same ("remote_only","office","hybrid")
// but show new labels in the UI ("Remote","In-Office","In-Office (Remote)")

function convertDatabaseSettingToUI(databaseValue) {
  switch (databaseValue) {
    case 'office':
      return 'In-Office';
    case 'hybrid':
      return 'In-Office (Remote)';
    case 'remote_only':
    default:
      return 'Remote';
  }
}

function convertUISettingToDatabase(uiValue) {
  switch (uiValue) {
    case 'In-Office':
      return 'office';
    case 'In-Office (Remote)':
      return 'hybrid';
    case 'Remote':
    default:
      return 'remote_only';
  }
}

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ProviderPracticeInfo = (props) => {
  // Basic HIPAA Information States
  const [inHIPAA, setInHIPAA] = useState('');
  const [hoursPerWeek, setHoursPerWeek] = useState('');
  const [basicScheduleText, setBasicScheduleText] = useState('');

  // LLC / Tax info
  const [llcName, setLlcName] = useState('');
  const [llcAddress, setLlcAddress] = useState('');
  const [sdatNumber, setSdatNumber] = useState('');
  const [taxID, setTaxID] = useState('');

  // Multi-row approach
  const [officeSchedules, setOfficeSchedules] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allRooms, setAllRooms] = useState([]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const showLoading = useCallback(() => dispatch(setLoading()), [dispatch]);
  const hideLoading = useCallback(() => dispatch(clearLoading()), [dispatch]);

  const isDoctorAdminSupervisor = (user) =>
    user?.roles &&
    user.roles.includes('ROLE_DOCTOR') &&
    user.roles.includes('ROLE_ADMIN') &&
    user.roles.includes('ROLE_SUPERVISOR');

  // ----------------------------------
  // 1. On load: set base fields & fetch schedules
  // ----------------------------------
  useEffect(() => {
    // Initialize from selectedUserDetail
    setInHIPAA(Boolean(props.selectedUserDetail?.hipaa) ?? '');
    setHoursPerWeek(props.selectedUserDetail?.hrs_p_week ?? '');
    setBasicScheduleText(props.selectedUserDetail?.schedule ?? '');
    setLlcName(props.selectedUserDetail?.llc_name ?? '');
    setLlcAddress(props.selectedUserDetail?.llc_address ?? '');
    setSdatNumber(props.selectedUserDetail?.sdat_num ?? '');
    setTaxID(props.selectedUserDetail?.tax_id ?? '');

    const loadProviderSchedules = async () => {
      try {
        if (!props.selectedUser?.id) return;
        showLoading();

        const response = await groupService.getOfficeScheduleByProvider(
          props.selectedUser.id
        );
        let data = response.data;

        // if single object, wrap in array
        if (data && !Array.isArray(data)) {
          data = [data];
        }

        // Convert old DB setting => new UI label
        const mapped = (data || []).map((row) => ({
          ...row,
          setting: convertDatabaseSettingToUI(row.setting),
          schedule: {
            ...row.schedule,
            startTime: row.schedule?.startTime
              ? parse(row.schedule.startTime, 'HH:mm', new Date())
              : parse('09:00', 'HH:mm', new Date()),
            endTime: row.schedule?.endTime
              ? parse(row.schedule.endTime, 'HH:mm', new Date())
              : parse('17:00', 'HH:mm', new Date()),
          },
        }));

        setOfficeSchedules(mapped);
      } catch (error) {
        console.error('Error fetching provider schedules:', error);
        enqueueSnackbar('Error fetching provider schedules.', {
          variant: 'error',
        });
      } finally {
        hideLoading();
      }
    };

    loadProviderSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUser, props.selectedUserDetail]);

  // ----------------------------------
  // 2. Load locations
  // ----------------------------------
  useEffect(() => {
    const loadLocations = async () => {
      try {
        const response = await groupService.getAllGroupsWithLocations();
        setAllLocations(response.data.locations || []);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    loadLocations();
  }, []);

  // ----------------------------------
  // 3. Load Rooms
  // ----------------------------------
  useEffect(() => {
    const fetchAllRooms = async () => {
      try {
        const response = await groupService.getAllOfficeRooms();
        setAllRooms(response.data || []);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };
    fetchAllRooms();
  }, []);

  // ----------------------------------
  // 4. Handlers for multi-schedule approach
  // ----------------------------------
  const handleAddScheduleRow = () => {
    const newRow = {
      id: null,
      provider_id: props.selectedUser?.id,
      // Default to "Remote" or whichever
      setting: 'Remote',
      office_location_id: '',
      office_room_id: '',
      schedule: {
        days: [],
        startTime: parse('09:00', 'HH:mm', new Date()),
        endTime: parse('17:00', 'HH:mm', new Date()),
      },
    };
    setOfficeSchedules((prev) => [...prev, newRow]);
  };

  // Removing the Schedule Row
  const handleRemoveScheduleRow = async (index) => {
    const row = officeSchedules[index];
    if (!row) return;

    if (row.id) {
      showLoading();
      try {
        await groupService.deleteOfficeSchedule(row.id);
        enqueueSnackbar('Deleted schedule entry.', { variant: 'success' });
      } catch (error) {
        console.error('Error deleting schedule:', error);
        enqueueSnackbar('Error deleting schedule entry.', { variant: 'error' });
      } finally {
        hideLoading();
      }
    }
    // remove from state
    setOfficeSchedules((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChangeField = (index, field, value) => {
    setOfficeSchedules((prev) =>
      prev.map((row, i) => {
        if (i !== index) return row;

        if (['days', 'startTime', 'endTime'].includes(field)) {
          return {
            ...row,
            schedule: {
              ...row.schedule,
              [field]: value,
            },
          };
        }
        return { ...row, [field]: value };
      })
    );
  };

  const handleLocationChange = (index, newLocationId) => {
    handleChangeField(index, 'office_location_id', newLocationId);
  };

  // Save or update row
  const handleSaveRow = async (index) => {
    const row = officeSchedules[index];
    if (!row) return;

    // Convert UI label => old DB setting
    const databaseSetting = convertUISettingToDatabase(row.setting);

    // If it's remote_only, set location/room to null
    let finalLocationId = null;
    let finalRoomId = null;
    if (databaseSetting === 'office' || databaseSetting === 'hybrid') {
      finalLocationId = row.office_location_id
        ? Number(row.office_location_id)
        : null;
      finalRoomId = row.office_room_id ? Number(row.office_room_id) : null;
    }

    const payload = {
      provider_id: row.provider_id,
      setting: databaseSetting, // old DB values
      office_location_id: finalLocationId,
      office_room_id: finalRoomId,
      schedule: {
        days: row.schedule.days,
        startTime: row.schedule.startTime
          ? format(row.schedule.startTime, 'HH:mm')
          : '09:00',
        endTime: row.schedule.endTime
          ? format(row.schedule.endTime, 'HH:mm')
          : '17:00',
      },
    };

    try {
      showLoading();
      if (row.id) {
        // existing row => update
        await groupService.updateOfficeSchedule(row.id, payload);
        enqueueSnackbar(`Updated schedule #${row.id}`, { variant: 'success' });
      } else {
        // new => create
        const createRes = await groupService.createOfficeSchedule(payload);
        const newId = createRes.data?.id;
        enqueueSnackbar(`Created new schedule #${newId}`, {
          variant: 'success',
        });

        // store new ID in local state
        setOfficeSchedules((prev) =>
          prev.map((r, i2) => {
            if (i2 === index) {
              return { ...r, id: newId };
            }
            return r;
          })
        );
      }
    } catch (error) {
      console.error('Error saving schedule row:', error);
      enqueueSnackbar('Error saving schedule row.', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  // ----------------------------------
  // 4. Handle "Save" for provider's base fields
  // ----------------------------------
  const handleSaveProviderDetails = async () => {
    showLoading();
    try {
      if (!props?.selectedUser?.id) {
        enqueueSnackbar('Please select a provider first.', {
          variant: 'error',
        });
        return;
      }

      if (!props.selectedUserDetail?.provider_email || !hoursPerWeek) {
        enqueueSnackbar('Please select required fields.', { variant: 'error' });
        return;
      }

      await providerService.updateProviderDetails(props.selectedUser.id, {
        email: props.selectedUserDetail?.provider_email,
        hipaa: inHIPAA,
        hrs_p_week: hoursPerWeek,
        schedule: basicScheduleText,
        llc_name: llcName,
        llc_address: llcAddress,
        sdat_num: sdatNumber,
        tax_id: taxID,
        supervisorEmail: props.selectedUserDetail?.supervisor_email,
        supervisorName: props.selectedUserDetail?.supervisor_name,
        degreesAndLicense: props.selectedUserDetail?.degrees_and_license,
        licensedInOtherStates:
          props.selectedUserDetail?.licensed_in_other_states,
        inTherapy: props.selectedUserDetail?.in_therapy,
        inTesting: props.selectedUserDetail?.in_testing,
        aetna_effective_date: props.selectedUserDetail?.aetna_effective_date,
        bcbs_effective_date: props.selectedUserDetail?.bcbs_effective_date,
        cigna_effective_date: props.selectedUserDetail?.cigna_effective_date,
        hopkins_ehp_effective_date:
          props.selectedUserDetail?.hopkins_ehp_effective_date,
        hopkins_usfhp_effective_date:
          props.selectedUserDetail?.hopkins_usfhp_effective_date,
        medicaid_effective_date:
          props.selectedUserDetail?.medicaid_effective_date,
        tricare_effective_date:
          props.selectedUserDetail?.tricare_effective_date,
      });

      enqueueSnackbar('Provider details updated successfully.', {
        variant: 'success',
      });
      props.updateProviderDetail();
    } catch (error) {
      console.error('Error updating provider details:', error);
      enqueueSnackbar('Error updating provider details.', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  // ----------------------------------
  // 5. Render
  // ----------------------------------
  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the provider configuration are read-only...
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className={styles.providerNotice1}>
            The "Personal Info" section of the provider profile is read-only...
          </Typography>
        )}

      <div className={styles.tabsContentContainer}>
        {/* Basic provider fields */}
        <Grid container spacing={3} className={styles.gridItemTextField}>
          {/* HIPAA */}
          <Grid item size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <FormControl fullWidth>
              <InputLabel>HIPAA Workforce</InputLabel>
              <Select
                value={inHIPAA}
                onChange={(e) => setInHIPAA(e.target.value)}
                disabled={props.isProvider}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Hours per week */}
          <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <TextField
              fullWidth
              label="Hours Per Week"
              value={hoursPerWeek}
              disabled={props.isProvider}
              onChange={(e) => setHoursPerWeek(e.target.value)}
            />
          </Grid>

          {/* Basic schedule text */}
          <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <TextField
              fullWidth
              label="Basic Schedule (Text)"
              value={basicScheduleText}
              disabled={props.isProvider}
              onChange={(e) => setBasicScheduleText(e.target.value)}
            />
          </Grid>

          {/* LLC, SDAT, Tax, etc. */}
          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                fullWidth
                label="LLC Name"
                value={llcName}
                disabled={props.isProvider}
                onChange={(e) => setLlcName(e.target.value)}
              />
            </Grid>
          )}

          {/* LLC Address text */}
          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                label="LLC Address"
                autoComplete="off"
                variant="outlined"
                value={llcAddress}
                disabled={props.isProvider}
                onChange={(e) => {
                  setLlcAddress(e.target.value);
                }}
              />
            </Grid>
          )}

          {/* SDAT Address text */}
          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                label="SDAT #"
                value={sdatNumber}
                disabled={props.isProvider}
                onChange={(e) => {
                  setSdatNumber(e.target.value);
                }}
              />
            </Grid>
          )}

          {/* TaxId text */}
          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                label="Tax ID"
                value={taxID}
                disabled={props.isProvider}
                onChange={(e) => {
                  setTaxID(e.target.value);
                }}
              />
            </Grid>
          )}
        </Grid>

        <Button
          variant="contained"
          onClick={handleSaveProviderDetails}
          disabled={props.isProvider}
          className={styles.buttonWithMargin}
          style={{ marginBottom: '2rem' }}
        >
          Save Provider Details
        </Button>

        {/* MULTI-ROW SCHEDULE */}
        <Typography variant="h6">Office Schedules</Typography>

        {officeSchedules.map((row, index) => {
          const schedule = row.schedule || {};

          // Filter rooms by location
          const roomsForThisLocation = allRooms.filter(
            (room) => room.location_id === row.office_location_id
          );

          return (
            <Grid
              container
              spacing={2}
              key={index}
              style={{
                marginBottom: '1rem',
                border: '1px solid #ccc',
                padding: '1rem',
              }}
            >
              {/* SETTING */}
              <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                <RadioGroup
                  row
                  value={row.setting} // UI label
                  onChange={(e) =>
                    handleChangeField(index, 'setting', e.target.value)
                  }
                >
                  <FormControlLabel
                    value="Remote"
                    control={<Radio />}
                    label="Remote"
                    disabled={props.isProvider}
                  />
                  <FormControlLabel
                    value="In-Office"
                    control={<Radio />}
                    label="In-Office"
                    disabled={props.isProvider}
                  />
                  <FormControlLabel
                    value="In-Office (Remote)"
                    control={<Radio />}
                    label="In-Office (Remote)"
                    disabled={props.isProvider}
                  />
                </RadioGroup>
              </Grid>

              {/* Days multi-select */}
              <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Days</InputLabel>
                  <Select
                    multiple
                    value={schedule.days || []}
                    onChange={(e) =>
                      handleChangeField(index, 'days', e.target.value)
                    }
                    disabled={props.isProvider}
                  >
                    {DAYS_OF_WEEK.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Start Time */}
              <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start Time"
                    value={schedule.startTime}
                    onChange={(newValue) =>
                      handleChangeField(index, 'startTime', newValue)
                    }
                    disabled={props.isProvider}
                  />
                </LocalizationProvider>
              </Grid>

              {/* End Time */}
              <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="End Time"
                    value={schedule.endTime}
                    onChange={(newValue) =>
                      handleChangeField(index, 'endTime', newValue)
                    }
                    disabled={props.isProvider}
                  />
                </LocalizationProvider>
              </Grid>

              {/* If the user picks "Remote", no location/room needed */}
              {row.setting === 'In-Office' ||
              row.setting === 'In-Office (Remote)' ? (
                <>
                  {/* LOCATION */}
                  <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel>Location</InputLabel>
                      <Select
                        value={row.office_location_id || ''}
                        onChange={(e) =>
                          handleLocationChange(index, e.target.value)
                        }
                        disabled={props.isProvider}
                      >
                        <MenuItem value="">Select Location</MenuItem>
                        {allLocations.map((loc) => (
                          <MenuItem
                            key={loc.location_id}
                            value={loc.location_id}
                          >
                            {loc.location_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* ROOM */}
                  <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel>Room</InputLabel>
                      <Select
                        value={row.office_room_id || ''}
                        onChange={(e) =>
                          handleChangeField(
                            index,
                            'office_room_id',
                            e.target.value
                          )
                        }
                        disabled={props.isProvider}
                      >
                        <MenuItem value="">Select Room</MenuItem>
                        {roomsForThisLocation.map((room) => (
                          <MenuItem key={room.id} value={room.id}>
                            {room.room_letter}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : null}

              {/* Save & Delete */}
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveRow(index)}
                  disabled={props.isProvider}
                  style={{ marginRight: '8px' }}
                >
                  {row.id ? 'Update' : 'Save'}
                </Button>
                <IconButton
                  onClick={() => handleRemoveScheduleRow(index)}
                  disabled={props.isProvider}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}

        {/* Add new row */}
        <Button
          variant="outlined"
          onClick={handleAddScheduleRow}
          disabled={props.isProvider}
        >
          + Add Schedule
        </Button>
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderPracticeInfo);

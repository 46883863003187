import React, { useState, useMemo } from 'react';
import { TablePagination } from '@mui/material';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';
import useProvidersProfessional from '../../hooks/useProviderProfessional';

function compareProviderFullName(a, b) {
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

const tableHeaderCellStyle = {
  borderBottom: '0.5px solid black',
  fontWeight: '900',
  fontSize: '1.0rem', // Increase font size
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#F5F5F5',
};

const tableCellStyle = { borderBottom: '0.1px solid black' };

export default function ProviderProfessionalInfoTable({ showActiveProviders }) {
  // Below is the Hook getting used to fetch Providers Professional Info
  const { providers, isLoading, error } =
    useProvidersProfessional(showActiveProviders);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Formatting the Providers for the UI
  const formattedProviders = useMemo(() => {
    const sortedProviders = [...providers].sort(compareProviderFullName);

    return sortedProviders.map((provider) => ({
      ...provider,
      diplomas: provider.diplomas || 'N/A',
      supervisor: provider.supervisor || 'No supervisor',
    }));
  }, [providers]);

  const paginatedProviders = useMemo(() => {
    return formattedProviders.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [formattedProviders, page, rowsPerPage]);

  // Setting up the Columns for Provider Professional Info
  const columns = useMemo(
    () => [
      {
        field: 'provider_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'supervisor_name',
        headerName: 'Supervisor',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'diplomas',
        headerName: 'Graduating School\n(Graduating Date)',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ],
    []
  );

  return (
    <div>
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!isLoading && !error && (
        <>
          <ResponsiveTableCard
            columns={columns}
            data={paginatedProviders}
            isDesktopTableStyled={true}
            tableHeaderCellStyle={tableHeaderCellStyle}
            tableCellStyle={tableCellStyle}
          />
          <TablePagination
            component="div"
            count={providers.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
          />
        </>
      )}
    </div>
  );
}

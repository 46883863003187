import React, { useEffect, useCallback } from 'react';
import * as MUIv6 from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import EventBus from '../../common/EventBus';
import {
  CareCoordinatorPersonalInfo,
  CareCoordinatorSystemInfo,
} from '../Input';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import {
  isDoctor,
  isSupervisee,
  isDoctorAdminSupervisor,
  isCareCoordinator,
} from '../../common/utils/RoleUtils';
import styles from '../../styles/ProviderConfigHomeStyles.module.css';
import CareCoordinatorPracticeInfo from '../Input/CareCoordinatorPracticeInfo';
import { careCoordinatorService } from '../../services';
import ResponsiveTabs from '../UI/ResponsiveTabs';

// Define the tabs array similar to Provider and Admin
const tabs = [
  {
    value: 'personal_info',
    title: 'Personal Info',
    description: 'Personal Details of Care Coordinator',
    label: <span>Personal Info</span>,
  },
  {
    value: 'carecoordinator_systems',
    title: 'Systems',
    description: 'System configurations for Care Coordinator',
    label: <span>Systems</span>,
  },
  {
    value: 'practice_info',
    title: 'Practice Info',
    description: 'Practice-related information',
    label: <span>Practice Info</span>,
  },
];

export default function CareCoordinatorConfigHome() {
  // Rename from value to activeTab for consistency
  const [activeTab, setActiveTab] = React.useState('personal_info');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveCareCoodinatorOnly, setShowActiveCareCoordinatorOnly] =
    React.useState(true);
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getCareCoordinators = async (status) => {
    try {
      const response = await careCoordinatorService.getCareCoordinators(status);

      let careCoordinators = response?.data ?? [];
      careCoordinators = careCoordinators.sort((a, b) => {
        const nameA = a.care_coordinator_name.split(' ')[1] || '';
        const nameB = b.care_coordinator_name.split(' ')[1] || '';
        return nameA.localeCompare(nameB);
      });

      if (isCareCoordinator(currentUser)) {
        careCoordinators = careCoordinators.filter(
          (cc) => cc.care_coordinator_email === currentUser.email
        );
        setSelectedUserDetail(careCoordinators[0]);
      }

      setUsers(careCoordinators);
    } catch (error) {
      console.error('Error fetching care coordinators:', error);
      if (error.response && error.response.status === 401) {
        EventBus.dispatch('logout');
      }
    } finally {
      hideLoading();
    }
  };

  const getAllCareCoordinators = async () => {
    try {
      const response = await careCoordinatorService.getAllCareCoordinators();

      let careCoordinators = response?.data ?? [];
      careCoordinators = careCoordinators.sort((a, b) => {
        const nameA = a.care_coordinator_name.split(' ')[1] || '';
        const nameB = b.care_coordinator_name.split(' ')[1] || '';
        return nameA.localeCompare(nameB);
      });

      if (isCareCoordinator(currentUser)) {
        careCoordinators = careCoordinators.filter(
          (cc) => cc.care_coordinator_email === currentUser.email
        );
        setSelectedUserDetail(careCoordinators[0]);
      }

      setUsers(careCoordinators);
    } catch (error) {
      console.error('Error fetching care coordinators:', error);
      if (error.response && error.response.status === 401) {
        EventBus.dispatch('logout');
      }
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, [currentUser.id]);

  useEffect(() => {
    showLoading();
    if (showActiveCareCoodinatorOnly) {
      getCareCoordinators('active');
    } else {
      getAllCareCoordinators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveCareCoodinatorOnly]); // Dependencies adjusted

  useEffect(() => {
    if (users.length > 1) {
      return;
    }
    if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, currentUser]);

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      try {
        const careCoordinatorDetail =
          await careCoordinatorService.getCareCoordinatorDetail(
            enObj.id,
            enObj.email
          );
        if (careCoordinatorDetail && careCoordinatorDetail.data) {
          careCoordinatorDetail.data.careCoordinator.username = enObj.username;
          setSelectedUserDetail(careCoordinatorDetail.data.careCoordinator);
        }
      } catch (error) {
        console.error('Error fetching care coordinator details:', error);
        setSelectedUserDetail(null);
      }
    } else {
      setSelectedUserDetail(null);
    }
  };

  const updateCareCoordinatorDetail = async () => {
    const careCoordinatorDetail =
      await careCoordinatorService.getCareCoordinatorDetail(
        selectedUser.id,
        selectedUser.email
      );
    if (careCoordinatorDetail && careCoordinatorDetail.data) {
      setSelectedUserDetail(careCoordinatorDetail?.data?.careCoordinator);
    }
  };

  const visibleTabs = tabs; // No conditions, all tabs are visible

  useEffect(() => {
    const validTab = visibleTabs.find((tab) => tab.value === activeTab);
    if (!validTab) {
      setActiveTab(visibleTabs.length > 0 ? visibleTabs[0].value : '');
    }
  }, [visibleTabs, activeTab]);

  const marginTop = !isDoctor(currentUser) && isMobile ? '30px' : '0px';

  return (
    <React.Fragment>
      <div
        className="main-container"
        style={{ marginTop: marginTop, paddingLeft: '1.3rem' }}
      >
        <MUIv6.Grid2>
          <MUIv6.Grid2 size={{ xs: 12 }}>
            {(!isDoctor(currentUser) || !isCareCoordinator(currentUser)) && (
              <Autocomplete
                sx={{ width: '100%' }}
                disablePortal
                defaultValue={selectedUser}
                id="careCoordinator"
                options={users}
                onChange={onTagsChange}
                renderInput={(params) => (
                  <MUIv6.TextField
                    className={styles.widerProviderDropdown}
                    variant="outlined"
                    {...params}
                    name="careCoordinator_"
                    label="Care Coordinator"
                  />
                )}
                getOptionLabel={(option) => option.care_coordinator_name || ''}
              />
            )}
            {isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}
          </MUIv6.Grid2>
        </MUIv6.Grid2>

        {selectedUser && (
          <MUIv6.Box className={styles.tabContainer}>
            {/* Use ResponsiveTabs instead of MUIv6.Tabs */}
            <ResponsiveTabs
              tabs={visibleTabs}
              activeTab={activeTab}
              onChange={handleTabChange}
              useUniqueValues={true}
            />

            {activeTab === 'personal_info' && (
              <CareCoordinatorPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {activeTab === 'carecoordinator_systems' && (
              <CareCoordinatorSystemInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {activeTab === 'practice_info' && (
              <CareCoordinatorPracticeInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateCareCoordinatorDetail={updateCareCoordinatorDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}
          </MUIv6.Box>
        )}

        {!selectedUser && !isCareCoordinator(currentUser) && (
          <div>Please select a Care Coordinator.</div>
        )}

        {shouldShow && !selectedUser && !isCareCoordinator(currentUser) && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <MUIv6.Switch
              checked={showActiveCareCoodinatorOnly}
              onChange={(e) =>
                setShowActiveCareCoordinatorOnly(e.target.checked)
              }
            />
            <span
              style={{
                fontSize: '1.1rem',
                fontWeight: 'bold',
              }}
            >
              {showActiveCareCoodinatorOnly ? (
                <span> Show Active Care Coordinators</span>
              ) : (
                <span>Show All Care Coordinators</span>
              )}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { complianceService, providerService } from '../../services';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareProviderFullName(a, b) {
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

export default function ProfessionalUpkeepTable({ showActiveProviders }) {
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';

    showLoading();

    providerService
      .getProviders(status)
      .then((response) => {
        const providersInit = response.data.providers.map((provider) => ({
          ...provider,
          malpracticeDetails: 'Loading...',
          last_attestation: 'Loading...',
          last_roster_submission: 'Loading...',
        }));

        const detailsPromises = providersInit.map((provider) =>
          Promise.all([
            providerService.getTherapyConfig(provider.id),
            complianceService.getMalpracticeInsuranceDetails(provider.id),
          ])
            .then(([therapyResponse, malpracticeResponse]) => ({
              ...provider,
              last_attestation: therapyResponse.data.last_attestation || 'N/A',
              last_roster_submission:
                therapyResponse.data.last_roster_submission || 'N/A',
              malpracticeDetails:
                malpracticeResponse.data.malpracticeInsurance ||
                'Not available',
            }))
            .catch((error) => {
              console.error(
                'Error fetching details for provider:',
                provider.id,
                error
              );
              return {
                ...provider,
                malpracticeDetails: 'Error fetching details',
              };
            })
        );

        Promise.all(detailsPromises).then((fullDetails) => {
          fullDetails.sort(compareProviderFullName);
          setProviders(fullDetails);
        });
      })
      .catch((error) => {
        console.error('Failed to fetch providers:', error);
        enqueueSnackbar(
          'Failed to fetch providers: ' +
            (error.response?.data?.message || error.message),
          { variant: 'error' }
        );
      })
      .finally(() => {
        hideLoading();
      });
  }, [showActiveProviders, enqueueSnackbar, showLoading, hideLoading]);

  const getMalpracticeDateStyle = (malpracticeDetails) => {
    const dateMatch = malpracticeDetails.match(
      /\((\d{1,2}\/\d{1,2}\/\d{2,4})\)/
    );
    if (dateMatch && dateMatch[1]) {
      const expiryDate = moment(dateMatch[1], 'MM/DD/YY');
      const today = moment();
      if (expiryDate.isBefore(today)) {
        return { color: 'red' }; // Past expiration
      } else if (expiryDate.isBefore(today.clone().add(30, 'days'))) {
        return { color: 'orange' }; // Expiring within 30 days
      }
    }
    return {}; // Default style if no conditions met
  };

  const getExpiryDateColor = (expiryDate) => {
    const today = new Date();
    const expiration = new Date(expiryDate);
    const oneMonthFromNow = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );

    if (expiration < today) {
      return 'red'; // past expiration
    } else if (expiration <= oneMonthFromNow) {
      return 'orange'; // expires within one month
    }
    return 'black'; // default color
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'provider_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
          width: '140px',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          padding: '8px 4px',
          whiteSpace: 'nowrap',
          width: '140px',
        },
      },
      {
        field: 'last_attestation',
        headerName: 'Last Attestation',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          padding: '8px 4px',
        },
      },
      {
        field: 'last_roster_submission',
        headerName: 'Last Roster Submission',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          padding: '8px 4px',
        },
      },
      {
        field: 'malpracticeDetails',
        headerName: 'Malpractice Insurance Expiration',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          padding: '8px 1px',
        },
      },
      {
        field: 'professionalLicense',
        headerName: 'Professional License State (Expiration)',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          padding: '8px 1px',
        },
      },
    ];
  }, []);

  const formattedProviders = useMemo(() => {
    return providers.map((provider) => {
      const lastAttestationDays = moment().diff(
        moment(provider.last_attestation),
        'days'
      );
      const lastRosterSubmissionDays = moment().diff(
        moment(provider.last_roster_submission),
        'days'
      );

      const malpracticeDetailsStyled = {
        text: provider.malpracticeDetails || '',
        style: getMalpracticeDateStyle(provider.malpracticeDetails || ''),
      };

      const professionalLicenseDetails = [];

      if (provider.marylandLicenseDetails) {
        const expiryDate = provider.marylandLicenseDetails.expirationDate;
        professionalLicenseDetails.push({
          text: `MD: Expires ${
            expiryDate ? moment(expiryDate).format('M/D/YY') : ''
          }`,
          style: { color: getExpiryDateColor(expiryDate) },
        });
      }

      if (provider.psyPactDetails?.isPsyPactMember === 'yes') {
        const expiryDate = provider.psyPactDetails.expiryDate;
        professionalLicenseDetails.push({
          text: `PsyPact Member: Expires ${
            expiryDate ? moment(expiryDate).format('M/D/YY') : ''
          }`,
          style: { color: getExpiryDateColor(expiryDate) },
        });
      }

      if (provider.licenseInOtherStateDetails) {
        for (const [state, licenseDetails] of Object.entries(
          provider.licenseInOtherStateDetails
        )) {
          if (licenseDetails.expirationDate) {
            const expiryDate = licenseDetails.expirationDate;
            professionalLicenseDetails.push({
              text: `${state}: Expires ${
                expiryDate ? moment(expiryDate).format('M/D/YY') : ''
              }`,
              style: { color: getExpiryDateColor(expiryDate) },
            });
          }
        }
      }

      return {
        ...provider,
        last_attestation: {
          text: provider.last_attestation,
          style: { color: lastAttestationDays > 90 ? 'red' : 'black' },
        },
        last_roster_submission: {
          text: provider.last_roster_submission,
          style: { color: lastRosterSubmissionDays > 37 ? 'red' : 'black' },
        },
        malpracticeDetails: malpracticeDetailsStyled,
        professionalLicense: professionalLicenseDetails,
      };
    });
  }, [providers]);

  const paginatedProviders = useMemo(() => {
    return formattedProviders.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [formattedProviders, page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    fontSize: '1.0rem', // Increase font size
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={paginatedProviders}
        tableStyles={{}}
        isDesktopTableStyled
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}

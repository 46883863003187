import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';

const GroupGeneralTable = ({ groupInfo, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '10px' }}>
          Loading Group Information...
        </Typography>
      </Box>
    );
  }

  if (!groupInfo) {
    return (
      <Typography variant="h6" sx={{ padding: '16px', marginTop: '20px' }}>
        No Group Information Available
      </Typography>
    );
  }

  return (
    <div>
      {/* General Office Information Table */}
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Typography variant="h6" sx={{ padding: '16px' }}>
          General Office Information
        </Typography>
        <Table>
          {/* Table Headers */}
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Outgoing Phone Number</strong>
              </TableCell>
              <TableCell>
                <strong>Fax Number</strong>
              </TableCell>
              <TableCell>
                <strong>Tax ID</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {groupInfo.officeOutgoingPhoneNumber || 'N/A'}
              </TableCell>
              <TableCell>{groupInfo.officeFaxNumber || 'N/A'}</TableCell>
              <TableCell>{groupInfo.officeTaxId || 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Office Phone Extensions Table */}
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Typography variant="h6" sx={{ padding: '16px' }}>
          Office Phone Extensions
        </Typography>
        {groupInfo.officePhoneExtensions?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Extension Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Extension Number</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupInfo.officePhoneExtensions.map((ext, idx) => (
                <TableRow key={idx}>
                  <TableCell>{ext.name || 'N/A'}</TableCell>
                  <TableCell>{ext.extension || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ padding: '16px' }}>
            No phone extensions available.
          </Typography>
        )}
      </TableContainer>
    </div>
  );
};

export default GroupGeneralTable;

import React, { useEffect, memo } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import styles from '../../styles/ProviderConfig.module.css';
import { groupService } from '../../services'; // Update path as needed

const getBackgroundColor = (officeName) => {
  if (!officeName) return '#f9f9f9'; // Default Light Grey if officeName is undefined

  switch (officeName.toLowerCase()) {
    case 'baltimore':
      return '#EDE7F6'; // Light Purple
    case 'columbia':
      return '#E3F2FD'; // Light Blue
    case 'silver spring':
      return '#E8F5E9'; // Light Green
    default:
      return '#f9f9f9'; // Default Light Grey
  }
};

const GroupBasicInfo = (props) => {
  // 1) State for general office info
  const [generalOfficeInfo, setGeneralOfficeInfo] = React.useState({
    officeOutgoingPhoneNumber: '',
    officeFaxNumber: '',
    officeTaxId: '',
    officePhoneExtensions: [],
  });
  const [selectedOfficeIndex, setSelectedOfficeIndex] = React.useState(0);
  const [locations, setLocations] = React.useState([
    {
      location_id: null,
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
      pointOfContactName: '',
      pointOfContactPhone: '',
      pointOfContactEmail: '',
      buildingServicePortal: '',
      buildingNotes: '',
      buildingHours: {
        weekdays: { open: '', close: '' },
        sunday: { open: '', close: '' },
      },
      afterHoursInstructions: '',
      // Add any additional fields as needed
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const fetchAllGroups = async () => {
    try {
      props.showLoading();

      // Below getting All Groups With Locations
      const response = await groupService.getAllGroupsWithLocations();

      // If your backend returns an array, adapt accordingly
      if (response.data) {
        const { generalOfficeInfo: gInfo, locations: locs } = response.data;

        if (gInfo) {
          setGeneralOfficeInfo({
            officeOutgoingPhoneNumber: gInfo.outgoing_phone_number || '',
            officeFaxNumber: gInfo.fax_number || '',
            officeTaxId: gInfo.tax_id || '',
            officePhoneExtensions: gInfo.phone_extensions || [],
          });
        }

        if (Array.isArray(locs) && locs.length > 0) {
          // Transform or store as is
          const transformedLocations = locs.map((l) => ({
            location_id: l.location_id || null,
            name: l.location_name || '',
            address: l.address1 || '',
            address2: l.address2 || '',
            city: l.city || '',
            state: l.state || '',
            zip: l.zip || '',
            phoneNumber: l.phone_number || '',
            pointOfContactName: l.point_of_contact_name || '',
            pointOfContactPhone: l.point_of_contact_number || '',
            pointOfContactEmail: l.point_of_contact_email || '',
            buildingServicePortal: l.building_service_portal || '',
            buildingNotes: l.building_notes || '',
            buildingHours: l.building_hours || {
              weekdays: { open: '', close: '' },
              sunday: { open: '', close: '' },
            },
            afterHoursInstructions: l.after_hours_instructions || '',
          }));
          setLocations(transformedLocations);
          setSelectedOfficeIndex(0);
        } else {
          enqueueSnackbar('No location details available.', {
            variant: 'warning',
          });
        }
      } else {
        enqueueSnackbar('No group details available.', { variant: 'warning' });
      }
    } catch (error) {
      console.error('Error fetching group details:', error.message);
      enqueueSnackbar('Failed to load group details.', { variant: 'error' });
    } finally {
      props.hideLoading();
    }
  };

  useEffect(() => {
    fetchAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If editing an existing group
    if (props.selectedGroup?.locations) {
      setLocations(props.selectedGroup.locations);
      // Reset the selected office if needed
      const baltimoreIndex = props.selectedGroup.locations.findIndex((loc) =>
        loc.name.toLowerCase().includes('baltimore')
      );
      if (baltimoreIndex > -1) {
        setSelectedOfficeIndex(baltimoreIndex);
      } else {
        setSelectedOfficeIndex(0);
      }
    }
  }, [props.selectedGroup]);

  // 3) Handle general office info changes
  const handleGeneralOfficeChange = (field, value) => {
    setGeneralOfficeInfo((prev) => ({ ...prev, [field]: value }));
  };

  // 4) Handle phone extension array changes
  const handleExtensionChange = (index, field, value) => {
    const updatedExtensions = [...generalOfficeInfo.officePhoneExtensions];
    updatedExtensions[index] = {
      ...updatedExtensions[index],
      [field]: value,
    };
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      officePhoneExtensions: updatedExtensions,
    }));
  };

  const handleBuildingHoursChange = (dayGroup, field, value) => {
    // Convert "HH:MM" string to proper format if necessary
    const timeString = value || '';

    setLocations((prev) =>
      prev.map((loc, idx) =>
        idx === selectedOfficeIndex
          ? {
              ...loc,
              buildingHours: {
                ...loc.buildingHours,
                [dayGroup]: {
                  ...loc.buildingHours[dayGroup],
                  [field]: timeString,
                },
              },
            }
          : loc
      )
    );
  };

  const addExtensionRow = () => {
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      officePhoneExtensions: [
        ...prev.officePhoneExtensions,
        { name: '', extension: '' },
      ],
    }));
  };

  const removeExtensionRow = (index) => {
    const updatedExtensions = [...generalOfficeInfo.officePhoneExtensions];
    updatedExtensions.splice(index, 1);
    setGeneralOfficeInfo((prev) => ({
      ...prev,
      officePhoneExtensions: updatedExtensions,
    }));
  };

  // 5) Handle location-specific changes
  const handleLocationChange = (field, value) => {
    if (
      selectedOfficeIndex == null ||
      selectedOfficeIndex < 0 ||
      selectedOfficeIndex >= locations.length
    ) {
      console.error('Invalid office index:', selectedOfficeIndex);
      return;
    }
    const updatedLocations = [...locations];
    updatedLocations[selectedOfficeIndex] = {
      ...updatedLocations[selectedOfficeIndex],
      [field]: value,
    };
    setLocations(updatedLocations);
  };

  const handleOfficeSelectionChange = (event) => {
    setSelectedOfficeIndex(event.target.value);
  };

  // 6a) Update general office info
  const handleGeneralInfoSubmit = async () => {
    try {
      props.showLoading();
      // transform your generalOfficeInfo state to match your backend
      const payload = {
        outgoing_phone_number:
          generalOfficeInfo.officeOutgoingPhoneNumber || '',
        fax_number: generalOfficeInfo.officeFaxNumber || '',
        tax_id: generalOfficeInfo.officeTaxId || '',
        phone_extensions: generalOfficeInfo.officePhoneExtensions || [],
        // add more if needed
      };
      await groupService.updateGroupInfoDetails(payload);
      enqueueSnackbar('General office info updated successfully.', {
        variant: 'success',
      });
    } catch (err) {
      console.error('handleGeneralInfoSubmit.error', err.message);
      enqueueSnackbar('Error updating general office info.', {
        variant: 'error',
      });
    } finally {
      props.hideLoading();
    }
  };

  // 6b) Update location-specific details
  const handleLocationSubmit = async () => {
    const { buildingHours, location_id, name } = currentLocation;

    // Basic validation
    if (!name) {
      enqueueSnackbar('Location name is required.', { variant: 'error' });
      return;
    }

    if (!location_id) {
      enqueueSnackbar('Location ID is missing.', { variant: 'error' });
      return;
    }

    // Validate building hours
    if (!buildingHours.weekdays.open || !buildingHours.weekdays.close) {
      enqueueSnackbar('Weekdays open and close times are required.', {
        variant: 'error',
      });
      return;
    }
    if (buildingHours.weekdays.open >= buildingHours.weekdays.close) {
      enqueueSnackbar('Weekdays close time must be after open time.', {
        variant: 'error',
      });
      return;
    }

    if (
      (buildingHours.sunday.open && !buildingHours.sunday.close) ||
      (!buildingHours.sunday.open && buildingHours.sunday.close)
    ) {
      enqueueSnackbar('Both Sunday open and close times must be provided.', {
        variant: 'error',
      });
      return;
    }
    if (
      buildingHours.sunday.open &&
      buildingHours.sunday.close &&
      buildingHours.sunday.open >= buildingHours.sunday.close
    ) {
      enqueueSnackbar('Sunday close time must be after open time.', {
        variant: 'error',
      });
      return;
    }

    try {
      props.showLoading();

      // Prepare the payload as an array
      const payload = [
        {
          location_id: currentLocation.location_id,
          location_name: currentLocation.name,
          address1: currentLocation.address,
          address2: currentLocation.address2 || '',
          city: currentLocation.city || '',
          state: currentLocation.state || '',
          zip: currentLocation.zip || '',
          phone_number: currentLocation.phoneNumber || '',
          point_of_contact_name: currentLocation.pointOfContactName || '',
          point_of_contact_number: currentLocation.pointOfContactPhone || '',
          point_of_contact_email: currentLocation.pointOfContactEmail || '',
          building_service_portal: currentLocation.buildingServicePortal || '',
          building_notes: currentLocation.buildingNotes || '',
          building_hours: currentLocation.buildingHours || {
            weekdays: { open: '', close: '' },
            sunday: { open: '', close: '' },
          },
          after_hours_instructions:
            currentLocation.afterHoursInstructions || '',
          // Include any additional fields here
        },
      ];

      await groupService.updateGroupLocationDetails(payload);
      enqueueSnackbar('Location details updated successfully.', {
        variant: 'success',
      });
    } catch (err) {
      console.error('handleLocationSubmit.error', err.message);
      enqueueSnackbar('Error updating location details.', { variant: 'error' });
    } finally {
      props.hideLoading();
    }
  };

  const currentLocation = locations[selectedOfficeIndex] || {};

  return (
    <React.Fragment>
      <Box className={styles.tabsContentContainer}>
        <Typography variant="h6">Non-Location Basic Info</Typography>

        {/* ------------------------------- 
          GENERAL OFFICE INFORMATION (ALL LOCATIONS)
        --------------------------------*/}
        <Paper
          variant="outlined"
          style={{ padding: '15px', marginTop: '20px' }}
        >
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Outgoing Phone Number"
                value={generalOfficeInfo.officeOutgoingPhoneNumber}
                onChange={(e) =>
                  handleGeneralOfficeChange(
                    'officeOutgoingPhoneNumber',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Fax Number"
                value={generalOfficeInfo.officeFaxNumber}
                onChange={(e) =>
                  handleGeneralOfficeChange('officeFaxNumber', e.target.value)
                }
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Tax ID"
                value={generalOfficeInfo.officeTaxId}
                onChange={(e) =>
                  handleGeneralOfficeChange('officeTaxId', e.target.value)
                }
              />
            </Grid>
          </Grid>

          {/* PHONE EXTENSIONS AS A LIST */}
          <div>
            <Typography variant="body1" style={{ marginTop: '15px' }}>
              Office Phone Extensions
            </Typography>
            {generalOfficeInfo.officePhoneExtensions.map((ext, idx) => (
              <Grid
                container
                spacing={2}
                key={idx}
                style={{ marginBottom: '10px' }}
              >
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Extension Name"
                    value={ext.name}
                    onChange={(e) =>
                      handleExtensionChange(idx, 'name', e.target.value)
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Extension Number"
                    value={ext.extension}
                    onChange={(e) =>
                      handleExtensionChange(idx, 'extension', e.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <IconButton
                    onClick={() => removeExtensionRow(idx)}
                    color="error"
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={addExtensionRow}
              style={{ marginTop: '10px' }}
            >
              Add Extension
            </Button>
          </div>
          {/* A separate button for general info submission */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Centers horizontally
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleGeneralInfoSubmit}
              sx={{
                marginTop: '20px',
              }}
            >
              Save General Office Info
            </Button>
          </Box>
        </Paper>

        {/**
         * Below is Location Specific Basic Info
         */}
        {locations.length > 0 && (
          <Box sx={{ marginTop: '30px' }}>
            <Typography variant="h6">Location Specific Basic Info</Typography>
            <Paper
              variant="outlined"
              sx={{
                padding: '20px',
                marginTop: '20px',
                backgroundColor: getBackgroundColor(
                  locations[selectedOfficeIndex].name
                ),
              }}
            >
              <Paper
                variant="outlined"
                style={{ padding: '15px', marginTop: '20px' }}
              >
                <FormControl fullWidth>
                  <InputLabel id="office-select-label">Office</InputLabel>

                  <Select
                    labelId="office-select-label"
                    id="office-select"
                    value={selectedOfficeIndex}
                    onChange={handleOfficeSelectionChange}
                    label="Office"
                  >
                    {locations.map((loc, idx) => (
                      <MenuItem key={idx} value={idx}>
                        {loc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>

              <Paper
                variant="outlined"
                style={{ padding: '15px', marginTop: '20px' }}
              >
                <Grid
                  size={{ xs: 12, sm: 6, md: 4, lg: 4 }}
                  sx={{ marginTop: 2 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Phone Number"
                    value={currentLocation.phoneNumber || ''}
                    onChange={(e) =>
                      handleLocationChange('phoneNumber', e.target.value)
                    }
                  />
                </Grid>
              </Paper>

              {/* Address Section Grouped */}

              <Grid container spacing={2}>
                <Grid>
                  <Paper
                    variant="outlined"
                    style={{ padding: '15px', marginTop: '20px' }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: '10px' }}
                    >
                      Address
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Address"
                          value={currentLocation.address || ''}
                          onChange={(e) =>
                            handleLocationChange('address', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Address 2 (e.g. Apt)"
                          value={currentLocation.address2 || ''}
                          onChange={(e) =>
                            handleLocationChange('address2', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="City"
                          value={currentLocation.city || ''}
                          onChange={(e) =>
                            handleLocationChange('city', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                        <FormControl fullWidth>
                          <InputLabel id="state-label">State</InputLabel>
                          <Select
                            labelId="state-label"
                            id="state"
                            value={currentLocation.state || ''}
                            label="State"
                            onChange={(e) =>
                              handleLocationChange('state', e.target.value)
                            }
                          >
                            {[
                              'AL',
                              'AK',
                              'AZ',
                              'AR',
                              'CA',
                              'CO',
                              'CT',
                              'DE',
                              'FL',
                              'GA',
                              'HI',
                              'ID',
                              'IL',
                              'IN',
                              'IA',
                              'KS',
                              'KY',
                              'LA',
                              'ME',
                              'MD',
                              'MA',
                              'MI',
                              'MN',
                              'MS',
                              'MO',
                              'MT',
                              'NE',
                              'NV',
                              'NH',
                              'NJ',
                              'NM',
                              'NY',
                              'NC',
                              'ND',
                              'OH',
                              'OK',
                              'OR',
                              'PA',
                              'RI',
                              'SC',
                              'SD',
                              'TN',
                              'TX',
                              'UT',
                              'VT',
                              'VA',
                              'WA',
                              'WV',
                              'WI',
                              'WY',
                            ].map((st) => (
                              <MenuItem key={st} value={st}>
                                {st}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Zip Code"
                          value={currentLocation.zip || ''}
                          onChange={(e) =>
                            handleLocationChange('zip', e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              {/* Point of Contact Section Grouped */}

              <Grid container spacing={2}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '15px',
                    marginTop: '20px',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: '10px' }}
                  >
                    Point of Contact
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Name"
                        value={currentLocation.pointOfContactName || ''}
                        onChange={(e) =>
                          handleLocationChange(
                            'pointOfContactName',
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Phone"
                        value={currentLocation.pointOfContactPhone || ''}
                        onChange={(e) =>
                          handleLocationChange(
                            'pointOfContactPhone',
                            e.target.value
                          )
                        }
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        value={currentLocation.pointOfContactEmail || ''}
                        onChange={(e) =>
                          handleLocationChange(
                            'pointOfContactEmail',
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* NEW: Building Information Section */}
              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '15px',
                    marginTop: '20px',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: '10px' }}
                  >
                    Building Information
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Building Hours */}
                    <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                      <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                        Building Hours
                      </Typography>
                      <Paper variant="outlined" sx={{ padding: '15px' }}>
                        {/* Weekdays */}
                        <Typography
                          variant="subtitle2"
                          sx={{ marginBottom: '10px' }}
                        >
                          Weekdays (Monday - Friday)
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Open Time"
                              type="time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              value={
                                currentLocation.buildingHours?.weekdays?.open ||
                                ''
                              }
                              onChange={(e) =>
                                handleBuildingHoursChange(
                                  'weekdays',
                                  'open',
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Close Time"
                              type="time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              value={
                                currentLocation.buildingHours?.weekdays
                                  ?.close || ''
                              }
                              onChange={(e) =>
                                handleBuildingHoursChange(
                                  'weekdays',
                                  'close',
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                        </Grid>

                        {/* Sunday */}
                        <Typography
                          variant="subtitle2"
                          sx={{ marginTop: '20px', marginBottom: '10px' }}
                        >
                          Sunday
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Open Time"
                              type="time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              value={
                                currentLocation.buildingHours?.sunday?.open ||
                                ''
                              }
                              onChange={(e) =>
                                handleBuildingHoursChange(
                                  'sunday',
                                  'open',
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Close Time"
                              type="time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              value={
                                currentLocation.buildingHours?.sunday?.close ||
                                ''
                              }
                              onChange={(e) =>
                                handleBuildingHoursChange(
                                  'sunday',
                                  'close',
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="After Hours Instructions"
                          value={currentLocation.afterHoursInstructions || ''}
                          onChange={(e) =>
                            handleLocationChange(
                              'afterHoursInstructions',
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Building Service Portal"
                          value={currentLocation.buildingServicePortal || ''}
                          onChange={(e) =>
                            handleLocationChange(
                              'buildingServicePortal',
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          variant="outlined"
                          label="Building Notes"
                          value={currentLocation.buildingNotes || ''}
                          onChange={(e) =>
                            handleLocationChange(
                              'buildingNotes',
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Centered Save Button for Location Specific Info */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLocationSubmit}
                  style={{ marginTop: '20px' }}
                >
                  Save Office Location Info
                </Button>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default memo(GroupBasicInfo);
